import { useMemo, useState } from "react";
import classNames from "classnames";
import { Outlet, Link, useParams, useNavigate } from "react-router-dom";

import Loader from "../components/chat/Loader";

import { useProject } from "../contexts/new-project.context";
import { useChat } from "../contexts/chat.context";

import {
  ChatHistoryIcon,
  ChatHistoryOutlinedIcon,
  CreateChatIcon,
  CreateChatHoverIcon,
  ChatHistoryHideIcon,
  ChatHistoryHideHoverIcon,
  ChatHistoryShowIcon,
  ChatHistoryShowHoverIcon,
} from "../assets/icons";

export default function Chat() {
  const [isChatHistoryVisible, setIsChatHistoryVisible] = useState(true);

  return (
    <div className="flex-auto flex overflow-auto">
      <ChatHistory
        isChatHistoryVisible={isChatHistoryVisible}
        setIsChatHistoryVisible={setIsChatHistoryVisible}
      />

      <Outlet />
    </div>
  );
}

const ChatHistory = ({ isChatHistoryVisible, setIsChatHistoryVisible }) => {
  const navigate = useNavigate();

  const { projectId, logoFiles, packagingFiles, designElementsFiles } =
    useProject();
  const { chatHistory, loadingChatHistory, selectedStimuli, selectedVariants } =
    useChat();

  const createChatWithOldVariants = () => {
    if (!selectedStimuli?.field || !selectedVariants.length) {
      navigate(`/project-stimuli/${projectId}/chat/new`, { replace: true });
      return;
    }

    const selectedVariantList = selectedVariants.join("&selectedVariant=");

    navigate(
      `/project-stimuli/${projectId}/chat/new?selectedStimuli=${selectedStimuli?.field}&selectedVariant=${selectedVariantList}`,
      { replace: true }
    );
  };

  const stimuliVariants = useMemo(() => {
    if (selectedStimuli?.field === "logo") {
      return logoFiles.filter((file) =>
        selectedVariants?.includes(file?.image_hash)
      );
    }
    if (selectedStimuli?.field === "packaging_design") {
      return packagingFiles.filter((file) =>
        selectedVariants?.includes(file?.image_hash)
      );
    }
    if (selectedStimuli?.field === "design_element") {
      return designElementsFiles.filter((file) =>
        selectedVariants?.includes(file?.image_hash)
      );
    }
  }, [
    selectedStimuli,
    logoFiles,
    packagingFiles,
    designElementsFiles,
    selectedVariants,
  ]);

  const tooltipText = useMemo(() => {
    return selectedStimuli && stimuliVariants?.length
      ? `Start new interview with ${stimuliVariants
          ?.map((variant) => variant.name)
          ?.join(", ")}`
      : "Start new interview";
  }, [selectedStimuli, stimuliVariants]);

  return (
    <>
      {isChatHistoryVisible ? (
        <div
          className={classNames(
            "w-[270px] flex-none h-full overflow-auto flex flex-col border border-[#E7EBF1] bg-[#F7F8FA]"
          )}
        >
          <div className="flex-none h-16 border-b border-[#E7EBF1] p-3 flex justify-between items-center">
            <div
              onClick={() => setIsChatHistoryVisible((prev) => !prev)}
              className="group/icons cursor-pointer rounded-lg"
            >
              <ChatHistoryHideIcon />
              <ChatHistoryHideHoverIcon />
            </div>
            <div
              title={tooltipText}
              onClick={createChatWithOldVariants}
              className="group/icons cursor-pointer rounded-lg"
            >
              <CreateChatIcon />
              <CreateChatHoverIcon />
            </div>
          </div>

          {loadingChatHistory ? (
            <Loader />
          ) : (
            <>
              {!chatHistory?.length ? (
                <div className="flex-auto py-5 text-center">
                  <p className="text-sm text-[#99a8c0]">No interviews found</p>
                </div>
              ) : (
                <div className="flex-auto ps-2 py-2 overflow-auto space-y-2">
                  {chatHistory.map((chat) => (
                    <ChatListItem chat={chat} key={chat?.chatId} />
                  ))}
                </div>
              )}
            </>
          )}
        </div>
      ) : (
        <div className={classNames("px-3 flex-none")}>
          <div className="h-16 flex justify-between items-center gap-3">
            <div
              onClick={() => setIsChatHistoryVisible((prev) => !prev)}
              className="group/icons cursor-pointer rounded-lg"
            >
              <ChatHistoryShowIcon />
              <ChatHistoryShowHoverIcon />
            </div>
            <div
              title={tooltipText}
              onClick={createChatWithOldVariants}
              className="group/icons cursor-pointer rounded-lg"
            >
              <CreateChatIcon />
              <CreateChatHoverIcon />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const ChatListItem = ({ chat }) => {
  const { chatId } = useParams();
  const [isHovering, setIsHovering] = useState(false);

  return (
    <Link
      to={chat.chatId}
      onMouseOver={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      className={classNames(
        "px-3 py-3 flex gap-3 items-center rounded-tl-lg rounded-bl-lg cursor-pointer",
        chat?.chatId === chatId ? "bg-[#F1EAF6]" : "hover:bg-[#EDEFF4]"
      )}
    >
      {chat?.chatId === chatId ? (
        <span className="flex-none">
          <ChatHistoryOutlinedIcon />
        </span>
      ) : (
        <span className="flex-none">
          {isHovering ? (
            <ChatHistoryOutlinedIcon fill="#000000" />
          ) : (
            <ChatHistoryIcon />
          )}
        </span>
      )}
      <span
        className={classNames(
          "flex-auto text-sm truncate",
          chat?.chatId === chatId
            ? "font-semibold text-[#7A3DAA]"
            : "text-[#536787]",
          {
            "text-black font-semibold": isHovering && chat?.chatId !== chatId,
          }
        )}
      >
        {chat.chatTitle}
      </span>
    </Link>
  );
};
