import classNames from "classnames";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import Persona from "./Persona";
import ChatStimuliSelect from "../chat/ChatStimuliSelect";

import { useProject } from "../../contexts/new-project.context";

import {
  DownMenuIcon,
  PlusIcon,
  StimuliDetailsDesignElementsIcon,
  StumiliDetailsLogoIcon,
  StumiliDetailsPackagingIcon,
} from "../../assets/icons";

export default function StimuliSidebar() {
  const location = useLocation();
  const navigate = useNavigate();

  const isChatPage = location.pathname.includes("chat");

  const {
    logoFiles,
    packagingFiles,
    designElementsFiles,
    personas,
    selectedPersonas,
    currentPersona,
    setCurrentPersona,
    currentStimuli,
    setCurrentStimuli,
    setCurrentSelectedStimuli,
  } = useProject();

  const [isLogoMenuOpen, setIsLogoMenuOpen] = useState(false);
  const [isPackagingMenuOpen, setIsPackagingMenuOpen] = useState(false);
  const [isDesignElementsMenuOpen, setIsDesignElementsMenuOpen] =
    useState(false);
  const [isAllExpanded, setIsAllExpanded] = useState(false);

  const [stimuliHover, setStimuliHover] = useState({
    visible: false,
    imageSrc: "",
  });

  const choosePersona = (persona) => {
    setCurrentPersona(persona);

    if (isChatPage) {
      navigate("chat/new");
    }
  };

  /* const handleStimuliFileSelect = async (e) => {
    const { name, files } = e.target;
    const formData = new FormData();
    let fileCategory = "";

    if (name === "logoFiles") fileCategory = "logo";
    else if (name === "packagingFiles") fileCategory = "packaging_design";
    else if (name === "designElementsFiles") fileCategory = "design_element";

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      formData.append("image_files", file);
    }

    try {
      const fileUploadResponse = await httpRequest({
        method: "POST",
        url: `/projects/${projectId}/${fileCategory}/image_files`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
        isAuthRequired: true,
      });

      if (name === "logoFiles")
        setLogoFiles((prev) => [...prev, ...fileUploadResponse.data]);
      else if (name === "packagingFiles")
        setPackagingFiles((prev) => [...prev, ...fileUploadResponse.data]);
      else if (name === "designElementsFiles")
        setDesignElementsFiles((prev) => [...prev, ...fileUploadResponse.data]);
    } catch (error) {
      handleAPIError(error);
    }
  }; */

  const selectedPersonaList = personas.filter((persona) =>
    selectedPersonas.includes(persona.persona_id)
  );

  useEffect(() => {
    const personaList = personas.filter((persona) =>
      selectedPersonas.includes(persona.persona_id)
    );
    const firstSelectedPersona = personaList[0];

    setCurrentPersona(firstSelectedPersona);
    // eslint-disable-next-line
  }, [personas, selectedPersonas]);

  return (
    <>
      <div className="w-72 overflow-auto flex-none flex flex-col border-r border-[#D2D9E5]">
        <div className="flex-auto overflow-auto flex flex-col">
          {/* PERSONAS START */}
          <div className="flex-none border-b border-[#D2D9E5]">
            <div className="px-4 h-11 flex justify-between items-center bg-[#F7F8FA]">
              <span className="text-sm font-semibold">PERSONAS</span>
            </div>

            <div className="h-[230px] px-4 py-3 overflow-auto space-y-5">
              {selectedPersonaList.map((persona) => (
                <Persona
                  key={persona.persona_id}
                  persona={persona}
                  isSelected={persona.persona_id === currentPersona?.persona_id}
                  choosePersona={choosePersona}
                />
              ))}
            </div>
          </div>
          {/* PERSONAS END */}

          {/* STIMULI DETAILS START */}
          {isChatPage ? (
            <ChatStimuliSelect setStimuliHover={setStimuliHover} />
          ) : (
            <div className="flex-auto overflow-auto flex flex-col">
              <div className="flex-none px-4 h-11 flex justify-between items-center bg-[#F7F8FA]">
                <span className="text-sm font-semibold">DESIGNS</span>
                <button
                  onClick={() => {
                    if (isAllExpanded) {
                      setIsAllExpanded(false);
                      setIsLogoMenuOpen(false);
                      setIsPackagingMenuOpen(false);
                      setIsDesignElementsMenuOpen(false);
                    } else {
                      setIsAllExpanded(true);
                      setIsLogoMenuOpen(true);
                      setIsPackagingMenuOpen(true);
                      setIsDesignElementsMenuOpen(true);
                    }
                  }}
                  className="text-xs font-medium text-[#7A3DAA]"
                >
                  {isAllExpanded ? "Hide" : "Show all"}
                </button>
              </div>

              <div className="pb-2 flex-auto overflow-auto">
                <div>
                  <button
                    onClick={() => {
                      setIsLogoMenuOpen((prev) => !prev);
                    }}
                    className="px-4 py-4 w-full flex justify-between items-center text-sm font-medium"
                  >
                    <span className="flex items-center gap-x-3">
                      <StumiliDetailsLogoIcon width={12} height={12} />
                      <span
                        className={classNames(
                          "text-sm",
                          isLogoMenuOpen
                            ? "font-semibold text-[#7A3DAA]"
                            : "font-medium"
                        )}
                      >
                        Logo
                      </span>
                    </span>
                    <span
                      className={classNames({ "rotate-180": isLogoMenuOpen })}
                    >
                      <DownMenuIcon
                        fill={isLogoMenuOpen ? "#7A3DAA" : "#536787"}
                      />
                    </span>
                  </button>

                  {isLogoMenuOpen ? (
                    <div onClick={() => setCurrentSelectedStimuli("logo")}>
                      <div className="px-4 mb-2 max-h-72 overflow-auto grid grid-cols-2 gap-2">
                        {logoFiles.map((item) => (
                          <div
                            key={item.image_hash}
                            onClick={() =>
                              setCurrentStimuli({ ...item, stimuli: "logo" })
                            }
                            onMouseOver={() =>
                              setStimuliHover({
                                visible: true,
                                imageSrc: item.public_url,
                              })
                            }
                            onMouseLeave={() =>
                              setStimuliHover({ visible: false, imageSrc: "" })
                            }
                            className={classNames(
                              "h-32 rounded cursor-pointer bg-[#EDEFF4]",
                              {
                                "border-2 border-[#7A3DAA]":
                                  currentStimuli?.image_hash ===
                                  item.image_hash,
                              }
                            )}
                          >
                            <img
                              src={item.public_url}
                              alt={item.name}
                              className="w-full h-full object-contain rounded-lg"
                            />
                          </div>
                        ))}
                      </div>

                      {/* <div className="px-4">
                        <label
                          htmlFor="logoFiles"
                          className={classNames(
                            "p-3 w-full flex items-center justify-center gap-x-2 rounded text-[#AA48CB] bg-[#F1EAF6]",
                            isStimuliProcessing
                              ? "cursor-not-allowed"
                              : "cursor-pointer"
                          )}
                        >
                          <NewUploadIcon fill="#AA48CB" />

                          <span>Upload More</span>
                        </label>

                        <input
                          type="file"
                          disabled={isStimuliProcessing}
                          accept="image/jpg,image/jpeg,image/png"
                          id="logoFiles"
                          name="logoFiles"
                          multiple={true}
                          onChange={handleStimuliFileSelect}
                          className="hidden"
                        />
                      </div> */}
                    </div>
                  ) : null}
                </div>

                <div>
                  <button
                    onClick={() => {
                      setIsPackagingMenuOpen((prev) => !prev);
                    }}
                    className="p-4 w-full flex justify-between items-center text-sm font-medium"
                  >
                    <span className="flex items-center gap-x-3">
                      <StumiliDetailsPackagingIcon width={12} height={12} />
                      <span
                        className={classNames(
                          "text-sm",
                          isPackagingMenuOpen
                            ? "font-semibold text-[#7A3DAA]"
                            : "font-medium"
                        )}
                      >
                        Packaging
                      </span>
                    </span>
                    <span
                      className={classNames({
                        "rotate-180": isPackagingMenuOpen,
                      })}
                    >
                      <DownMenuIcon
                        fill={isPackagingMenuOpen ? "#7A3DAA" : "#536787"}
                      />
                    </span>
                  </button>

                  {isPackagingMenuOpen ? (
                    <div
                      onClick={() =>
                        setCurrentSelectedStimuli("packaging_design")
                      }
                    >
                      <div className="px-4 mb-2 max-h-72 overflow-auto grid grid-cols-2 gap-2">
                        {packagingFiles.map((item) => (
                          <div
                            key={item.image_hash}
                            onClick={() =>
                              setCurrentStimuli({
                                ...item,
                                stimuli: "packaging",
                              })
                            }
                            onMouseOver={() =>
                              setStimuliHover({
                                visible: true,
                                imageSrc: item.public_url,
                              })
                            }
                            onMouseLeave={() =>
                              setStimuliHover({ visible: false, imageSrc: "" })
                            }
                            className={classNames(
                              "h-32 rounded cursor-pointer bg-[#EDEFF4]",
                              {
                                "border-2 border-[#7A3DAA]":
                                  currentStimuli?.image_hash ===
                                  item.image_hash,
                              }
                            )}
                          >
                            <img
                              src={item.public_url}
                              alt={item.name}
                              className="w-full h-full object-contain rounded-lg"
                            />
                          </div>
                        ))}
                      </div>

                      {/* <div className="px-4">
                        <label
                          htmlFor="packagingFiles"
                          className={classNames(
                            "p-3 w-full flex items-center justify-center gap-x-2 rounded text-[#89A318] bg-[#F5F7EC]",
                            isStimuliProcessing
                              ? "cursor-not-allowed"
                              : "cursor-pointer"
                          )}
                        >
                          <NewUploadIcon fill="#89A318" />

                          <span>Upload More</span>
                        </label>

                        <input
                          type="file"
                          disabled={isStimuliProcessing}
                          accept="image/jpg,image/jpeg,image/png"
                          id="packagingFiles"
                          name="packagingFiles"
                          multiple={true}
                          onChange={handleStimuliFileSelect}
                          className="hidden"
                        />
                      </div> */}
                    </div>
                  ) : null}
                </div>

                <div>
                  <button
                    onClick={() => {
                      setIsDesignElementsMenuOpen((prev) => !prev);
                    }}
                    className="p-4 w-full flex justify-between items-center text-sm font-medium"
                  >
                    <span className="flex items-center gap-x-3">
                      <StimuliDetailsDesignElementsIcon
                        width={12}
                        height={12}
                      />
                      <span
                        className={classNames(
                          "text-sm",
                          isDesignElementsMenuOpen
                            ? "font-semibold text-[#7A3DAA]"
                            : "font-medium"
                        )}
                      >
                        Concept
                      </span>
                    </span>
                    <span
                      className={classNames({
                        "rotate-180": isDesignElementsMenuOpen,
                      })}
                    >
                      <DownMenuIcon
                        fill={isDesignElementsMenuOpen ? "#7A3DAA" : "#536787"}
                      />
                    </span>
                  </button>

                  {isDesignElementsMenuOpen ? (
                    <div
                      onClick={() =>
                        setCurrentSelectedStimuli("design_element")
                      }
                    >
                      <div className="px-4 mb-2 max-h-72 overflow-auto grid grid-cols-2 gap-2">
                        {designElementsFiles.map((item) => (
                          <div
                            key={item.image_hash}
                            onClick={() =>
                              setCurrentStimuli({
                                ...item,
                                stimuli: "design_elements",
                              })
                            }
                            onMouseOver={() =>
                              setStimuliHover({
                                visible: true,
                                imageSrc: item.public_url,
                              })
                            }
                            onMouseLeave={() =>
                              setStimuliHover({ visible: false, imageSrc: "" })
                            }
                            className={classNames(
                              "h-32 rounded cursor-pointer bg-[#EDEFF4]",
                              {
                                "border-2 border-[#7A3DAA]":
                                  currentStimuli?.image_hash ===
                                  item.image_hash,
                              }
                            )}
                          >
                            <img
                              src={item.public_url}
                              alt={item.name}
                              className="w-full h-full object-contain rounded-lg"
                            />
                          </div>
                        ))}
                      </div>

                      {/* <div className="px-4">
                        <label
                          htmlFor="designElementsFiles"
                          className={classNames(
                            "p-3 w-full flex items-center justify-center gap-x-2 rounded text-[#1F70E1] bg-[#EDF4FD]",
                            isStimuliProcessing
                              ? "cursor-not-allowed"
                              : "cursor-pointer"
                          )}
                        >
                          <NewUploadIcon fill="#1F70E1" />

                          <span>Upload More</span>
                        </label>

                        <input
                          type="file"
                          disabled={isStimuliProcessing}
                          accept="image/jpg,image/jpeg,image/png"
                          id="designElementsFiles"
                          name="designElementsFiles"
                          multiple={true}
                          onChange={handleStimuliFileSelect}
                          className="hidden"
                        />
                      </div> */}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          )}
          {/* STIMULI DETAILS END */}
        </div>

        {isChatPage && (
          <div className="p-4 flex-none">
            <Link
              to="chat/new"
              className="w-full p-3 flex items-center justify-center rounded-lg gap-2 text-white bg-[#7A3DAA] hover:bg-[#4E2670]"
            >
              <PlusIcon className="stroke-white" />
              <span>New Interview</span>
            </Link>
          </div>
        )}
      </div>

      <div
        onMouseOver={() => setStimuliHover({ ...stimuliHover, visible: true })}
        onMouseLeave={() => setStimuliHover({ visible: false, imageSrc: "" })}
        className={classNames(
          "w-[550px] h-[550px] fixed z-40 left-[300px] top-2/4 -translate-y-2/4 rounded-xl bg-white border shadow-2xl",
          stimuliHover.visible ? "block" : "hidden"
        )}
      >
        <img
          src={stimuliHover.imageSrc}
          alt="Stimuli input preview"
          className="w-full h-full object-contain rounded-lg"
        />
      </div>
    </>
  );
}
