import lightBg from "../../assets/png/light-bg.png";
import personaSkeletonAvatar from "../../assets/png/persona-skeleton-avatar.png";

export default function GeneratePersonaSkeletonLoader() {
  return (
    <div className="space-y-10 relative before:absolute before:z-20 before:inset-0 before:-translate-x-full before:animate-[shimmer_2s_infinite] before:bg-gradient-to-r before:from-transparent before:via-white before:to-transparent isolate overflow-hidden">
      <div className="relative px-16 py-6 max-lg:p-5 border bg-[#F7F2FB] border-[#E7EBF1] rounded-[12px]">
        <img
          src={lightBg}
          alt="Bulb icon"
          className="w-full h-full absolute inset-0 z-10 opacity-10"
        />

        <div className="relative flex gap-10 max-lg:flex-wrap">
          <div className="flex-none rounded-full bg-[#F1EAF6] h-40 w-40">
            <img
              src={personaSkeletonAvatar}
              alt="Persona Skeleton Avatar"
              className="w-full h-full rounded-full object-contain"
            />
          </div>
          <div className="flex-auto space-y-6">
            <div className="w-56 h-3 rounded-[40px] bg-[#E5D4F7]" />
            <div className="grid lg:grid-cols-2 gap-x-10 gap-y-5">
              {new Array(6).fill(1).map((_, index) => (
                <div key={index} className="h-2 rounded-[40px] bg-white" />
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="p-5 border bg-[#F9F5FF] border-[#F7E7FB] rounded-lg relative">
        <div className="space-y-5">
          <div className="w-52 h-3 bg-[#E5D4F7] rounded-[40px]" />
          <div className="space-y-3">
            {new Array(4).fill(1).map((_, index) => (
              <div key={index} className="h-3 rounded-[40px] bg-white" />
            ))}
            <div className="w-[426px] h-3 rounded-[40px] bg-white" />
          </div>
        </div>
      </div>

      <div className="p-5 border bg-[#F2E8FD] border-[#F7E7FB] rounded-lg relative">
        <div className="space-y-5">
          <div className="w-[339px] h-3 bg-[#EADCFA] rounded-[40px]" />
          <div className="space-y-3">
            {new Array(4).fill(1).map((_, index) => (
              <div key={index} className="h-3 rounded-[40px] bg-white" />
            ))}
            <div className="w-[195px] h-3 rounded-[40px] bg-white" />
          </div>
        </div>
      </div>

      <div className="p-5 border bg-[#F9F5FF] border-[#F7E7FB] rounded-lg">
        <div className="space-y-5">
          <div className="w-52 h-3 bg-[#E5D4F7] rounded-[40px]" />
          <div className="space-y-3">
            {new Array(4).fill(1).map((_, index) => (
              <div key={index} className="h-3 rounded-[40px] bg-white" />
            ))}
            <div className="w-[426px] h-3 rounded-[40px] bg-white" />
          </div>
        </div>
      </div>
    </div>
  );
}

export function DemographicsSkeletonLoader() {
  return (
    <div className="relative px-16 py-6 max-lg:p-5 border bg-[#F7F2FB] border-[#E7EBF1] rounded-[12px] before:absolute before:z-20 before:inset-0 before:-translate-x-full before:animate-[shimmer_2s_infinite] before:bg-gradient-to-r before:from-transparent before:via-white before:to-transparent isolate overflow-hidden">
      <img
        src={lightBg}
        alt="Bulb icon"
        className="w-full h-full absolute inset-0 z-10 opacity-10"
      />

      <div className="relative flex gap-10 max-lg:flex-wrap">
        <div className="flex-none rounded-full bg-[#F1EAF6] h-40 w-40">
          <img
            src={personaSkeletonAvatar}
            alt="Persona Skeleton Avatar"
            className="w-full h-full rounded-full object-contain"
          />
        </div>
        <div className="flex-auto space-y-6">
          <div className="w-56 h-3 rounded-[40px] bg-[#E5D4F7]" />
          <div className="grid lg:grid-cols-2 gap-x-10 gap-y-5">
            {new Array(6).fill(1).map((_, index) => (
              <div key={index} className="h-2 rounded-[40px] bg-white" />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export function SummarySkeletonLoader() {
  return (
    <div className="p-5 border bg-[#F9F5FF] border-[#F7E7FB] rounded-lg relative before:absolute before:z-20 before:inset-0 before:-translate-x-full before:animate-[shimmer_2s_infinite] before:bg-gradient-to-r before:from-transparent before:via-white before:to-transparent isolate overflow-hidden">
      <div className="space-y-5">
        <div className="w-52 h-3 bg-[#E5D4F7] rounded-[40px]" />
        <div className="space-y-3">
          {new Array(4).fill(1).map((_, index) => (
            <div key={index} className="h-3 rounded-[40px] bg-white" />
          ))}
          <div className="w-[426px] h-3 rounded-[40px] bg-white" />
        </div>
      </div>
    </div>
  );
}

export function ProfileSkeletonLoader() {
  return (
    <div className="p-5 border bg-[#F2E8FD] border-[#F7E7FB] rounded-lg relative before:absolute before:z-20 before:inset-0 before:-translate-x-full before:animate-[shimmer_2s_infinite] before:bg-gradient-to-r before:from-transparent before:via-white before:to-transparent isolate overflow-hidden">
      <div className="space-y-5">
        <div className="w-[339px] h-3 bg-[#EADCFA] rounded-[40px]" />
        <div className="space-y-3">
          {new Array(4).fill(1).map((_, index) => (
            <div key={index} className="h-3 rounded-[40px] bg-white" />
          ))}
          <div className="w-[195px] h-3 rounded-[40px] bg-white" />
        </div>
      </div>
    </div>
  );
}
