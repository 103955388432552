import classNames from "classnames";
import { useEffect, useRef, useState } from "react";

import HighlightedText from "../project-stimuli/HighlightedText";

import { useProject } from "../../contexts/new-project.context";
import { useAuth } from "../../contexts/auth.context";

import MalePersonaImage from "../../assets/png/male-persona.png";
/* import {
  CLoseIcon,
  ChatResponseCopyIcon,
  ChatResponseDislikeIcon,
  ChatResponseLikeIcon,
  ChatResponseRefreshIcon,
  ChatResponseShareIcon,
  ChatResponseSoundIcon,
} from "../../assets/icons"; */

export default function ChatQna({ qnaList }) {
  const chatContainerRef = useRef(null);

  useEffect(() => {
    const element = chatContainerRef.current;
    if (element) {
      element.scrollTop = element.scrollHeight;
    }
  }, [qnaList]);

  return (
    <div
      ref={chatContainerRef}
      className="px-7 pt-7 space-y-7 flex-auto overflow-auto"
    >
      {qnaList.map((qna, index) => (
        <SingleQna key={index} qna={qna} />
      ))}

      {/* <div className="w-80 mx-auto my-8 flex border rounded-lg border-[#D2D9E5]">
        <div className="py-4 flex justify-center items-center flex-1 gap-3">
          <span className="text-[10px] font-medium text-[#060606]">
            Is this conversation helpful so far?
          </span>
          <button>
            <ChatResponseLikeIcon stroke="#000000" />
          </button>
          <button>
            <ChatResponseDislikeIcon stroke="#000000" />
          </button>
        </div>
        <button className="px-5 py-4 border-l flex-none">
          <CLoseIcon stroke="#000000" />
        </button>
      </div> */}
    </div>
  );
}

function SingleQna({ qna }) {
  const { user } = useAuth();
  const { currentPersona } = useProject();

  return (
    <div className="flex flex-col gap-7">
      {/* Question */}
      <div className="w-4/5 flex-none self-end flex gap-3 justify-end">
        <p className="p-7 text-sm flex-auto rounded-[10px] text-[#536787] bg-[#F9F2FE]">
          {qna.question || qna.user || ""}
        </p>

        <div className="flex-none w-10 h-10 rounded-full">
          <img
            src={user?.photoURL || MalePersonaImage}
            alt="User profile"
            className="w-full h-full object-contain rounded-full"
          />
        </div>
      </div>

      {/* Answer */}
      <div className="w-10/12 flex-none flex gap-3">
        <div className="w-10 h-10 flex-none">
          <img
            src={currentPersona?.avatar_url || MalePersonaImage}
            alt={`${currentPersona?.name} persona`}
            className="w-full h-full object-contain rounded-full"
          />
        </div>

        {qna.loading && (!qna?.answer || !qna?.assistant) ? (
          <AnswerLoader loading={qna.loading} />
        ) : (
          <ChatResponse qna={qna} />
        )}
      </div>
    </div>
  );
}

function AnswerLoader({ loading }) {
  const [loadingStep, setLoadingStep] = useState(1);

  useEffect(() => {
    if (loading) {
      const intervalId = setInterval(() => {
        setLoadingStep((prev) => {
          if (prev === 3) {
            return 1;
          } else return prev + 1;
        });
      }, 200);

      return () => clearInterval(intervalId);
    }
  }, [loading]);

  return (
    <div className="p-7 flex-auto rounded-[10px] bg-[#F7F8FA] space-x-2">
      <span
        className={classNames(
          "inline-block w-[8.5px] h-[8.5px] rounded-full bg-black",
          {
            "bg-opacity-10": loadingStep !== 1,
          }
        )}
      />
      <span
        className={classNames(
          "inline-block w-[8.5px] h-[8.5px] rounded-full bg-black",
          {
            "bg-opacity-10": loadingStep !== 2,
          }
        )}
      />
      <span
        className={classNames(
          "inline-block w-[8.5px] h-[8.5px] rounded-full bg-black",
          {
            "bg-opacity-10": loadingStep !== 3,
          }
        )}
      />
    </div>
  );
}

function ChatResponse({ qna }) {
  return (
    <div className="p-7 flex-auto rounded-[10px] bg-[#F7F8FA]">
      <pre className="text-sm text-[#536787] whitespace-pre-wrap font-poppins">
        <HighlightedText text={qna?.answer || qna?.assistant || "No answer"} />
      </pre>

      {/* <div className="space-x-4">
        <button>
          <ChatResponseSoundIcon />
        </button>
        <button>
          <ChatResponseCopyIcon />
        </button>
        <button>
          <ChatResponseShareIcon />
        </button>
        <button>
          <ChatResponseRefreshIcon />
        </button>
      </div> */}
    </div>
  );
}
