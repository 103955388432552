import { useState } from "react";

import { useAuth } from "../contexts/auth.context";

import MalePersonaImage from "../assets/png/male-persona.png";

export default function ProfileMenu() {
  const { user, logOut } = useAuth();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  return (
    <div className="relative inline-block">
      <div
        onClick={toggleMenu}
        className="w-11 h-11 cursor-pointer rounded-full outline outline-2 outline-[#E7EBF1] border-2 border-transparent"
      >
        <img
          src={user?.photoURL || MalePersonaImage}
          alt="User profile menu"
          className="w-full h-full object-contain rounded-full"
        />
      </div>

      {isMenuOpen && (
        <div className="min-w-56 mt-2 origin-top-right absolute right-0 z-50 border rounded shadow bg-[#F7F8FA] border-[#D2D9E5]">
          <div className="p-3">
            <p className="text-[#06060699]">{user.email}</p>
          </div>

          <hr className="h-1" />

          <div
            className="p-3 cursor-pointer flex items-center gap-x-2 bg-[#F7F8FA] hover:bg-opacity-70"
            onClick={logOut}
          >
            <img src="./svg/Signout.svg" alt="" /> <p>Sign Out</p>
          </div>
        </div>
      )}
    </div>
  );
}
