import { InfoIcon2 } from "../../assets/icons";

export default function ValidateErrorInput({
  packError,
  handleChange,
  isFormTouched,
  setIsFormTouched,
}) {
  return (
    <div className="flex gap-x-4 items-center">
      <div className="min-h-12 px-3 py-[5px] w-32 flex-none flex items-center rounded-lg bg-[#F1EAF6]">
        <label className="text-sm font-medium text-[#060606]">
          {packError.keyword}
        </label>
      </div>
      <div className="flex-1 relative bg-[#F7F8FA]">
        <input
          value={packError.desc}
          onBlur={() => setIsFormTouched(true)}
          type="text"
          placeholder="Enter brief description*"
          name="brandName"
          onChange={(e) => handleChange(packError.id, e.target.value)}
          className="w-full p-3 h-full border rounded-[5px] text-sm font-medium outline-none text-[#060606] bg-transparent border-[#E7EBF1] focus:border-[#7A3DAA]"
        />
        {isFormTouched && !packError.desc ? (
          <div className="absolute top-1/2 right-2 -translate-y-1/2 flex items-center gap-x-1">
            <InfoIcon2 />
            <span className="text-xs font-medium text-[#DEA028]">
              Action required
            </span>
          </div>
        ) : null}
      </div>
    </div>
  );
}
