import classNames from "classnames";
import { useRef, useState } from "react";

import { useProject } from "../../contexts/new-project.context";
import { useError } from "../../contexts/error.context";

import HighlightedText2 from "../HighlightedText2";

import { wait } from "../../utils/promise.util";
import { httpRequest } from "../../utils/http.util";

import { UpdatePersonaPensilIcon } from "../../assets/icons";

export default function PersonaProfileCard({
  isEditable = true,
  personaProfile,
  handlePersonaProfileChange,
  generatedPersonaDetails,
  setGeneratedPersonaDetails,
}) {
  const personaId = generatedPersonaDetails?.persona_id;

  const textboxRef = useRef(null);

  const { projectId } = useProject();
  const { handleAPIError } = useError();

  const [isUpdating, setIsUpdating] = useState(false);

  const handleEditButtonClick = async () => {
    setIsUpdating(true);
    await wait(100);
    textboxRef.current.focus();
  };

  const handleUpdate = async () => {
    try {
      setIsUpdating(false);

      const payload = {
        persona_name: generatedPersonaDetails?.persona_info?.persona_name || "",
        demographics:
          generatedPersonaDetails.persona_info.demographics.map((item) => ({
            ...item,
            id: undefined,
          })) || [],
        profile:
          generatedPersonaDetails.persona_info.profile.map((item) => ({
            ...item,
            section: `${item.icon} ${item.section}`,
            icon: undefined,
            id: undefined,
          })) || [],
        brief_summary:
          generatedPersonaDetails?.persona_info?.brief_summary || "",
      };

      const response = await httpRequest({
        domain: "chat",
        url: `/projects/${projectId}/personas/${personaId}/brief_summary`,
        method: "POST",
        data: payload,
        isAuthRequired: true,
      });

      setGeneratedPersonaDetails((prev) => ({
        ...prev,
        persona_info: {
          ...prev.persona_info,
          brief_summary: response.data,
        },
      }));
    } catch (error) {
      handleAPIError(error);
    }
  };

  return (
    <div className="relative flex flex-col">
      {isEditable ? (
        <button
          onClick={handleEditButtonClick}
          className={classNames("absolute top-4 right-4 z-30", {
            hidden: isUpdating,
          })}
        >
          <UpdatePersonaPensilIcon />
        </button>
      ) : null}
      <p className="flex-none p-5 border rounded-t-[10px] bg-[#F7F8FA]">
        <span>{personaProfile.icon}</span> {personaProfile.section}
      </p>
      <div
        className={classNames(
          "flex-auto border rounded-b-[10px]",
          isUpdating ? "border-[#7A3DAA]" : "border-[#F1EAF6]"
        )}
      >
        <textarea
          ref={textboxRef}
          onBlur={handleUpdate}
          value={personaProfile.content}
          onChange={(e) => handlePersonaProfileChange(e, personaProfile.id)}
          className={classNames(
            "w-full h-full hidden-scroll px-5 py-4 rounded-b-[10px] resize-none outline-none",
            {
              hidden: !isUpdating,
            }
          )}
        />
        <pre
          className={classNames(
            "h-full overflow-auto hidden-scroll px-5 py-4 rounded-b-[10px] whitespace-pre-wrap font-poppins space-y-4",
            {
              hidden: isUpdating,
            }
          )}
        >
          <HighlightedText2 text={personaProfile.content} color="#7A3DAA" />
        </pre>
      </div>
    </div>
  );
}
