import classNames from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { auth } from "../configs/firebase.config";

import Button from "../components/NewButton";
import ChatQna from "../components/chat/ChatQna";
import PersonaDetailsModal from "../components/modals/Persona-Details-Modal";

import { useProject } from "../contexts/new-project.context";
import { useChat } from "../contexts/chat.context";
import { useError } from "../contexts/error.context";

import {
  ChatMicIcon,
  ChatUploadIcon,
  CheckIcon,
  NewChatMessageIcon,
  SampleQuestionIcon,
  SearchIcon,
  SubmitChatQuestionIcon,
} from "../assets/icons";

import MalePersonaImage from "../assets/png/male-persona.png";

export default function CreateChat() {
  const [searchParams] = useSearchParams();
  const selectedStimuliFromUrl = useMemo(
    () => searchParams.get("selectedStimuli"),
    [searchParams]
  );
  const selectedVariantFromUrl = useMemo(
    () => searchParams.getAll("selectedVariant"),
    [searchParams]
  );

  const navigate = useNavigate();
  const {
    projectId,
    currentPersona,
    logoFiles,
    packagingFiles,
    designElementsFiles,
  } = useProject();

  const stimuliList = [
    { field: "logo", label: "Logo", files: logoFiles },
    {
      field: "packaging_design",
      label: "Packaging",
      files: packagingFiles,
    },
    {
      field: "design_element",
      label: "Concept",
      files: designElementsFiles,
    },
  ];

  const {
    setChatId,
    selectedStimuli,
    setSelectedStimuli,
    selectedVariants,
    setSelectedVariants,
    isPersonaModalOpen,
    setIsPersonaModalOpen,
    setChatHistory,
    setChatData,
    handleChatDetails,
    isChatLoading,
    setIsChatLoading,
  } = useChat();
  const { handleAPIError } = useError();

  const [isChatCreated, setIsChatCreated] = useState(
    selectedStimuliFromUrl && selectedVariantFromUrl?.length ? true : false
  );

  const [chatResponse, setChatResponse] = useState("");
  const [question, setQuestion] = useState("");
  const [qnaList, setQnaList] = useState([]);

  const handleMountOver = (e) => {
    const checkbox = e.currentTarget.querySelector(".checkbox");
    const variantName = e.currentTarget.querySelector(".variant-name");
    variantName.style.fontWeight = 600;
    variantName.style.color = "#7A3DAA";
    checkbox.style.borderColor = "#7A3DAA";
    checkbox.style.outline = "4px solid #F1EAF6";
  };

  const handleMountLeave = (e) => {
    const checkbox = e.currentTarget.querySelector(".checkbox");
    const variantName = e.currentTarget.querySelector(".variant-name");
    variantName.style.fontWeight = 500;
    variantName.style.color = "#536787";
    checkbox.style.borderColor = "#899BB6";
    checkbox.style.outline = "none";
  };

  const handleVariantClick = (clickedVariantId) => {
    if (selectedVariants.includes(clickedVariantId)) {
      setSelectedVariants((prev) =>
        prev.filter((item) => item !== clickedVariantId)
      );
    } else {
      setSelectedVariants((prev) => [...prev, clickedVariantId]);
    }
  };

  const handleStartChat = () => {
    setIsChatCreated(true);
  };

  const selectedVariantsList = packagingFiles.filter((item) =>
    selectedVariants.includes(item.image_hash)
  );

  const handleChatSubmit = async (e, suggestedQuestion) => {
    if (e) e.preventDefault();

    if (!question && !suggestedQuestion) return;

    setQuestion("");
    setIsChatLoading(true);

    let qstn = question || suggestedQuestion;

    setQnaList((prev) => [
      ...prev,
      { question: qstn, answer: "", loading: true },
    ]);

    try {
      const accessToken = await auth.currentUser.getIdToken();

      const response = await fetch(
        `https://kntr-chat-3m2lt2iata-el.a.run.app/v1/projects/${projectId}/chat`,
        {
          method: "POST",
          body: JSON.stringify({
            persona_id: currentPersona?.persona_id,
            category: selectedStimuli?.field,
            image_hashes: selectedVariants,
            user_query: qstn,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const reader = response.body.getReader();
      let data = "";
      let currentChatId = "";

      while (true) {
        const { done, value } = await reader.read();

        if (done) break;

        const decodedText = new TextDecoder().decode(value);
        const jsonObjects = decodedText.match(/({[^}]*})/g);

        for (let i = 0; i < jsonObjects.length; i++) {
          const obj = jsonObjects[i];
          const messageObj = JSON.parse(obj);

          if (messageObj?.type === "chat_id") {
            currentChatId = messageObj?.chat_id;
            // eslint-disable-next-line
            setChatHistory((prev) => [
              { chatId: currentChatId, chatTitle: "New Interview" },
              ...prev,
            ]);
          }
          if (messageObj?.type === "message") {
            data += messageObj?.message;
          }
          if (messageObj?.type === "title") {
            // eslint-disable-next-line
            setChatHistory((prev) => {
              return prev.map((chatItem) =>
                chatItem.chatId === currentChatId
                  ? {
                      ...chatItem,
                      chatTitle: messageObj?.title || "New Interview",
                    }
                  : chatItem
              );
            });
          }
        }

        setChatResponse(data);
      }

      const newChatPayload = {
        category: selectedStimuli?.field,
        image_hashes: selectedVariants,
        persona_id: currentPersona?.persona_id,
        chats: [
          {
            id: 1,
            loading: false,
            user: qstn,
            assistant: data,
          },
        ],
      };

      const chatDetails = handleChatDetails(newChatPayload);

      setChatId(currentChatId);
      setChatData(chatDetails);

      // getChatHistory();

      setIsChatLoading(false);

      navigate(
        `/project-stimuli/${projectId}/chat/${currentChatId}?shouldLoadChat=false`,
        { replace: true }
      );
    } catch (error) {
      handleAPIError(error);
    }
  };

  const closeModal = () => {
    setIsPersonaModalOpen(false);
  };

  const handleSampleQuestionClick = async (suggestedQuestion) => {
    setQuestion(suggestedQuestion);
    handleChatSubmit(null, suggestedQuestion);
  };

  useEffect(() => {
    if (selectedStimuliFromUrl && selectedVariantFromUrl?.length) {
      setSelectedStimuli(
        stimuliList.find((stimuli) => stimuli?.field === selectedStimuliFromUrl)
      );
      setSelectedVariants(selectedVariantFromUrl);
      setIsChatCreated(true);
    } else {
      setSelectedStimuli(stimuliList[1]);
      setSelectedVariants([]);
      setIsChatCreated(false);
    }

    // eslint-disable-next-line
  }, [selectedStimuliFromUrl, selectedVariantFromUrl, currentPersona]);

  useEffect(() => {
    if (qnaList.length) {
      setQnaList((prev) => {
        const prevData = [...prev];
        if (prevData[prevData.length - 1].loading) {
          prevData[prevData.length - 1].loading = false;
        }
        prevData[prevData.length - 1].answer = chatResponse;

        return prevData;
      });
    }
    // eslint-disable-next-line
  }, [chatResponse]);

  return (
    <>
      <div className="flex-auto flex flex-col overflow-auto">
        <div className="p-3 border-l border-b flex-none flex overflow-auto justify-between items-center gap-3">
          <div className="flex items-center gap-3 flex-none flex-wrap">
            <div className="flex-none w-10 h-10 relative rounded-full bg-[#F7F8FA]">
              <img
                src={currentPersona?.avatar_url || MalePersonaImage}
                alt={`${currentPersona?.persona_name} persona`}
                className="w-10 h-10 rounded-full bg-[#F7F8FA]"
              />

              <span className="w-3 h-3 absolute bottom-0 right-0 border-2 rounded-full border-white bg-[#33B379]"></span>
            </div>
            <div className="flex-none">
              <p
                onClick={() => setIsPersonaModalOpen(true)}
                className="text-[#7A3DAA] font-semibold cursor-pointer hover:underline hover:underline-offset-1"
              >
                {currentPersona?.persona_name}
              </p>
              {currentPersona?.consumer_segment_name ? (
                <p className="text-[#828282] text-xs">
                  ({currentPersona?.consumer_segment_name})
                </p>
              ) : null}
            </div>

            <div className="flex-none h-10 mx-3 w-px bg-[#E7EBF1]" />

            <div className="flex-none flex items-center gap-2 flex-wrap">
              <div className="text-sm font-medium flex items-center gap-2">
                <NewChatMessageIcon /> <span>New Interview</span>
              </div>

              {isChatCreated && (
                <div className="flex gap-2">
                  {selectedVariantsList?.map?.((item, index) => (
                    <span
                      key={index}
                      className="px-2 py-1 rounded bg-[#F7F8FA] text-[#536787] text-xs"
                    >
                      {item.name}
                    </span>
                  ))}
                </div>
              )}
            </div>
          </div>
          <button className="mr-3 flex-none">
            <SearchIcon />
          </button>
        </div>

        {!isChatCreated && (
          <>
            <div className="flex-auto overflow-auto flex flex-wrap justify-center">
              <div className="p-3 flex flex-col">
                <div className="flex-auto flex items-center justify-center overflow-auto">
                  <div>
                    <p className="inline-flex gap-x-2 text-2xl font-semibold text-[#7A3DAA]">
                      <span>
                        Hello, my name is {currentPersona?.persona_name}
                      </span>
                      <img
                        src="/png/Waving Hand.png"
                        alt="Waving hand icon"
                        className="w-8 h-8"
                      />
                    </p>

                    <p className="mt-1 text-[#99A8C0] text-2xl">
                      I'd love to talk to you about your product
                    </p>

                    <div className="my-8 border border-[#E7EBF1]" />

                    <p>
                      <span className="text-[#7A3DAA] font-semibold">
                        Select the variants
                      </span>{" "}
                      <span>you want to talk about</span>
                    </p>

                    <div className="mt-5 grid grid-cols-2 gap-3 select-none">
                      {selectedStimuli?.files?.map?.((item, index) => (
                        <div
                          key={index}
                          onMouseOver={handleMountOver}
                          onMouseLeave={handleMountLeave}
                          onClick={() => handleVariantClick(item?.image_hash)}
                          className={classNames(
                            "ps-2 pe-5 py-2 flex justify-between items-center rounded-lg border cursor-pointer hover:border-[#7A3DAA]",
                            selectedVariants?.includes?.(item?.image_hash)
                              ? "border-[#7A3DAA]"
                              : "border-[#E7EBF1]"
                          )}
                        >
                          <div className="flex items-center gap-3">
                            <div className="w-[52px] h-[52px] bg-[#EDEFF4] rounded">
                              <img
                                src={item.public_url}
                                alt={item.name}
                                className="w-full h-full rounded object-contain"
                              />
                            </div>
                            <p className="variant-name text-[#536787] text-sm font-medium">
                              {item.name}
                            </p>
                          </div>

                          <div>
                            <span
                              className={classNames(
                                "checkbox w-[18px] h-[18px] inline-flex justify-center items-center rounded-sm border-[1.5px] border-[#899BB6]",
                                {
                                  "bg-[#7A3DAA]": selectedVariants?.includes?.(
                                    item?.image_hash
                                  ),
                                }
                              )}
                            >
                              <CheckIcon fill="#ffffff" />
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="px-10 py-4 flex-none text-right space-x-4">
              <Button
                title="Back"
                variant="secondary"
                onClick={() => navigate(-1, { replace: true })}
              />
              <Button
                title="Next"
                disabled={!selectedStimuli || !selectedVariants?.length}
                variant={
                  !selectedStimuli || !selectedVariants?.length
                    ? "disabled"
                    : "primary"
                }
                onClick={handleStartChat}
              />
            </div>
          </>
        )}

        {isChatCreated && (
          <div className="flex-auto overflow-auto flex flex-col">
            {!qnaList.length ? (
              <div className="p-6 flex-auto flex justify-center items-center">
                <div className="max-w-[668px]">
                  <p className="text-4xl text-[#99A8C0]">Ask me anything...</p>

                  <div className="mt-10">
                    <p className="text-sm text-[#060606] flex items-center gap-x-2">
                      <SampleQuestionIcon />
                      <span>
                        Here are a few starter questions to get things rolling.
                      </span>
                    </p>
                  </div>

                  <div className="mt-5 grid lg:grid-cols-3 gap-4">
                    {[
                      "How did you come to know about this product?",
                      "Will you choose this brand over its competitors?",
                      "What kind of information or content do you find most helpful from similar brands?",
                    ].map((item, index) => (
                      <div
                        key={index}
                        onClick={() => handleSampleQuestionClick(item)}
                        className="p-5 rounded-xl cursor-pointer bg-[#F9F2FE]"
                      >
                        <p className="text-[10px] font-bold text-[#828282]">
                          Question {index + 1}
                        </p>
                        <p className="mt-2 text-sm text-[#060606]">{item}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ) : (
              <ChatQna qnaList={qnaList} />
            )}

            <div className="p-6 mt-5 flex-none">
              <form
                onSubmit={handleChatSubmit}
                className="px-5 flex flex-wrap gap-3 bg-[#F7F8FA] border rounded-lg shadow-md shadow-[#D2D9E5]"
              >
                <div className="flex-none self-center">
                  <button
                    type="button"
                    className="w-6 h-6 rounded-full flex justify-center items-center"
                  >
                    <ChatUploadIcon />
                  </button>
                </div>
                <input
                  autoFocus
                  value={question}
                  onChange={(e) => setQuestion(e.target.value)}
                  placeholder={`Ask anything to ${currentPersona?.persona_name}...`}
                  disabled={isChatLoading}
                  className="py-4 flex-auto text-sm bg-transparent text-[#536787] outline-none placeholder:text-[#536787] placeholder:truncate truncate"
                />
                <div className="flex-none flex gap-2">
                  <button
                    type="button"
                    className="w-6 h-6 self-center rounded-full flex justify-center items-center"
                  >
                    <ChatMicIcon />
                  </button>
                  <button
                    disabled={isChatLoading}
                    type="submit"
                    className={classNames(
                      "w-6 h-6 self-center rounded-full flex justify-center items-center",
                      { "cursor-not-allowed": isChatLoading }
                    )}
                  >
                    <SubmitChatQuestionIcon />
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>

      {isPersonaModalOpen ? (
        <PersonaDetailsModal closeModal={closeModal} />
      ) : null}
    </>
  );
}
