import { useState } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";

import Summary from "./Summary";
import DeepDive from "./DeepDive";

import PersonaDetailsModal from "../modals/Persona-Details-Modal";

import { useProject } from "../../contexts/new-project.context";

import MalePersonaImage from "../../assets/png/male-persona.png";

import {
  DownloadAsPDFIcon,
  MessageIcon,
  StimuliDetailsDesignElementsIcon,
  StumiliDetailsLogoIcon,
  StumiliDetailsPackagingIcon,
} from "../../assets/icons";

export default function StimuliSummary() {
  const {
    projectId,
    currentPersona,
    currentStimuli,
    isSummaryVisible,
    setIsSummaryVisible,
    isDeepDiveVisible,
    setIsDeepDiveVisible,
    currentSelectedStimuli,
  } = useProject();

  const [isPersonaModalOpen, setIsPersonaModalOpen] = useState(false);

  return (
    <>
      <div className="p-10 flex-1 space-y-10 overflow-auto">
        <div>
          <div className="w-[70px] h-[70px] relative rounded-full bg-[#F7F8FA]">
            <img
              src={currentPersona?.avatar_url || MalePersonaImage}
              alt={`${currentPersona?.persona_name} persona`}
              className="w-full h-full object-contain rounded-full bg-[#F7F8FA]"
            />

            <span className="w-3 h-3 absolute bottom-0 right-0 border-2 rounded-full border-white bg-[#33B379]"></span>
          </div>

          <div className="mt-2 mb-4 flex items-center gap-2 flex-wrap">
            <p className="text-2xl font-medium">
              <span
                onClick={() => setIsPersonaModalOpen(true)}
                className="text-[#7A3DAA] cursor-pointer hover:underline hover:underline-offset-1"
              >
                {currentPersona?.persona_name}'s
              </span>{" "}
              <span>insights on</span>
            </p>
            {currentStimuli?.stimuli === "logo" && (
              <StumiliDetailsLogoIcon width={18} height={20} />
            )}
            {currentStimuli?.stimuli === "packaging" && (
              <StumiliDetailsPackagingIcon width={18} height={20} />
            )}
            {currentStimuli?.stimuli === "design_elements" && (
              <StimuliDetailsDesignElementsIcon width={18} height={20} />
            )}
            <p className="text-2xl font-medium space-x-2 text-[#7A3DAA]">
              {currentStimuli?.stimuli === "logo" && <span>Logo</span>}
              {currentStimuli?.stimuli === "packaging" && (
                <span>Packaging</span>
              )}
              {currentStimuli?.stimuli === "design_elements" && (
                <span>Concept</span>
              )}
              <span>•</span>
              <span>{currentStimuli?.name}</span>
            </p>
          </div>

          <div className="flex justify-between items-center flex-wrap gap-4">
            <div className="flex items-center gap-4 flex-wrap">
              <button
                onClick={() => {
                  setIsSummaryVisible(true);
                  setIsDeepDiveVisible(false);
                }}
                className={classNames(
                  "px-4 py-[10px] font-semibold text-sm rounded-full",
                  isSummaryVisible
                    ? "text-white bg-[#7A3DAA]"
                    : "border text-[#536787] border-[#D2D9E5]"
                )}
              >
                SUMMARY
              </button>
              <button
                onClick={() => {
                  setIsSummaryVisible(false);
                  setIsDeepDiveVisible(true);
                }}
                className={classNames(
                  "px-4 py-[10px] border text-sm rounded-full text-[#536787] border-[#D2D9E5]",
                  isDeepDiveVisible
                    ? "text-white bg-[#7A3DAA]"
                    : "border text-[#536787] border-[#D2D9E5]"
                )}
              >
                DEEP DIVE
              </button>
              <span className="h-6 inline-block border border-[#D2D9E5]"></span>
              <Link
                to={`/project-stimuli/${projectId}/chat/new?selectedStimuli=${currentSelectedStimuli}&selectedVariant=${currentStimuli?.image_hash}`}
                className="px-3 py-2 flex items-center gap-x-2 text-sm font-medium border rounded-lg border-[#E7EBF1]"
              >
                <MessageIcon /> Interview {currentPersona?.persona_name}
              </Link>
            </div>
            <button className="px-3 py-2 flex items-center font-medium border gap-x-2 rounded-lg border-[#E7EBF1]">
              <DownloadAsPDFIcon fill="#000000" />
              Download PDF
            </button>
          </div>
        </div>

        {isSummaryVisible ? <Summary /> : null}

        {isDeepDiveVisible ? <DeepDive /> : null}
      </div>

      {isPersonaModalOpen ? (
        <PersonaDetailsModal
          isModalOpen={true}
          closeModal={() => setIsPersonaModalOpen(false)}
        />
      ) : null}
    </>
  );
}
