import { useState } from "react";

import { DustbinIcon } from "../assets/icons";

export default function CommonCard({
  file,
  fileCategory,
  handleChange,
  handleDelete,
}) {
  const [isDeleteButtonVisible, setIsDeleteButtonVisible] = useState(false);
  const [isStimuliNameEditable, setIsStimuliNameEditable] = useState(false);

  return (
    <div
      onMouseOver={() => setIsDeleteButtonVisible(true)}
      onMouseLeave={() => setIsDeleteButtonVisible(false)}
    >
      <div className="relative rounded-lg bg-[#EDEFF4] hover:shadow-sm hover:shadow-[#232323]">
        {isDeleteButtonVisible ? (
          <button
            onClick={() => handleDelete(file.image_hash, fileCategory)}
            className="w-8 h-8 absolute top-2 right-2 flex justify-center items-center rounded-sm bg-[#E42929]"
          >
            <DustbinIcon fill="#ffffff" />
          </button>
        ) : null}

        <img
          src={file.public_url}
          alt="Kent"
          draggable={false}
          className="w-full h-56 object-contain rounded-lg"
        />
      </div>

      <input
        value={file.name}
        readOnly={!isStimuliNameEditable}
        onChange={(e) =>
          handleChange(file.image_hash, fileCategory, e.target.value)
        }
        onBlur={() => setIsStimuliNameEditable(false)}
        onDoubleClick={() => setIsStimuliNameEditable(true)}
        className="w-full h-11 mt-2 ps-3 pe-10 text-sm text-center font-medium rounded outline-none select-none text-[#536787] bg-[#F7F8FA] border hover:border-black focus:outline focus:outline-2 focus:outline-[#F1EAF6] focus:border-black focus:bg-[#FFFFFF]"
      />
    </div>
  );
}
