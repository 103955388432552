import classNames from "classnames";

import MalePersonaImage from "../../assets/png/male-persona.png";

export default function Persona({ persona, isSelected, choosePersona }) {
  return (
    <div
      className="flex gap-3 hover:cursor-pointer"
      onClick={() => choosePersona(persona)}
    >
      <div
        className={classNames(
          "w-[55px] h-[55px] relative rounded-full bg-[#F7F8FA]",
          {
            "outline outline-2 outline-offset-2 outline-[#7A3DAA]": isSelected,
          }
        )}
      >
        <img
          src={persona.avatar_url || MalePersonaImage}
          alt={`${persona.persona_name} persona`}
          className="w-full h-full object-contain rounded-full bg-[#F7F8FA]"
        />

        <span className="w-3 h-3 absolute bottom-0 right-0 border-2 rounded-full border-white bg-[#33B379]"></span>
      </div>

      <div className="self-center">
        <span
          className={classNames(
            "block text-xs",
            "font-semibold text-[#7A3DAA]"
          )}
        >
          {persona.persona_name}
        </span>
        {persona?.consumer_segment_name ? (
          <span className={classNames("block text-xs text-[#536787]")}>
            ({persona?.consumer_segment_name})
          </span>
        ) : null}
      </div>
    </div>
  );
}
