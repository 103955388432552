import classNames from "classnames";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useProject } from "../../../contexts/new-project.context";
import { useError } from "../../../contexts/error.context";

import { httpRequest } from "../../../utils/http.util";

import {
  CheckIcon,
  CLoseIcon,
  StimuliDetailsDesignElementsIcon,
  StumiliDetailsLogoIcon,
  StumiliDetailsPackagingIcon,
} from "../../../assets/icons";

export default function StimuliProcessingModal() {
  const navigate = useNavigate();

  const {
    projectId,
    setIsProcessingModalOpen,
    setIsStimuliProcessed,
    setIsStimuliProcessing,
  } = useProject();
  const { handleAPIError } = useError();

  const [stimuliProcessingStatus, setStimuliProcessingStatus] = useState(null);

  useEffect(() => {
    const getStimuliProcessingStatus = async () => {
      try {
        const { data } = await httpRequest({
          method: "GET",
          url: `/projects/${projectId}/processing_status`,
          isAuthRequired: true,
        });

        setStimuliProcessingStatus(() => {
          let finalProcessedResult = { ...data };

          if (data.logo && Object.keys(data.logo).length) {
            let processStatus = "pending";
            const logoStatus = data.logo;
            const { percent_complete } = logoStatus;

            if (percent_complete === 0) processStatus = "pending";
            if (percent_complete >= 100) processStatus = "completed";
            if (percent_complete > 0 && percent_complete < 100)
              processStatus = "processing";

            finalProcessedResult.logo = {
              ...finalProcessedResult.logo,
              processStatus,
              label: "Logo",
              item: "logo",
            };
          }

          if (
            data.packaging_design &&
            Object.keys(data.packaging_design).length
          ) {
            let processStatus = "pending";
            const packagingStatus = data.packaging_design;
            const { percent_complete } = packagingStatus;

            if (percent_complete === 0) processStatus = "pending";
            if (percent_complete >= 100) processStatus = "completed";
            if (percent_complete > 0 && percent_complete < 100)
              processStatus = "processing";

            finalProcessedResult.packaging_design = {
              ...finalProcessedResult.packaging_design,
              processStatus,
              label: "Packaging",
              item: "packaging_design",
            };
          }

          if (data.design_element && Object.keys(data.design_element).length) {
            let processStatus = "pending";
            const designElementStatus = data.design_element;
            const { percent_complete } = designElementStatus;

            if (percent_complete === 0) processStatus = "pending";
            if (percent_complete >= 100) processStatus = "completed";
            if (percent_complete > 0 && percent_complete < 100)
              processStatus = "processing";

            finalProcessedResult.design_element = {
              ...finalProcessedResult.design_element,
              processStatus,
              label: "Concept",
              item: "design_element",
            };
          }

          return finalProcessedResult;
        });

        if (data.percent_complete >= 100) {
          clearInterval(intervalId);
          setIsProcessingModalOpen(false);
          setIsStimuliProcessed(true);
          setIsStimuliProcessing(false);
          navigate(`/project-stimuli/${projectId}/insights`);
        }
      } catch (error) {
        clearInterval(intervalId);
        handleAPIError(error);
      }
    };

    getStimuliProcessingStatus();

    const intervalId = setInterval(getStimuliProcessingStatus, 1000 * 2);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line
  }, []);

  const logoData = stimuliProcessingStatus?.logo;
  const packagingData = stimuliProcessingStatus?.packaging_design;
  const designElementData = stimuliProcessingStatus?.design_element;

  return (
    <div className="absolute inset-0 z-50 bg-black bg-opacity-75">
      <div className="w-full h-full flex justify-center items-center">
        <div className="w-[520px] relative rounded-xl bg-white">
          <div className="relative">
            <img
              src="/png/processing-bg.png"
              alt="Stimuli input processing modal"
              className="w-full h-full absolute inset-0 opacity-30 z-10 rounded-t-xl"
            />

            <div className="absolute w-full h-1/2 bottom-0 z-20 bg-gradient-to-t from-white to-white opacity-10" />
            <div className="absolute w-full h-1/3 bottom-0 z-20 bg-gradient-to-t from-white to-white opacity-20" />
            <div className="absolute w-full h-1/4 bottom-0 z-20 bg-gradient-to-t from-white to-white opacity-30" />
            <div className="absolute w-full h-1/5 bottom-0 z-20 bg-gradient-to-t from-white to-white opacity-40" />
            <div className="absolute w-full h-1/6 bottom-0 z-20 bg-gradient-to-t from-white to-white opacity-50" />

            <button
              onClick={() => setIsProcessingModalOpen(false)}
              className="w-8 h-8 absolute top-4 right-4 inline-flex justify-center items-center rounded-full border z-30 border-[#E7EBF1] bg-white"
            >
              <CLoseIcon stroke="#536787" />
            </button>

            <div className="relative w-full h-40 flex z-20 justify-center items-start">
              <div className="w-28 h-28 p-2 mt-5 relative rounded-full bg-white">
                <div className="absolute inset-1 border-4 border-[#FEEAFD] border-t-4 border-t-[#7A3DAA] border-b-4 border-b-[#7A3DAA] border-l-4 border-l-[#7A3DAA] rounded-full animate-spin" />
                <div className="relative w-full h-full p-1 rounded-full bg-white">
                  <div className="w-full h-full">
                    <div
                      className={classNames(
                        "w-full h-full flex justify-center items-center rounded-full",
                        {
                          "bg-[#F9F0FD]":
                            logoData?.processStatus === "processing",
                          "bg-[#F5F7EC]":
                            packagingData?.processStatus === "processing",
                          "bg-[#EDF4FD]":
                            designElementData?.processStatus === "processing",
                        }
                      )}
                    >
                      {logoData?.processStatus === "processing" ? (
                        <StumiliDetailsLogoIcon width={40} height={40} />
                      ) : null}

                      {packagingData?.processStatus === "processing" ? (
                        <StumiliDetailsPackagingIcon width={40} height={40} />
                      ) : null}

                      {designElementData?.processStatus === "processing" ? (
                        <StimuliDetailsDesignElementsIcon
                          width={40}
                          height={40}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="px-4 pt-1 pb-4 flex flex-col justify-center items-center">
            <p className="mb-1 text-2xl font-semibold text-[#060606]">
              Hold on tight! Generating insights..
            </p>

            <p className="mb-6 text-sm text-[#536787]">Just a few minutes</p>

            <div className="w-full p-[10px] mb-2 text-center rounded-lg space-x-2 bg-[#F1EAF6]">
              <span className="text-sm font-medium text-[#7A3DAA]">
                Analysing your designs •{" "}
                {stimuliProcessingStatus?.percent_complete}%
              </span>
            </div>

            <div className="w-full space-y-2">
              {logoData ? (
                <div className="p-4 flex justify-between items-center border rounded-lg border-[#E7EBF1]">
                  <div className="flex items-center gap-x-2">
                    <div className="w-6 h-6 rounded-full flex justify-center items-center bg-[#F9F0FD]">
                      <StumiliDetailsLogoIcon width={14} height={14} />
                    </div>

                    <p
                      className={classNames("text-sm", {
                        "font-semibold text-[#060606]":
                          logoData.processStatus === "processing" ||
                          logoData.processStatus === "completed",
                        "text-[#536787]": logoData.processStatus === "pending",
                      })}
                    >
                      {logoData.label}
                    </p>
                  </div>
                  <div>
                    {logoData.processStatus === "completed" ? (
                      <span
                        title={`${logoData.percent_complete}%`}
                        className="w-5 h-5 inline-flex justify-center items-center rounded-full bg-[#3F9545]"
                      >
                        <CheckIcon fill="#ffffff" />
                      </span>
                    ) : null}

                    {logoData.processStatus === "processing" ? (
                      <p
                        title={`${logoData.percent_complete}%`}
                        className="cursor-default text-xs font-medium flex items-center gap-x-2 text-[#3F9545]"
                      >
                        <span>
                          Processing Images • {logoData?.total_num_images} of{" "}
                          {logoData?.total_num_images}
                        </span>
                        <span
                          style={{
                            background: `radial-gradient(closest-side, transparent 80% 100%), conic-gradient(#3F9545 ${logoData.percent_complete}%, white 0)`,
                          }}
                          className="w-5 h-5 inline-block border-2 rounded-full border-[#3F9545]"
                        ></span>
                      </p>
                    ) : null}

                    {logoData.processStatus === "pending" ? (
                      <p className="text-xs font-medium text-[#DEA028]">
                        Next in queue
                      </p>
                    ) : null}
                  </div>
                </div>
              ) : null}

              {packagingData ? (
                <div className="p-4 flex justify-between items-center border rounded-lg border-[#E7EBF1]">
                  <div className="flex items-center gap-x-2">
                    <div className="w-6 h-6 rounded-full flex justify-center items-center bg-[#F5F7EC]">
                      <StumiliDetailsPackagingIcon width={14} height={14} />
                    </div>

                    <p
                      className={classNames("text-sm", {
                        "font-semibold text-[#060606]":
                          packagingData.processStatus === "processing" ||
                          packagingData.processStatus === "completed",
                        "text-[#536787]":
                          packagingData.processStatus === "pending",
                      })}
                    >
                      {packagingData.label}
                    </p>
                  </div>
                  <div>
                    {packagingData.processStatus === "completed" ? (
                      <span
                        title={`${packagingData.percent_complete}%`}
                        className="w-5 h-5 inline-flex justify-center items-center rounded-full bg-[#3F9545]"
                      >
                        <CheckIcon fill="#ffffff" />
                      </span>
                    ) : null}

                    {packagingData.processStatus === "processing" ? (
                      <p
                        title={`${packagingData.percent_complete}%`}
                        className="cursor-default text-xs font-medium flex items-center gap-x-2 text-[#3F9545]"
                      >
                        <span>
                          Processing Images • {packagingData?.total_num_images}{" "}
                          of {packagingData?.total_num_images}
                        </span>
                        <span
                          style={{
                            background: `radial-gradient(closest-side, transparent 80% 100%), conic-gradient(#3F9545 ${packagingData.percent_complete}%, white 0)`,
                          }}
                          className="w-5 h-5 inline-block border-2 rounded-full border-[#3F9545]"
                        ></span>
                      </p>
                    ) : null}

                    {packagingData.processStatus === "pending" ? (
                      <p className="text-xs font-medium text-[#DEA028]">
                        Next in queue
                      </p>
                    ) : null}
                  </div>
                </div>
              ) : null}

              {designElementData ? (
                <div className="p-4 flex justify-between items-center border rounded-lg border-[#E7EBF1]">
                  <div className="flex items-center gap-x-2">
                    <div className="w-6 h-6 rounded-full flex justify-center items-center bg-[#EDF4FD]">
                      <StimuliDetailsDesignElementsIcon
                        width={14}
                        height={14}
                      />
                    </div>

                    <p
                      className={classNames("text-sm", {
                        "font-semibold text-[#060606]":
                          designElementData.processStatus === "processing" ||
                          designElementData.processStatus === "completed",
                        "text-[#536787]":
                          designElementData.processStatus === "pending",
                      })}
                    >
                      {designElementData.label}
                    </p>
                  </div>
                  <div>
                    {designElementData.processStatus === "completed" ? (
                      <span
                        title={`${designElementData.percent_complete}%`}
                        className="w-5 h-5 inline-flex justify-center items-center rounded-full bg-[#3F9545]"
                      >
                        <CheckIcon fill="#ffffff" />
                      </span>
                    ) : null}

                    {designElementData.processStatus === "processing" ? (
                      <p
                        title={`${designElementData.percent_complete}%`}
                        className="cursor-default text-xs font-medium flex items-center gap-x-2 text-[#3F9545]"
                      >
                        <span>
                          Processing Images •{" "}
                          {designElementData?.total_num_images} of{" "}
                          {designElementData?.total_num_images}
                        </span>
                        <span
                          style={{
                            background: `radial-gradient(closest-side, transparent 80% 100%), conic-gradient(#3F9545 ${designElementData.percent_complete}%, white 0)`,
                          }}
                          className="w-5 h-5 inline-block border-2 rounded-full border-[#3F9545]"
                        ></span>
                      </p>
                    ) : null}

                    {designElementData.processStatus === "pending" ? (
                      <p className="text-xs font-medium text-[#DEA028]">
                        Next in queue
                      </p>
                    ) : null}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
