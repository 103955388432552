import classNames from "classnames";

import { CheckIcon } from "../assets/icons";

export default function StepperCard({
  isActive = false,
  isComplete = false,
  step,
  label,
  setStep,
}) {
  const containerClasses = classNames("ps-2 pe-4 py-2 rounded-full space-x-2", {
    "bg-[#F8F4FA]": isActive,
    "border border-[#E7EBF1]": !isActive,
  });

  const iconClasses = classNames(
    "w-5 h-5 inline-flex justify-center items-center font-medium text-xs rounded-full text-white",
    {
      "bg-[#7A3DAA]": isActive,
      "bg-[#F1EAF6]": isComplete,
      "bg-[#E7EBF1] text-[#536787]": !isActive && !isComplete,
    }
  );

  const labelClasses = classNames("text-sm font-semibold", {
    "text-[#7A3DAA]": isActive || isComplete,
    "text-[#536787] font-normal": !isActive && !isComplete,
  });

  const handleStepClick = () => {
    if (step === 1) setStep(1);
  };

  return (
    <div className={containerClasses} onClick={handleStepClick}>
      <span className={iconClasses}>
        {isComplete ? <CheckIcon fill="#7A3DAA" /> : step}
      </span>
      <span className={labelClasses}>{label}</span>
    </div>
  );
}
