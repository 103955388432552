import { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import Button from "../components/NewButton";
import StepperCard from "../components/StepperCard";

import { useAuth } from "../contexts/auth.context";
import { useError } from "../contexts/error.context";
import { useProject } from "../contexts/new-project.context";

import { httpRequest } from "../utils/http.util";
import { getItemFromStore } from "../utils/storage.util";

import { CLoseIcon, NewUploadIcon, PdfIcon } from "../assets/icons";

export default function ProjectCreate() {
  const [searchParams] = useSearchParams();

  const runType = searchParams.get("run_type");
  const runTypeFromLocalStore = getItemFromStore("run_type");

  const navigate = useNavigate();
  const brandGuidelineFileRef = useRef(null);

  const [projectDetails, setProjectDetails] = useState({
    projectName: "",
    projectDescription: "",
    brandGuidelines: "",
    brandGuidelineFile: null,
    selectedPersonas: [],
  });

  const { user } = useAuth();
  const { handleAPIError } = useError();
  const {
    setProjectId,
    setProjectName,
    setProjectDescription,
    setBrandGuidelines,
    setBrandGuidelineFile,
    setLogoFiles,
    setPackagingFiles,
    setDesignElementsFiles,
    setIsStimuliFilesValidating,
    setStimuliValidationErrors,
    setIsShowValidationInput,
    setIsSelectBrandModalOpen,
    setIsProcessingModalOpen,
    setCurrentPersona,
    setCurrentStimuli,
    setIsUpdatingPersonas,
    setIsSummaryVisible,
    setIsDeepDiveVisible,
    setCurrentSelectedStimuli,
    setIsStimuliProcessing,
    setIsStimuliProcessed,
    setIsStimuliUpdating,
    setPersonas,
    setDraftedPersonas,
    setChatStimuliVariants,
    setChatStimuliCategory,
    setBrand,
    setSelectedPersonas,
  } = useProject();

  const handleInputChanges = (e) => {
    const { name, value, files } = e.target;
    setProjectDetails((prev) => {
      if (name === "brandGuidelineFile") {
        return { ...prev, [name]: files?.[0] };
      }
      return { ...prev, [name]: value };
    });
  };

  const createProjectAndSaveName = async () => {
    try {
      const createdProjectId = await createProjectApi();
      await setProjectNameApi(createdProjectId, projectDetails.projectName);
      await updateProjectRunTypeApi(createdProjectId);
      setProjectId(createdProjectId);
      setProjectName(projectDetails.projectName);
      setProjectDescription(projectDetails.projectDescription);
      setBrandGuidelines(projectDetails.brandGuidelines);
      setBrandGuidelineFile(projectDetails.brandGuidelineFile);
      navigate(`/${createdProjectId}/upload-stimuli`);
    } catch (error) {
      handleAPIError(error);
    }
  };

  async function createProjectApi() {
    const response = await httpRequest({
      method: "POST",
      url: "/projects/",
      isAuthRequired: true,
    });

    return response.data;
  }

  async function updateProjectRunTypeApi(projectId) {
    await httpRequest({
      url: `/projects/${projectId}/run_type`,
      isAuthRequired: true,
      method: "PUT",
      data: JSON.stringify(runType || runTypeFromLocalStore || "full"),
      headers: { "Content-Type": "application/json" },
    });
  }

  async function setProjectNameApi(projectId, projectName) {
    const response = await httpRequest({
      method: "PUT",
      url: `/projects/${projectId}/name`,
      isAuthRequired: true,
      data: JSON.stringify(projectName),
      headers: { "Content-Type": "application/json" },
    });

    return response.data;
  }

  const handleRemovePdf = () => {
    brandGuidelineFileRef.current.value = null;
    setProjectDetails((prev) => ({ ...prev, brandGuidelineFile: null }));
  };

  useEffect(() => {
    /* setLogoFiles([]);
    setPackagingFiles([]);
    setDesignElementsFiles([]);
    setIsStimuliFilesValidating(false);
    setStimuliValidationErrors([]);
    setIsShowValidationInput(false);
    setIsSelectBrandModalOpen(false);
    setIsProcessingModalOpen(false);
    setIsProcessingModalOpen(false);
    setCurrentPersona(null);
    setCurrentStimuli(null);
    setIsUpdatingPersonas(false);
    setIsSummaryVisible(true);
    setIsDeepDiveVisible(false);
    setCurrentSelectedStimuli(null);
    setIsStimuliProcessing(false);
    setIsStimuliProcessed(false);
    setIsStimuliUpdating(false);
    setSelectedPersonas([]); */

    setIsStimuliProcessing(false);
    setProjectId("");
    setProjectName("");
    setProjectDescription("");
    setBrandGuidelines("");
    setBrandGuidelineFile(null);
    setPersonas([]);
    setDraftedPersonas([]);
    setSelectedPersonas(["ai_expert"]);
    setLogoFiles([]);
    setPackagingFiles([]);
    setDesignElementsFiles([]);
    setIsStimuliFilesValidating(false);
    setStimuliValidationErrors([]);
    setIsShowValidationInput(false);
    setIsSelectBrandModalOpen(false);
    setBrand("");
    setIsProcessingModalOpen(false);
    setCurrentPersona(null);
    setCurrentStimuli(null);
    setIsUpdatingPersonas(false);
    setIsSummaryVisible(true);
    setIsDeepDiveVisible(false);
    setCurrentSelectedStimuli(null);
    setIsStimuliProcessed(false);
    setIsStimuliUpdating(false);
    setChatStimuliCategory("packaging_design");
    setChatStimuliVariants([]);

    // eslint-disable-next-line
  }, []);

  return (
    <div className="flex-auto overflow-auto flex flex-col">
      <div className="hidden-scroll mx-auto flex-auto w-full lg:w-[812px] overflow-auto py-8 space-y-8 px-3">
        <div className="space-y-4">
          <div className="text-[32px]">
            <span className="inline-flex items-center gap-x-2 font-semibold text-[#7A3DAA]">
              Hello, {user?.displayName}
              <img
                src="/png/Waving Hand.png"
                alt="Waving hand icon"
                className="w-8 h-8"
              />
            </span>

            <br />

            <span className="font-normal text-[#99A8C0]">
              let's set up your new project
            </span>
          </div>

          <div className="flex items-center gap-x-1">
            <StepperCard
              isActive={true}
              step={1}
              label="Project Name"
              setStep={() => {}}
            />
            <div className="w-10 border-[1.5px] border-dashed border-[#E7EBF1]" />
            <StepperCard step={2} label="Add your designs" setStep={() => {}} />
            <div className="w-10 border-[1.5px] border-dashed border-[#E7EBF1]" />
            <StepperCard
              step={3}
              label="Select AI Personas"
              setStep={() => {}}
            />
          </div>
        </div>

        <div className="space-y-8">
          <div>
            <p className="mb-2 font-medium">Project Name</p>

            <input
              value={projectDetails.projectName}
              name="projectName"
              onChange={handleInputChanges}
              placeholder="Enter project name here"
              className="w-full h-16 px-4 py-[10px] border-[1.5px] outline-none rounded-lg border-[#E7EBF1] bg-[#F7F8FA] focus:border-[#7A3DAA] focus:outline focus:outline-[#F1EAF6] hover:border-[#7A3DAA] hover:outline hover:outline-[#F1EAF6]"
            />
          </div>

          <div>
            <p className="mb-1 font-medium">Describe the project</p>
            <p className="mb-2 text-sm text-[#536787]">
              Tell us more about the brief, the intent behind the project, what
              matters most and other relevant info.
            </p>

            <textarea
              value={projectDetails.projectDescription}
              name="projectDescription"
              onChange={handleInputChanges}
              placeholder="Enter your brief here"
              className="w-full h-24 px-4 py-[10px] border-[1.5px] outline-none rounded-lg border-[#E7EBF1] bg-[#F7F8FA] focus:border-[#7A3DAA] focus:outline focus:outline-[#F1EAF6] hover:border-[#7A3DAA] hover:outline hover:outline-[#F1EAF6]"
            />
          </div>

          <div>
            <div className="mb-2 flex justify-between">
              <div>
                <p className="mb-1 font-medium">Brand Guidelines</p>
                <p className="text-sm text-[#536787]">
                  Any other relevant information about the brand that you'd like
                  us to know
                </p>
              </div>
              <div>
                <input
                  ref={brandGuidelineFileRef}
                  type="file"
                  id="brandGuidelineFile"
                  className="hidden"
                  name="brandGuidelineFile"
                  onChange={handleInputChanges}
                  accept=".pdf"
                />
                <label
                  htmlFor="brandGuidelineFile"
                  className="px-3 py-2 flex items-center gap-x-2 font-medium text-sm border rounded-lg cursor-pointer border-[#E7EBF1] hover:bg-[#DFE4EC]"
                >
                  <NewUploadIcon />
                  <span>Upload</span>
                </label>
              </div>
            </div>

            {projectDetails.brandGuidelineFile ? (
              <div>
                <div className="p-4 mb-3 border rounded-lg flex justify-between items-center border-[#E7EBF1]">
                  <div className="flex items-center gap-x-4">
                    <div>
                      <PdfIcon />
                    </div>
                    <div className="space-y-1">
                      <p className="text-sm font-medium text-[#151515]">
                        {projectDetails.brandGuidelineFile.name}
                      </p>
                      <p className="flex items-center gap-x-2 text-xs text-[#536787]">
                        <span>
                          {Number.parseInt(
                            projectDetails.brandGuidelineFile.size / 1024
                          ).toFixed(1)}{" "}
                          MB
                        </span>
                      </p>
                    </div>
                  </div>
                  <button
                    onClick={handleRemovePdf}
                    className="w-5 h-5 rounded-full flex justify-center items-center bg-[#A9B3C3]"
                  >
                    <CLoseIcon stroke="#ffffff" />
                  </button>
                </div>
              </div>
            ) : null}

            <textarea
              value={projectDetails.brandGuidelines}
              name="brandGuidelines"
              onChange={handleInputChanges}
              placeholder="Enter your text here"
              className="w-full h-24 px-4 py-[10px] border-[1.5px] outline-none rounded-lg border-[#E7EBF1] bg-[#F7F8FA] focus:border-[#7A3DAA] focus:outline focus:outline-[#F1EAF6] hover:border-[#7A3DAA] hover:outline hover:outline-[#F1EAF6]"
            />
          </div>
        </div>
      </div>
      <div className="py-3 mx-auto w-full lg:w-[812px] flex-none text-right px-3">
        <Button
          title="Next"
          variant={projectDetails.projectName ? "primary" : "disabled"}
          type="submit"
          disabled={!projectDetails.projectName}
          onClick={createProjectAndSaveName}
        />
      </div>
    </div>
  );
}
