import StimuliInsights from "../components/project-stimuli/StimuliInsights";

import { useProject } from "../contexts/new-project.context";

export default function StimuliInsightsPage() {
  const { isStimuliProcessed, isStimuliUpdating } = useProject();

  return (
    <div className="flex-auto overflow-auto">
      {isStimuliProcessed && !isStimuliUpdating ? <StimuliInsights /> : null}
    </div>
  );
}
