import { useNavigate } from "react-router-dom";
import { createContext, useContext } from "react";

import { useAuth } from "./auth.context";

import { showErrorToast } from "../utils/error.util";

export const ErrorContext = createContext(null);

export function ErrorContextProvider({ children }) {
  const navigate = useNavigate();
  const { logOut } = useAuth();

  function handleAPIError(error) {
    console.log(error);

    if (error.response) {
      if (error.response.status === 401) {
        logOut();
        navigate("/login", { replace: true });
      } else {
        showErrorToast(
          error.response?.data?.detail ||
          error.response?.data?.message ||
          error.response?.data?.detail?.[0]?.msg ||
          "Something went wrong"
        );
      }
    } else {
      showErrorToast(error.message || "Something went wrong");
    }
  }

  return (
    <ErrorContext.Provider value={{ handleAPIError }}>
      {children}
    </ErrorContext.Provider>
  );
}

export const useError = () => useContext(ErrorContext);
