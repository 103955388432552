import classNames from "classnames";

export default function PersonaIdeasSkeletonLoader() {
  return (
    <div
      className={classNames(
        "grid max-md:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 relative before:absolute before:z-20 before:inset-0 before:-translate-x-full before:animate-[shimmer_2s_infinite] before:bg-gradient-to-r before:from-transparent before:via-white before:to-transparent isolate overflow-hidden"
      )}
    >
      {new Array(9).fill(1).map((_, index) => (
        <div key={index} className="p-3 border border-[#E7EBF1] rounded-[10px]">
          <div className="flex space-x-2">
            <div className="rounded-[5px] bg-[#EADDF7] w-6 h-9" />
            <div className="flex-1 space-y-2">
              <div className="w-3/4 h-3 bg-[#F1EAF6] rounded-[40px]" />
              {new Array(5).fill(1).map((_, index) => (
                <div key={index} className="h-2 bg-[#F1EAF6] rounded-[40px]" />
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export function PersonaSingleIdeaSkeletonLoader() {
  return (
    <div
      className={classNames(
        "relative before:absolute before:z-20 before:inset-0 before:-translate-x-full before:animate-[shimmer_2s_infinite] before:bg-gradient-to-r before:from-transparent before:via-white before:to-transparent isolate overflow-hidden"
      )}
    >
      <div className="p-3 border border-[#E7EBF1] rounded-[10px]">
        <div className="flex space-x-2">
          <div className="rounded-[5px] bg-[#EADDF7] w-6 h-9" />
          <div className="flex-1 space-y-2">
            <div className="w-3/4 h-3 bg-[#F1EAF6] rounded-[40px]" />
            {new Array(5).fill(1).map((_, index) => (
              <div key={index} className="h-2 bg-[#F1EAF6] rounded-[40px]" />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
