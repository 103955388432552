import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

import Dropdown from "./Dropdown";
import ProfileMenu from "./ProfileMenu";

import logo from "../assets/png/logo.png";

import { NewProjectIcon } from "../assets/icons";

import { useError } from "../contexts/error.context";

import { httpRequest } from "../utils/http.util";
import { getItemFromStore } from "../utils/storage.util";

export default function Header() {
  const [projects, setProjects] = useState([]);
  const [isProjectsLoading, setIsProjectsLoading] = useState(true);

  const { handleAPIError } = useError();

  useEffect(() => {
    httpRequest({ url: "/projects/", isAuthRequired: true })
      .then((response) => {
        setIsProjectsLoading(false);
        setProjects(response.data.filter((item) => item.name));
      })
      .catch(handleAPIError)
      .finally(() => {
        setIsProjectsLoading(false);
      });
  }, [handleAPIError]);

  const runType = getItemFromStore("run_type");

  return (
    <header className="flex-none pe-8 py-4 flex items-center border-b-4 border-b-[#E7EBF1]">
      <div className="flex items-center gap-x-4 flex-1">
        <Link
          to={runType ? `/?run_type=${runType}` : "/"}
          className="w-40 h-16 block"
        >
          <img
            src={logo}
            alt="Logo of the Lens app"
            className="w-full h-full object-contain"
          />
        </Link>

        <Dropdown
          options={projects}
          LeftIcon={NewProjectIcon}
          isProjectsLoading={isProjectsLoading}
        />
      </div>

      <ProfileMenu />
    </header>
  );
}
