import { useNavigate } from "react-router-dom";
import { useContext, createContext, useEffect, useState } from "react";
import {
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
  onAuthStateChanged,
  OAuthProvider,
  onIdTokenChanged,
} from "firebase/auth";

import { auth } from "../configs/firebase.config";

import { showErrorToast } from "../utils/error.util";

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const navigate = useNavigate();

  const [user, setUser] = useState(null);

  const googleSignIn = async () => {
    const provider = new GoogleAuthProvider();

    try {
      await signInWithPopup(auth, provider);
    } catch (error) {
      let errorMessage = "Something went wrong while login";

      const stringWithJson = error?.message;
      const startIndex = stringWithJson.indexOf("{");
      const endIndex = stringWithJson.lastIndexOf("}");

      if (startIndex === -1 || endIndex === -1 || endIndex <= startIndex) {
        errorMessage = "Something went wrong while login";
      } else {
        const jsonString = stringWithJson.substring(startIndex, endIndex + 1);

        try {
          const jsonObject = JSON.parse(jsonString);
          errorMessage =
            jsonObject?.error?.message || "Something went wrong while login";
        } catch (error) {
          errorMessage = "Something went wrong while login";
        }
      }

      showErrorToast(errorMessage);
    }
  };

  const microsoftSignIn = async () => {
    const provider = new OAuthProvider("microsoft.com");

    try {
      await signInWithPopup(auth, provider);
    } catch (error) {
      let errorMessage = "Something went wrong while login";

      showErrorToast(errorMessage);
    }
  };

  const logOut = async () => {
    try {
      await signOut(auth);
      navigate("/login", { replace: true });
    } catch (error) {
      let errorMessage = "Something went wrong while login";

      showErrorToast(errorMessage);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    const unsubscribeToken = onIdTokenChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    return () => {
      unsubscribe();
      unsubscribeToken();
    };
  }, []);

  return (
    <AuthContext.Provider
      value={{ googleSignIn, microsoftSignIn, logOut, user }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
