import { useEffect } from "react";
import { Outlet } from "react-router-dom";

import Header from "../components/NewHeader";

import { useProject } from "../contexts/new-project.context";
/* import { useError } from "../contexts/error.context";

import { httpRequest } from "../utils/http.util"; */

export default function GlobalLayout() {
  const { projectId/* , setPersonas */, getDraftedPersonas } = useProject();
  /* const { handleAPIError } = useError(); */

  /* useEffect(() => {
    httpRequest({
      url: `/global/personas`,
      isAuthRequired: true,
    })
      .then((response) => {
        const personas = Object.entries(response.data).map(
          ([personaRole, personaData], index) => ({
            id: index,
            role: personaRole,
            name: personaData.name,
            gender: personaData.gender,
            persona_desc: personaData.persona_desc,
          })
        );
        setPersonas(personas);
      })
      .catch(handleAPIError);
    // eslint-disable-next-line
  }, []); */

  useEffect(() => {
    getDraftedPersonas();
    // eslint-disable-next-line
  }, [projectId]);

  return (
    <div className="h-screen flex flex-col overflow-auto">
      <Header />
      <Outlet />
    </div>
  );
}
