import classNames from "classnames";
import { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";

import Button from "../components/NewButton";
import PersonaProfileCard from "../components/persona/PersonaProfileCard";
import PersonaDemographicDetails from "../components/persona/PersonaDemographicDetails";
import DeletePersonaModal from "../components/modals/Delete-Persona-Modal";

import { PersonaSingleIdeaSkeletonLoader } from "../components/skeletons/Persona-Ideas-Skeleton-Loader";
import GeneratePersonaSkeletonLoader, {
  DemographicsSkeletonLoader,
  ProfileSkeletonLoader,
  SummarySkeletonLoader,
} from "../components/skeletons/GeneratePersonaSkeletonLoader";

import { useProject } from "../contexts/new-project.context";
import { useError } from "../contexts/error.context";

import { auth } from "../configs/firebase.config";

import { httpRequest } from "../utils/http.util";

export default function DraftedPersona() {
  const navigate = useNavigate();
  const params = useParams();

  const personaId = params?.personaId;

  const { projectId, getDraftedPersonas, getSavedPersonas } = useProject();
  const { handleAPIError } = useError();

  const [isLoading, setIsLoading] = useState(true);
  const [personaDescribe, setPersonaDescribe] = useState("");

  const [personaIdeas, setPersonaIdeas] = useState([]);
  const [isLoadingPersonaIdeans, setIsLoadingPersonaIdeas] = useState(true);
  const [isSuggestedPersonasVisible, setIsSuggestedPersonasVisible] =
    useState(false);

  const [isNewPersonaGenerated, setIsNewPersonaGenerated] = useState(false);
  const [generatedPersonaDetails, setGeneratedPersonaDetails] = useState(null);
  const [isGeneratingPersona, setIsGeneratingPersona] = useState(false);

  const [isDeletePersonaModalOpen, setIsDeletePersonaModalOpen] =
    useState(false);

  const [isDemographicsGenerating, setIsDemographicsGenerating] =
    useState(false);
  const [isSummaryGenerating, setIsSummaryGenerating] = useState(false);
  const [isProfileGenerating, setIsProfileGenerating] = useState(false);

  const initialLoaders = () => {
    setIsDemographicsGenerating(true);
    setIsSummaryGenerating(true);
    setIsProfileGenerating(true);
  };

  const changeProfileData = (profileData) => {
    const icon = profileData.section.slice(0, profileData.section.indexOf(" "));
    const section = profileData.section
      .substr(profileData.section.indexOf(" "))
      .trim();

    return { icon, section };
  };

  const handleGeneratePersona = async () => {
    try {
      let generatedPersonaId;

      initialLoaders();
      setGeneratedPersonaDetails(null);
      setIsSuggestedPersonasVisible(false);
      setIsGeneratingPersona(true);
      setIsNewPersonaGenerated(false);

      if (generatedPersonaDetails) {
        httpRequest({
          method: "DELETE",
          url: `/projects/${projectId}/personas/${generatedPersonaDetails.persona_id}`,
          isAuthRequired: true,
        });
      }

      const formData = new FormData();
      formData.append("data_text", personaDescribe);

      if (generatedPersonaDetails) {
        formData.append("old_persona_id", generatedPersonaDetails?.persona_id);
      }

      const accessToken = await auth.currentUser.getIdToken();

      const response = await fetch(
        `https://kntr-chat-3m2lt2iata-el.a.run.app/v1/projects/${projectId}/personas/generate/stream`,
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const reader = response.body.getReader();

      while (true) {
        const { done, value } = await reader.read();

        if (done) break;

        const decodedText = new TextDecoder().decode(value);
        let parsedObj;

        try {
          parsedObj = JSON.parse(decodedText);
        } catch (_) {
          continue;
        }

        if (parsedObj?.persona_id) {
          setGeneratedPersonaDetails((prev) => ({
            ...prev,
            persona_id: parsedObj?.persona_id,
          }));

          generatedPersonaId = parsedObj?.persona_id;
        } else if (parsedObj?.demographics) {
          const personaDemographicDetailList = parsedObj.demographics.map(
            (item, index) => {
              return { ...item, id: index + 1 };
            }
          );

          setGeneratedPersonaDetails((prev) => ({
            ...prev,
            persona_info: {
              ...(prev?.persona_info || {}),
              demographics: personaDemographicDetailList,
            },
          }));

          setIsDemographicsGenerating(false);
        } else if (parsedObj?.section && parsedObj?.content) {
          const { icon, section } = changeProfileData(parsedObj);

          setGeneratedPersonaDetails((prev) => ({
            ...prev,
            persona_info: {
              ...prev?.persona_info,
              profile: [
                ...(prev?.persona_info?.profile || []),
                {
                  ...parsedObj,
                  icon,
                  section,
                  id: prev?.persona_info?.profile?.length + 1,
                },
              ],
            },
          }));
        } else if (parsedObj?.brief_summary) {
          setGeneratedPersonaDetails((prev) => ({
            ...prev,
            persona_info: {
              ...(prev?.persona_info || {}),
              ...parsedObj,
            },
          }));

          setIsSummaryGenerating(false);
        } else {
          setGeneratedPersonaDetails((prev) => ({
            ...prev,
            persona_info: {
              ...(prev?.persona_info || {}),
              ...parsedObj,
            },
          }));
        }
      }

      if (!generatedPersonaId) {
        return handleGeneratePersona();
      }

      setIsProfileGenerating(false);

      await getDraftedPersonas();

      setIsGeneratingPersona(false);
      setIsNewPersonaGenerated(true);

      navigate(`/${projectId}/draft-persona/${generatedPersonaId}`, {
        replace: true,
      });
    } catch (error) {
      handleAPIError(error);
    }
  };

  const handlePersonaSave = async () => {
    try {
      const personaId = generatedPersonaDetails.persona_id;
      const payload = {
        save: true,
        ...generatedPersonaDetails.persona_info,
        demographics: generatedPersonaDetails.persona_info.demographics.map(
          (item) => ({
            ...item,
            id: undefined,
          })
        ),
        profile: generatedPersonaDetails.persona_info.profile.map((item) => ({
          ...item,
          section: `${item.icon} ${item.section}`,
          icon: undefined,
          id: undefined,
        })),
      };

      await httpRequest({
        method: "PUT",
        url: `/projects/${projectId}/personas/${personaId}`,
        isAuthRequired: true,
        data: payload,
      });

      await getSavedPersonas(true);
      await getDraftedPersonas();

      navigate(`/${projectId}/select-persona`, { replace: true });
    } catch (error) {
      handleAPIError(error);
    }
  };

  const handlePersonaProfileChange = (e, id) => {
    setGeneratedPersonaDetails((prev) => {
      return {
        ...prev,
        persona_info: {
          ...prev.persona_info,
          profile: prev.persona_info.profile.map((item) =>
            item.id === id ? { ...item, content: e.target.value } : item
          ),
        },
      };
    });
  };

  const handlePersonaDeleteAndClose = async () => {
    try {
      navigate(-1, { replace: true });
    } catch (error) {
      handleAPIError(error);
    }
  };

  const changePersonaIdeaData = (ideaData) => {
    const icon = ideaData.name.slice(0, ideaData.name.indexOf("*"));
    const name = ideaData.name
      .substr(ideaData.name.indexOf("*"))
      .replace(/\*\*/g, "");
    return { icon, name, description: ideaData.description };
  };

  const getPersonaIdeas = async () => {
    httpRequest({
      url: `/projects/${projectId}/personas/ideas`,
      domain: "chat",
      isAuthRequired: true,
    })
      .then((response) => {
        setPersonaIdeas(
          response.data.map((item) => changePersonaIdeaData(item))
        );
        setIsLoadingPersonaIdeas(false);
      })
      .catch(handleAPIError);
  };

  useEffect(() => {
    if (projectId && personaId) {
      setIsGeneratingPersona(true);
      setIsLoading(true);

      httpRequest({
        url: `/projects/${projectId}/personas/${personaId}`,
        isAuthRequired: true,
      })
        .then((getPersonaDetailsResponse) => {
          setPersonaDescribe(getPersonaDetailsResponse?.data?.data_text || "");

          const personaDemographicDetailList =
            getPersonaDetailsResponse.data.demographics.map((item, index) => {
              return { ...item, id: index + 1 };
            });

          const personaProfiles = getPersonaDetailsResponse.data.profile.map(
            (item, index) => {
              const icon = item.section.slice(0, item.section.indexOf(" "));
              const section = item.section
                .substr(item.section.indexOf(" "))
                .trim();

              return { ...item, icon, section, id: index + 1 };
            }
          );

          setGeneratedPersonaDetails({
            persona_id: personaId,
            persona_info: {
              ...getPersonaDetailsResponse.data,
              demographics: personaDemographicDetailList,
              profile: personaProfiles,
            },
          });

          setIsGeneratingPersona(false);
          setIsNewPersonaGenerated(true);
          setIsLoading(false);
        })
        .catch(handleAPIError);
    }
    // eslint-disable-next-line
  }, [projectId, personaId]);

  useEffect(() => {
    const main = async () => {
      if (projectId) {
        try {
          setIsLoadingPersonaIdeas(true);

          const accessToken = await auth.currentUser.getIdToken();

          const response = await fetch(
            `https://kntr-chat-3m2lt2iata-el.a.run.app/v1/projects/${projectId}/personas/ideas/stream`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );

          const reader = response.body.getReader();

          while (true) {
            const { done, value } = await reader.read();

            if (done) break;

            const decodedText = new TextDecoder().decode(value);

            if (decodedText) {
              const objList =
                decodedText
                  ?.replaceAll?.("}{", "}[Separate]{")
                  ?.split?.("[Separate]") || [];

              let objParsedList = [];
              try {
                objParsedList = objList.map((item) => JSON.parse(item));
              } catch (_) {
                continue;
              }

              setPersonaIdeas((prev) => [
                ...prev,
                ...(objParsedList?.map?.((item) =>
                  changePersonaIdeaData(item)
                ) || []),
              ]);
            }
          }

          setIsLoadingPersonaIdeas(false);

          await getPersonaIdeas();
        } catch (error) {
          handleAPIError(error);
        }
      }
    };

    main();

    // eslint-disable-next-line
  }, [projectId]);

  if (!projectId) {
    return <Navigate to="/" replace />;
  }

  return (
    <>
      <div className="flex-auto flex flex-col">
        <div className="px-10 pt-10 flex-auto overflow-auto space-y-10">
          <div>
            <p className="text-2xl font-semibold text-[#7A3DAA]">
              Tell us about the persona
            </p>
            <p className="mt-1 font-medium text-[#99A8C0]">
              Share as much or as little as you like. You can always update or
              refine the details later.
            </p>
          </div>

          <div className="p-4 rounded-lg bg-[#F7F8FA] space-y-5">
            <textarea
              autoFocus
              value={personaDescribe}
              onChange={(e) => setPersonaDescribe(e.target.value)}
              className={classNames(
                "w-full px-3 py-2 resize-none border rounded-[10px] outline-none bg-white placeholder:text-[#536787]",
                {
                  "border-[#E7EBF1] hover:border-[#7A3DAA] focus:border-[#7A3DAA]":
                    !personaDescribe,
                  "border-[#7A3DAA]": personaDescribe,
                }
              )}
            />

            <button
              onClick={() => setIsSuggestedPersonasVisible((prev) => !prev)}
              className="flex gap-3"
            >
              <span className="text-sm font-medium">
                Persona suggestions based on your product
              </span>
              <svg
                width="10"
                height="7"
                viewBox="0 0 10 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={classNames("self-center", {
                  "rotate-180": !isSuggestedPersonasVisible,
                })}
              >
                <path
                  opacity="0.7"
                  d="M4.22936 0.814612C4.65497 0.395129 5.34503 0.395129 5.77064 0.814612L9.67861 4.6663C10.3652 5.34298 9.87892 6.5 8.90797 6.5L1.09203 6.5C0.121082 6.5 -0.365172 5.34298 0.321395 4.6663L4.22936 0.814612Z"
                  fill="black"
                />
              </svg>
            </button>

            <div
              className={classNames(
                "grid max-md:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3",
                {
                  hidden: !isSuggestedPersonasVisible,
                }
              )}
            >
              {personaIdeas.map((personaIdea, index) => (
                <div
                  key={index}
                  onClick={() =>
                    setPersonaDescribe(
                      `${personaIdea.name}: ${personaIdea.description}` || ""
                    )
                  }
                  className={classNames(
                    "p-3 flex gap-2 cursor-pointer border rounded-[10px] broder-[#E7EBF1] hover:bg-[#F1EAF6] hover:border-[#7A3DAA]",
                    {
                      "bg-[#F1EAF6] border-[#7A3DAA]": personaDescribe.includes(
                        personaIdea.description
                      ),
                    }
                  )}
                >
                  <span className="flex-none self-start">
                    {personaIdea.icon}
                  </span>
                  <div className="">
                    <p className="text-sm font-medium">
                      {personaIdea.name || "No name"}
                    </p>
                    <p className="text-sm">
                      {personaIdea.description || "No description"}
                    </p>
                  </div>
                </div>
              ))}

              {isLoadingPersonaIdeans ? (
                <PersonaSingleIdeaSkeletonLoader />
              ) : null}
            </div>
          </div>

          {isNewPersonaGenerated ? (
            <div className="text-right space-x-2">
              <Button
                onClick={handleGeneratePersona}
                disabled={!personaDescribe || isGeneratingPersona}
                variant={
                  personaDescribe && !isGeneratingPersona
                    ? "primary"
                    : "disabled"
                }
                title="Regenerate Persona"
              />
            </div>
          ) : null}

          {isLoading ? (
            <GeneratePersonaSkeletonLoader />
          ) : (
            <>
              {isGeneratingPersona || generatedPersonaDetails ? (
                <div className="space-y-11">
                  {isDemographicsGenerating ? (
                    <DemographicsSkeletonLoader />
                  ) : (
                    <>
                      <p className="flex items-center gap-x-2 text-[32px] font-semibold text-[#7A3DAA]">
                        Your Persona
                        <img
                          src="/png/Party Popper.png"
                          alt="Your persona icon"
                          className="w-8 h-8"
                        />
                      </p>

                      <PersonaDemographicDetails
                        personaId={generatedPersonaDetails?.persona_id}
                        generatedPersonaDetails={generatedPersonaDetails}
                        setGeneratedPersonaDetails={setGeneratedPersonaDetails}
                      />
                    </>
                  )}

                  {isSummaryGenerating ? (
                    <SummarySkeletonLoader />
                  ) : (
                    <div className="px-3 py-5 border rounded-lg border-[#F7E7FB]">
                      <p className="mb-3 text-xl font-semibold">
                        🚀 A brief summary about the persona
                      </p>
                      <p>
                        {generatedPersonaDetails?.persona_info?.brief_summary}
                      </p>
                    </div>
                  )}

                  <div className="grid lg:grid-cols-2 gap-x-10 gap-y-5">
                    {generatedPersonaDetails?.persona_info?.profile?.map?.(
                      (personaProfile, index) => (
                        <PersonaProfileCard
                          key={index}
                          personaProfile={personaProfile}
                          handlePersonaProfileChange={
                            handlePersonaProfileChange
                          }
                          generatedPersonaDetails={generatedPersonaDetails}
                          setGeneratedPersonaDetails={
                            setGeneratedPersonaDetails
                          }
                        />
                      )
                    )}

                    {isProfileGenerating && isGeneratingPersona ? (
                      <ProfileSkeletonLoader />
                    ) : null}
                  </div>
                </div>
              ) : null}
            </>
          )}
        </div>

        <div
          className={classNames("px-10 py-4 flex-none text-right space-x-4", {
            invisible: !isNewPersonaGenerated,
          })}
        >
          <Button
            onClick={() => setIsDeletePersonaModalOpen(true)}
            variant="plain"
            title="Delete Persona"
          />
          <Button
            title="Cancel"
            variant="secondary"
            onClick={handlePersonaDeleteAndClose}
          />
          <Button
            title="Save Persona"
            variant="primary"
            onClick={handlePersonaSave}
          />
        </div>
      </div>

      <DeletePersonaModal
        personaId={personaId}
        isDeletePersonaModalOpen={isDeletePersonaModalOpen}
        setIsDeletePersonaModalOpen={setIsDeletePersonaModalOpen}
      />
    </>
  );
}
