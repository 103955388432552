import { createContext, useContext, useState } from "react";

const GlobalContext = createContext();

export function GlobalContextProvider({ children }) {
  const [uiLoading, setUILoading] = useState(false);

  function changeUILoading(value) {
    setUILoading(value);
  }

  return (
    <GlobalContext.Provider value={{ uiLoading, changeUILoading }}>
      {children}
    </GlobalContext.Provider>
  );
}

export const useGlobalUI = () => useContext(GlobalContext);
