import { createContext, useContext, useEffect, useState } from "react";

import DataLoader from "../components/Data-Loader";

import { useAuth } from "./auth.context";
import { useError } from "./error.context";

import { httpRequest } from "../utils/http.util";

const ProjectContext = createContext();

export function ProjectContextProvider({ children }) {
  const { user } = useAuth();
  const { handleAPIError } = useError();

  const [isStimuliProcessing, setIsStimuliProcessing] = useState(false);
  const [projectId, setProjectId] = useState("");
  const [projectName, setProjectName] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const [brandGuidelines, setBrandGuidelines] = useState("");
  const [brandGuidelineFile, setBrandGuidelineFile] = useState(null);
  const [personas, setPersonas] = useState([]);

  const [draftedPersonas, setDraftedPersonas] = useState([]);

  const [selectedPersonas, setSelectedPersonas] = useState(["ai_expert"]);
  const [logoFiles, setLogoFiles] = useState([]);
  const [packagingFiles, setPackagingFiles] = useState([]);
  const [designElementsFiles, setDesignElementsFiles] = useState([]);
  const [isStimuliFilesValidating, setIsStimuliFilesValidating] =
    useState(false);
  const [stimuliValidationErrors, setStimuliValidationErrors] = useState([]);
  const [isShowValidationInput, setIsShowValidationInput] = useState(false);
  const [isSelectBrandModalOpen, setIsSelectBrandModalOpen] = useState(false);
  const [brand, setBrand] = useState("");
  const [isProcessingModalOpen, setIsProcessingModalOpen] = useState(false);
  const [currentPersona, setCurrentPersona] = useState(null);
  const [currentStimuli, setCurrentStimuli] = useState(null);
  const [isUpdatingPersonas, setIsUpdatingPersonas] = useState(false);
  const [isSummaryVisible, setIsSummaryVisible] = useState(true);
  const [isDeepDiveVisible, setIsDeepDiveVisible] = useState(false);
  const [currentSelectedStimuli, setCurrentSelectedStimuli] = useState(null);
  const [isStimuliProcessed, setIsStimuliProcessed] = useState(false);
  const [isStimuliUpdating, setIsStimuliUpdating] = useState(false);

  const [chatStimuliCategory, setChatStimuliCategory] =
    useState("packaging_design");
  const [chatStimuliVariants, setChatStimuliVariants] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [stimuliProcessingStatus, setStimuliProcessingStatus] = useState(null);

  // useEffect(() => {
  //   if (!chatStimuliCategory) {
  //     let category = "";
  //     if (logoFiles.length) category = "logo";
  //     if (packagingFiles.length) category = "packaging_design";
  //     if (designElementsFiles.length) category = "design_element";
  //     setChatStimuliCategory(category);
  //   }

  //   if (!chatStimuliVariants.length) {
  //     let variant = [];
  //     if (logoFiles.length) variant.push(logoFiles[0].image_hash);
  //     if (packagingFiles.length) variant.push(packagingFiles[0].image_hash);
  //     if (designElementsFiles.length)
  //       variant.push(designElementsFiles[0].image_hash);
  //     setChatStimuliVariants(variant);
  //   }
  // }, [logoFiles, packagingFiles, designElementsFiles]);

  const getDraftedPersonas = async () => {
    if (projectId) {
      httpRequest({
        url: `/projects/${projectId}/personas/drafts`,
        isAuthRequired: true,
      })
        .then((response) => {
          /* setDraftedPersonas(
            Object.entries(response.data).map(([key, val]) => ({
              personaId: key,
              name: val,
            }))
          ); */
          setDraftedPersonas(response.data);
        })
        .catch(handleAPIError);
    }
  };

  const getSelectedPersonas = async (poll = false) => {
    let intervalId = null;

    const main = async () => {
      try {
        const response = await httpRequest({
          url: `/projects/${projectId}/personas/selected`,
          isAuthRequired: true,
        });

        if (poll) {
          const containsPersonaWithoutAvatar = response.data.some(
            (persona) => !persona.avatar_url
          );

          if (!containsPersonaWithoutAvatar) {
            clearInterval(intervalId);
          }
        }

        const selectedList = response.data.map((persona) => persona.persona_id);

        setSelectedPersonas(selectedList);
      } catch (error) {
        handleAPIError(error);
      }
    };

    main();

    if (poll) {
      intervalId = setInterval(main, 2000);
    }
  };

  const getSavedPersonas = async (poll = false) => {
    let intervalId = null;

    const main = async () => {
      try {
        const response = await httpRequest({
          url: `/projects/${projectId}/personas/saved`,
          isAuthRequired: true,
        });

        if (poll) {
          const containsPersonaWithoutAvatar = response.data.some(
            (persona) => !persona.avatar_url
          );

          if (!containsPersonaWithoutAvatar) {
            clearInterval(intervalId);
          }
        }

        setPersonas([
          {
            persona_id: "ai_expert",
            persona_name: "AI Expert",
            name: "AI Expert",
            from_name: "AI Expert",
            avatar_url: null,
            consumer_segment_name: "",
          },
          ...response.data,
        ]);
      } catch (error) {
        handleAPIError(error);
      }
    };

    main();

    if (poll) {
      intervalId = setInterval(main, 2000);
    }
  };

  useEffect(() => {
    if (user && projectId) {
      httpRequest({
        url: `/projects/${projectId}/name`,
        isAuthRequired: true,
      })
        .then((response) => {
          setProjectName(response.data);
        })
        .catch(handleAPIError);
    }
    // eslint-disable-next-line
  }, [user, projectId]);

  useEffect(() => {
    if (projectId && user) {
      getSavedPersonas();
    }
    // eslint-disable-next-line
  }, [projectId, user]);

  useEffect(() => {
    if (projectId && user && isStimuliProcessed) {
      getSelectedPersonas();
    }
    // eslint-disable-next-line
  }, [projectId, user, isStimuliProcessed]);

  useEffect(() => {
    if (logoFiles.length) {
      setCurrentStimuli({ ...logoFiles[0], stimuli: "logo" });
      setCurrentSelectedStimuli("logo");
    } else if (packagingFiles.length) {
      setCurrentStimuli({ ...packagingFiles[0], stimuli: "packaging" });
      setCurrentSelectedStimuli("packaging_design");
    } else if (designElementsFiles.length) {
      setCurrentStimuli({
        ...designElementsFiles[0],
        stimuli: "design_elements",
      });
      setCurrentSelectedStimuli("design_element");
    }
  }, [logoFiles, packagingFiles, designElementsFiles]);

  useEffect(() => {
    if (projectId && user) {
      setIsLoading(true);
      httpRequest({
        method: "GET",
        url: `/projects/${projectId}/processing_status`,
        isAuthRequired: true,
      })
        .then((response) => {
          const processResult = response.data;

          setStimuliProcessingStatus(processResult);

          if (processResult.percent_complete === 0) {
            setIsStimuliProcessing(false);
            setIsProcessingModalOpen(false);
          } else if (
            processResult.percent_complete === 100 ||
            processResult.percent_complete >= 100
          ) {
            setIsStimuliProcessing(false);
            setIsProcessingModalOpen(false);
            setIsStimuliProcessed(true);
          } else {
            setIsStimuliProcessing(true);
            setIsProcessingModalOpen(true);
          }
          setIsLoading(false);
        })
        .catch(handleAPIError);
    }
    // eslint-disable-next-line
  }, [projectId, user]);

  useEffect(() => {
    if (
      stimuliProcessingStatus &&
      stimuliProcessingStatus.percent_complete === 0 &&
      user
    ) {
      setIsLoading(true);
      httpRequest({
        method: "GET",
        url: `/projects/${projectId}/image_files`,
        isAuthRequired: true,
      })
        .then((response) => {
          const stimuliInputs = response.data;

          if (
            stimuliInputs?.logo instanceof Array &&
            stimuliInputs.logo.length
          ) {
            setLogoFiles(
              stimuliInputs?.logo.map((file) => ({
                name: file.filename,
                image_hash: file.image_hash,
                public_url: file.public_url,
              }))
            );
          }

          if (
            stimuliInputs?.packaging_design instanceof Array &&
            stimuliInputs.packaging_design.length
          ) {
            setPackagingFiles(
              stimuliInputs?.packaging_design.map((file) => ({
                name: file.filename,
                image_hash: file.image_hash,
                public_url: file.public_url,
              }))
            );
          }

          if (
            stimuliInputs?.design_element instanceof Array &&
            stimuliInputs.design_element.length
          ) {
            setDesignElementsFiles(
              stimuliInputs.design_element.map((file) => ({
                name: file.filename,
                image_hash: file.image_hash,
                public_url: file.public_url,
              }))
            );
          }

          setIsLoading(false);
        })
        .catch(handleAPIError);
    }
    // eslint-disable-next-line
  }, [stimuliProcessingStatus, user]);

  useEffect(() => {
    if (projectId && user) {
      setIsLoading(true);
      httpRequest({
        url: `/projects/${projectId}/stimuli_inputs`,
        isAuthRequired: true,
      })
        .then((response) => {
          const logoFiles = response.data?.logo || [];
          const packagingFiles = response.data?.packaging_design || [];
          const designElementsFiles = response.data?.design_element || [];

          setLogoFiles(logoFiles);
          setPackagingFiles(packagingFiles);
          setDesignElementsFiles(designElementsFiles);
          setIsLoading(false);
        })
        .catch(handleAPIError);
    }
    // eslint-disable-next-line
  }, [projectId, user]);

  const isStimulisUploaded =
    logoFiles.length || packagingFiles.length || designElementsFiles.length;

  const providerValue = {
    getDraftedPersonas,
    getSelectedPersonas,
    getSavedPersonas,

    isStimuliProcessing,
    setIsStimuliProcessing,
    projectId,
    setProjectId,
    projectName,
    setProjectName,
    projectDescription,
    setProjectDescription,
    brandGuidelines,
    setBrandGuidelines,
    brandGuidelineFile,
    setBrandGuidelineFile,
    personas,
    setPersonas,

    draftedPersonas,
    setDraftedPersonas,

    selectedPersonas,
    setSelectedPersonas,
    logoFiles,
    setLogoFiles,
    packagingFiles,
    setPackagingFiles,
    designElementsFiles,
    setDesignElementsFiles,
    isStimuliFilesValidating,
    setIsStimuliFilesValidating,
    stimuliValidationErrors,
    setStimuliValidationErrors,
    isShowValidationInput,
    setIsShowValidationInput,
    isSelectBrandModalOpen,
    setIsSelectBrandModalOpen,
    brand,
    setBrand,
    isProcessingModalOpen,
    setIsProcessingModalOpen,
    currentPersona,
    setCurrentPersona,
    currentStimuli,
    setCurrentStimuli,
    isUpdatingPersonas,
    setIsUpdatingPersonas,
    isSummaryVisible,
    setIsSummaryVisible,
    isDeepDiveVisible,
    setIsDeepDiveVisible,
    isStimulisUploaded,
    currentSelectedStimuli,
    setCurrentSelectedStimuli,
    isStimuliProcessed,
    setIsStimuliProcessed,
    isStimuliUpdating,
    setIsStimuliUpdating,

    chatStimuliCategory,
    setChatStimuliCategory,
    chatStimuliVariants,
    setChatStimuliVariants,
  };

  if (isLoading) {
    return <DataLoader />;
  }

  return (
    <ProjectContext.Provider value={providerValue}>
      {children}
    </ProjectContext.Provider>
  );
}

export const useProject = () => useContext(ProjectContext);
