import { useProject } from "../../../contexts/new-project.context";

import { CLoseIcon, CaptureIcon, CheckIcon } from "../../../assets/icons";

export default function StimuliValidationModal() {
  const {
    logoFiles,
    packagingFiles,
    designElementsFiles,
    setIsStimuliFilesValidating,
  } = useProject();

  return (
    <div className="absolute inset-0 z-50 bg-black bg-opacity-75">
      <div className="w-full h-full flex justify-center items-center">
        <div className="w-96 pb-4 rounded-xl bg-white">
          <div className="relative">
            <img
              src="/png/validation-bg.png"
              alt="Stimuli files validation modal"
              className="w-full h-full absolute inset-0 z-10 rounded-t-xl"
            />

            <button
              onClick={() => setIsStimuliFilesValidating(false)}
              className="w-8 h-8 absolute top-4 right-4 inline-flex justify-center items-center rounded-full border z-30 border-[#E7EBF1] bg-white"
            >
              <CLoseIcon stroke="#536787" />
            </button>

            <div className="relative w-full h-40 flex z-20 justify-center items-start">
              <div className="w-28 h-28 p-2 mt-5 relative rounded-full bg-white">
                <div className="absolute inset-1 border-4 border-[#FEEAFD] border-t-4 border-t-[#7A3DAA] border-b-4 border-b-[#7A3DAA] border-l-4 border-l-[#7A3DAA] rounded-full animate-spin" />
                <div className="relative w-full h-full p-1 rounded-full bg-white">
                  <div className="w-full h-full">
                    <div className="w-full h-full flex justify-center items-center rounded-full bg-[#7A3DAA]">
                      <CaptureIcon width={40} height={40} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col justify-center items-center">
            <p className="mb-1 text-2xl font-semibold uppercase text-[#7A3DAA]">
              Validating uploads
            </p>

            <p className="mb-4 text-[#536787]">before the magic begins!</p>

            <div className="p-[10px] rounded-lg space-x-2 bg-[#F1EAF6]">
              <span className="w-5 h-5 inline-flex justify-center items-center rounded-full bg-white">
                <CheckIcon fill="#7A3DAA" />
              </span>

              <span className="text-sm font-medium text-[#7A3DAA]">
                {logoFiles.length +
                  packagingFiles.length +
                  designElementsFiles.length}{" "}
                Images
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
