import classNames from "classnames";
import { useEffect, useRef, useState } from "react";

import { useProject } from "../../contexts/new-project.context";
import { useError } from "../../contexts/error.context";

import { wait } from "../../utils/promise.util";
import { httpRequest } from "../../utils/http.util";

import { CheckIcon, UpdatePersonaPensilIcon } from "../../assets/icons";

import lightBg from "../../assets/png/light-bg.png";
import maleAvatar from "../../assets/png/male-avatar.png";
import personaSkeletonAvatar from "../../assets/png/persona-skeleton-avatar.png";

export default function PersonaDemographicDetails({
  isAiExpert = false,
  isEditable = true,
  personaId,
  generatedPersonaDetails,
  setGeneratedPersonaDetails,
}) {
  const ageElementRef = useRef(null);

  const { projectId, personas } = useProject();
  const { handleAPIError } = useError();

  const [isUpdating, setIsUpdating] = useState(false);
  const [, setCurrentPersona] = useState(null);

  const handlePersonaNameChange = (name) => {
    setGeneratedPersonaDetails((prev) => {
      return {
        ...prev,
        persona_info: {
          ...prev.persona_info,
          persona_name: name,
        },
      };
    });
  };

  const handleDemographicDataChange = (e, id) => {
    setGeneratedPersonaDetails((prev) => {
      return {
        ...prev,
        persona_info: {
          ...prev.persona_info,
          demographics: prev.persona_info.demographics.map((item) =>
            item.id === id ? { ...item, description: e.target.value } : item
          ),
        },
      };
    });
  };

  const handleEnableEditing = async () => {
    setIsUpdating(true);
    await wait(100);
    ageElementRef.current.focus();
  };

  const handleUpdate = async () => {
    try {
      setIsUpdating(false);

      const payload = {
        persona_name: generatedPersonaDetails?.persona_info?.persona_name || "",
        demographics:
          generatedPersonaDetails.persona_info.demographics.map((item) => ({
            ...item,
            id: undefined,
          })) || [],
        profile:
          generatedPersonaDetails.persona_info.profile.map((item) => ({
            ...item,
            section: `${item.icon} ${item.section}`,
            icon: undefined,
            id: undefined,
          })) || [],
        brief_summary:
          generatedPersonaDetails?.persona_info?.brief_summary || "",
      };

      const response = await httpRequest({
        domain: "chat",
        url: `/projects/${projectId}/personas/${personaId}/brief_summary`,
        method: "POST",
        data: payload,
        isAuthRequired: true,
      });

      setGeneratedPersonaDetails((prev) => ({
        ...prev,
        persona_info: {
          ...prev.persona_info,
          brief_summary: response.data,
        },
      }));
    } catch (error) {
      handleAPIError(error);
    }
  };

  useEffect(() => {
    if (personaId) {
      setCurrentPersona(
        personas.find((persona) => persona.persona_id === personaId)
      );
    }
    // eslint-disable-next-line
  }, [personas, personaId]);

  let profileImageUrl = "";

  if (generatedPersonaDetails?.persona_info?.avatar_url) {
    profileImageUrl = generatedPersonaDetails?.persona_info?.avatar_url;
  } else if (isAiExpert) {
    profileImageUrl = maleAvatar;
  } else {
    profileImageUrl = personaSkeletonAvatar;
  }

  return (
    <div
      className={classNames(
        "p-6 rounded-xl z-10 relative border border-[#F7EAFB]",
        {
          "bg-[#F7F2FB]": !isUpdating,
        }
      )}
    >
      <img
        src={lightBg}
        alt="Bulb icon"
        className={classNames(
          "w-full h-full absolute inset-0 z-10 opacity-10",
          { hidden: isUpdating }
        )}
      />

      {isEditable ? (
        <button
          onClick={handleEnableEditing}
          className={classNames("absolute top-4 right-4 z-30", {
            hidden: isUpdating,
          })}
        >
          <UpdatePersonaPensilIcon />
        </button>
      ) : null}

      <button
        onClick={handleUpdate}
        className={classNames(
          "w-6 h-6 flex justify-center items-center rounded-full absolute top-4 right-4 z-30 bg-[#F1EAF6]",
          {
            hidden: !isUpdating,
          }
        )}
      >
        <CheckIcon />
      </button>

      <div className="relative inset-0 z-20 flex max-lg:flex-wrap gap-x-10 gap-y-5">
        <div className="w-[167px] flex-none h-[167px] rounded-full bg-[#F1EAF6]">
          <img
            src={profileImageUrl}
            alt="Selected persona"
            className="w-full h-full object-contain rounded-full"
          />
        </div>

        <div
          className={classNames("flex-auto", { "self-center": !isAiExpert })}
        >
          <p className="w-[296px] mb-3 text-2xl font-semibold">
            <span className={classNames({ hidden: isUpdating })}>
              {isAiExpert
                ? "AI Expert"
                : generatedPersonaDetails?.persona_info?.persona_name}
            </span>
            <input
              type="text"
              value={generatedPersonaDetails?.persona_info?.persona_name || ""}
              onChange={(e) => handlePersonaNameChange(e.target.value)}
              className={classNames(
                "bg-transparent outline-none border border-transparent focus:border-b focus:border-b-[#7A3DAA]",
                { hidden: !isUpdating }
              )}
            />
          </p>

          {isAiExpert ? (
            <p className="text-[#060606]">
              An advanced AI with specialised knowledge about packaging design
            </p>
          ) : null}

          {!isAiExpert ? (
            <div className="flex [@media(max-width:1300px)]:flex-wrap gap-x-10 gap-y-5">
              <div className="w-[296px] flex-none space-y-1">
                {generatedPersonaDetails?.persona_info?.demographics
                  ?.slice(0, 3)
                  ?.map?.((item, index) => (
                    <p
                      key={index}
                      className={classNames("text-[#060606]", {
                        "flex flex-wrap": isUpdating,
                      })}
                    >
                      <span className="flex-none font-semibold">
                        {item.name}:{" "}
                      </span>
                      <span
                        className={classNames("border border-transparent", {
                          hidden: isUpdating,
                        })}
                      >
                        {item.description}
                      </span>
                      <input
                        ref={index === 0 ? ageElementRef : null}
                        type="text"
                        value={item.description || ""}
                        onChange={(e) =>
                          handleDemographicDataChange(e, item.id)
                        }
                        className={classNames(
                          "bg-transparent outline-none border border-transparent focus:border-b focus:border-b-[#7A3DAA]",
                          { hidden: !isUpdating }
                        )}
                      />
                    </p>
                  ))}
              </div>

              <div className="flex-auto space-y-1">
                {generatedPersonaDetails?.persona_info?.demographics
                  ?.slice(3)
                  ?.map?.((item, index) => (
                    <p
                      key={index}
                      className={classNames("text-[#060606]", {
                        "flex flex-wrap": isUpdating,
                      })}
                    >
                      <span className="flex-none font-semibold">
                        {item.name}:{" "}
                      </span>
                      <span
                        className={classNames("border border-transparent", {
                          hidden: isUpdating,
                        })}
                      >
                        {item.description}
                      </span>
                      <input
                        type="text"
                        value={item.description || ""}
                        onChange={(e) =>
                          handleDemographicDataChange(e, item.id)
                        }
                        className={classNames(
                          "flex-auto bg-transparent outline-none border border-transparent focus:border-b focus:border-b-[#7A3DAA]",
                          { hidden: !isUpdating }
                        )}
                      />
                    </p>
                  ))}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
