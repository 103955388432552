import React from "react";

export default function HighlightedText2({ text, color }) {
  const parts = text.split("**");

  return (
    <p>
      {parts.map((part, index) => (
        <React.Fragment key={index}>
          {index % 2 === 0 ? (
            part
          ) : (
            <span style={{ color }} className="font-semibold opacity-60">
              {part}
            </span>
          )}
        </React.Fragment>
      ))}
    </p>
  );
}
