import { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";

import Button from "../components/NewButton";
import DataLoader from "../components/Data-Loader";
import PersonaProfileCard from "../components/persona/PersonaProfileCard";
import PersonaDemographicDetails from "../components/persona/PersonaDemographicDetails";

import { useProject } from "../contexts/new-project.context";
import { useError } from "../contexts/error.context";

import { httpRequest } from "../utils/http.util";

const GeneratedPersona = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { projectId, getDraftedPersonas, getSavedPersonas } = useProject();
  const { handleAPIError } = useError();

  const personaId = params.personaId;

  const [generatedPersonaDetails, setGeneratedPersonaDetails] = useState(null);
  const [isLoadingPersonaDetails, setIsLoadingPersonaDetails] = useState(true);

  const handlePersonaSave = async () => {
    try {
      const payload = {
        save: true,
        ...generatedPersonaDetails.persona_info,
        demographics: generatedPersonaDetails.persona_info.demographics.map(
          (item) => ({
            ...item,
            id: undefined,
          })
        ),
        profile: generatedPersonaDetails.persona_info.profile.map((item) => ({
          ...item,
          section: `${item.icon} ${item.section}`,
          icon: undefined,
          id: undefined,
        })),
      };

      await httpRequest({
        method: "PUT",
        url: `/projects/${projectId}/personas/${personaId}`,
        isAuthRequired: true,
        data: payload,
      });

      await getSavedPersonas();
      await getDraftedPersonas();

      navigate("/select-persona", {
        replace: true,
        state: { newAddedPersonaId: personaId },
      });
    } catch (error) {
      handleAPIError(error);
    }
  };

  const handlePersonaProfileChange = (e, id) => {
    setGeneratedPersonaDetails((prev) => {
      return {
        ...prev,
        persona_info: {
          ...prev.persona_info,
          profile: prev.persona_info.profile.map((item) =>
            item.id === id ? { ...item, content: e.target.value } : item
          ),
        },
      };
    });
  };

  const handlePersonaDeleteAndClose = async () => {
    try {
      await httpRequest({
        method: "DELETE",
        url: `/projects/${projectId}/personas/${personaId}`,
        isAuthRequired: true,
      });

      await getDraftedPersonas();

      navigate(-1, { replace: true });
    } catch (error) {
      handleAPIError(error);
    }
  };

  useEffect(() => {
    const main = async () => {
      if (projectId && personaId) {
        try {
          setIsLoadingPersonaDetails(true);

          const getPersonaDetailsResponse = await httpRequest({
            url: `/projects/${projectId}/personas/${personaId}`,
            isAuthRequired: true,
          });

          const personaDemographicDetailList =
            getPersonaDetailsResponse.data.demographics.map((item, index) => {
              return { ...item, id: index + 1 };
            });

          const personaProfiles = getPersonaDetailsResponse.data.profile.map(
            (item, index) => {
              const icon = item.section.slice(0, item.section.indexOf(" "));
              const section = item.section
                .substr(item.section.indexOf(" "))
                .trim();

              return { ...item, icon, section, id: index + 1 };
            }
          );

          setGeneratedPersonaDetails({
            persona_info: {
              ...getPersonaDetailsResponse.data,
              demographics: personaDemographicDetailList,
              profile: personaProfiles,
            },
          });

          setIsLoadingPersonaDetails(false);
        } catch (error) {
          handleAPIError(error);
        }
      }
    };

    main();
    // eslint-disable-next-line
  }, [projectId, personaId]);

  if (!projectId) {
    return <Navigate to="/" replace />;
  }

  return (
    <div className="flex-auto flex flex-col">
      {isLoadingPersonaDetails ? (
        <DataLoader />
      ) : (
        <>
          <div className="p-9 flex-auto space-y-11 overflow-auto">
            <p className="text-[32px] font-semibold text-[#7A3DAA]">
              Your Persona 🎉
            </p>

            <PersonaDemographicDetails
              personaId={personaId}
              generatedPersonaDetails={generatedPersonaDetails}
              setGeneratedPersonaDetails={setGeneratedPersonaDetails}
            />

            <div className="space-y-5">
              {/* <div className="px-3 py-5 border rounded-lg border-[#F7E7FB]">
                <p className="mb-3 text-xl font-semibold">
                  🚀 A quick summary about your persona
                </p>
                <p>
                  Sweet Indulgers represent a dynamic consumer segment that
                  values both the nostalgic and innovative aspects of snack
                  foods, making them prime targets for Oreo Chocolate Crème
                  Flavored Sandwich Biscuits.
                </p>
              </div> */}

              <div className="grid lg:grid-cols-2 gap-x-10 gap-y-5">
                {generatedPersonaDetails?.persona_info?.profile?.map?.(
                  (personaProfile, index) => (
                    <PersonaProfileCard
                      key={index}
                      personaProfile={personaProfile}
                      handlePersonaProfileChange={handlePersonaProfileChange}
                    />
                  )
                )}
              </div>

              {/* <div className="border rounded-[10px] border-[#F1EAF6]">
              <p className="p-5 bg-[#F7F8FA]">
                📣 Opportunities for Engagement
              </p>
              <div className="p-5 space-y-5">
                <p>
                  Lifestyle: Active social life, often engaged in gatherings
                  and social events where snacks are enjoyed.
                </p>
                <p>
                  Interests: High interest in new and unique food experiences,
                  often influenced by trends seen on social media platforms
                  like Instagram and TikTok.
                </p>
                <p>
                  Personality Traits: Fun-loving, adventurous, and enjoys
                  comfort foods.
                </p>
                <p>
                  Values: Values convenience, flavour variety, and brand
                  familiarity.
                </p>
              </div>
            </div> */}
            </div>
          </div>

          <div className="px-9 py-4 flex-none text-right space-x-2">
            <Button
              title="Cancel"
              variant="secondary"
              onClick={handlePersonaDeleteAndClose}
            />
            <Button
              title="Save Persona"
              variant="primary"
              onClick={handlePersonaSave}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default GeneratedPersona;
