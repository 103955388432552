import classNames from "classnames";
import { useNavigate } from "react-router-dom";

import Button from "../../../components/NewButton";

import { useProject } from "../../../contexts/new-project.context";
import { useError } from "../../../contexts/error.context";

import { CheckIcon, CompletedIcon } from "../../../assets/icons";

export default function SelectBrandModal({ saveStimuliInputs }) {
  const navigate = useNavigate();

  const {
    projectId,
    brand,
    setBrand,
    stimuliValidationErrors,
    setStimuliValidationErrors,
    setIsSelectBrandModalOpen,
  } = useProject();
  const { handleAPIError } = useError();

  function handleClick(value) {
    const newBrandValidationErrors = stimuliValidationErrors
      .map((brandError) => {
        brandError.is_brand_name = false;
        return brandError;
      })
      .map((brandError) => {
        if (brandError.keyword === value) {
          brandError.is_brand_name = true;
          return brandError;
        } else {
          return brandError;
        }
      });

    setBrand(value);
    setStimuliValidationErrors(newBrandValidationErrors);
  }

  async function handleSave() {
    try {
      await saveStimuliInputs();
      setIsSelectBrandModalOpen(false);
      navigate(`/${projectId}/select-persona`);
    } catch (error) {
      handleAPIError(error);
    }
  }

  const formDisabled = brand === "";

  return (
    <div className="absolute inset-0 z-50 bg-black bg-opacity-75">
      <div className="w-full h-full flex justify-center items-center">
        <div className="max-w-[800px] rounded-xl bg-white">
          <div className="m-2 p-3 flex items-center gap-x-2 rounded-xl bg-[#E9F8EA]">
            <CompletedIcon />
            <p className="text-sm font-medium text-[#326736]">
              Validation complete! Files have been checked and cleared for
              takeoff!
            </p>
          </div>

          <div className="px-4 py-4 bg-white">
            <p className="text-2xl font-medium text-[#060606]">
              Confirm Brand Name
            </p>
            <p className="text-sm text-[#536787]">
              Can you help confirm the brand name
            </p>
          </div>

          <div className="p-4 max-h-60 flex flex-wrap gap-4 overflow-y-auto">
            {stimuliValidationErrors.map((brandError, index) => (
              <button
                key={index}
                onClick={() => handleClick(brandError.keyword)}
                className={classNames(
                  "min-h-11 px-3 py-[5px] text-sm flex items-center justify-center gap-x-2 border rounded  hover:border-[#7A3DAA]",
                  brand === brandError.keyword
                    ? "font-semibold border-[#7A3DAA] text-[#7A3DAA] bg-[#F8F4FA]"
                    : "font-medium border-[#D2D9E5] text-[#060606]"
                )}
              >
                {brand === brandError.keyword ? (
                  <span className="w-4 h-4 inline-flex justify-center items-center rounded-full bg-[#7A3DAA]">
                    <CheckIcon fill="#ffffff" />
                  </span>
                ) : null}
                {brandError.keyword}
              </button>
            ))}
          </div>

          <div className="ps-4 py-4 pe-8 text-right space-x-2">
            <Button
              title="Back"
              variant="secondary"
              onClick={() => setIsSelectBrandModalOpen(false)}
            />
            <Button
              title="Save & Continue"
              variant={formDisabled ? "disabled" : "primary"}
              disabled={formDisabled}
              onClick={handleSave}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
