import classNames from "classnames";
import { useState } from "react";

import { CheckIcon, DownMenuIcon, RightArrowIcon } from "../../assets/icons";

export default function DeepDiveInto({
  dropdownOptions,
  selectedDeepDive,
  setSelectedDeepDive,
  selectedAttributeElement,
  setSelectedAttributeElement,
  selectedElement,
  setSelectedElement,
  selectedAttribute,
  setSelectedAttribute,
  deepDiveElements2,
}) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleDropdownSelect = (item) => {
    if (item.key === "attributes" || item.key === "visualElements") {
      setSelectedElement(deepDiveElements2.visualElements[1]);
      setSelectedAttribute(deepDiveElements2.attributes[1]);
      setSelectedAttributeElement(dropdownOptions[0]);
    } else if (item.key === "textElements") {
      setSelectedElement(deepDiveElements2.textElements[1]);
      setSelectedAttribute(deepDiveElements2.attributes[1]);
      setSelectedAttributeElement(dropdownOptions[1]);
    }
    setSelectedDeepDive(item);
    setIsDropdownOpen(false);
  };

  return (
    <div>
      <div className="flex gap-x-7 border-x border-t rounded-t-xl border-[#EDEFF4] bg-[#EDEFF4] bg-opacity-65">
        <div className="ps-2 py-2 flex-none relative">
          <div
            onClick={() => setIsDropdownOpen((prev) => !prev)}
            className={classNames(
              "px-3 py-2 flex items-center justify-between gap-3 cursor-pointer hover:border-[#7A3DAA]",
              isDropdownOpen
                ? "rounded-t-lg border-x border-t border-[#7A3DAA] bg-[#F7F8FA]"
                : "border border-[#EDEFF4] rounded-lg bg-white"
            )}
          >
            <span className="text-sm text-[#536787]">Dive into:</span>
            <span className="text-sm font-semibold">
              {selectedDeepDive.label}
            </span>
            <div>
              <DownMenuIcon />
            </div>
          </div>

          {isDropdownOpen && (
            <div className="absolute left-0 right-0 z-10 border rounded-b-lg border-x-[#7A3DAA] border-b-[#7A3DAA] bg-white">
              {dropdownOptions.map((item, index, array) => (
                <div
                  key={index}
                  onClick={() => handleDropdownSelect(item)}
                  className={classNames(
                    "px-3 py-2 flex items-center justify-between cursor-pointer select-none hover:bg-[#F7F8FA] hover:font-semibold",
                    { "rounded-b-lg": index === array.length - 1 }
                  )}
                >
                  <div className="flex items-center gap-1">
                    <div
                      className={classNames("text-sm", {
                        "text-[#7A3DAA] font-semibold":
                          selectedDeepDive.key === item.key,
                      })}
                    >
                      {item.label}
                    </div>
                  </div>
                  {selectedDeepDive.key === item.key && (
                    <div className="w-4 h-4 rounded-full flex justify-center items-center bg-[#7A3DAA]">
                      <CheckIcon fill="#ffffff" />
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="pe-2 flex-auto flex gap-7 overflow-x-auto">
          {selectedDeepDive.key === "attributes" ? (
            <>
              {deepDiveElements2?.attributes?.map?.((item) => (
                <span
                  key={item.id}
                  onClick={() => {
                    if (
                      selectedElement?.name === "Overall" &&
                      item?.name === "Overall"
                    )
                      return;

                    setSelectedAttribute(item);
                  }}
                  className={classNames(
                    "self-center flex-none uppercase text-sm",
                    selectedElement?.name === "Overall" &&
                      item?.name === "Overall"
                      ? "cursor-not-allowed"
                      : "cursor-pointer hover:text-[#7A3DAA] hover:font-bold",
                    {
                      "text-[#7A3DAA] font-bold":
                        selectedAttribute?.id === item.id,
                    }
                  )}
                >
                  {item.name}
                </span>
              ))}
            </>
          ) : (
            <>
              {deepDiveElements2?.[selectedDeepDive.key]?.map?.((item) => (
                <span
                  key={item.id}
                  onClick={() => {
                    if (
                      selectedAttribute?.name === "Overall" &&
                      item?.name === "Overall"
                    )
                      return;

                    setSelectedElement(item);
                  }}
                  className={classNames(
                    "self-center flex-none uppercase text-sm",
                    selectedAttribute?.name === "Overall" &&
                      item?.name === "Overall"
                      ? "cursor-not-allowed"
                      : "cursor-pointer hover:text-[#7A3DAA] hover:font-bold",
                    {
                      "text-[#7A3DAA] font-bold":
                        selectedElement?.id === item.id,
                    }
                  )}
                >
                  {item.name}
                </span>
              ))}
            </>
          )}
        </div>
      </div>

      {selectedDeepDive.key === "attributes" ? (
        <>
          <div className="flex gap-x-7 border-x border-b border-[#EDEFF4]">
            <div className="self-center ps-5 py-4 flex-none">
              <span className="text-sm">Visual Elements</span>
            </div>

            <div className="pe-5 py-4 flex-auto flex gap-2 overflow-x-auto">
              {deepDiveElements2["visualElements"].map((item) => (
                <span
                  key={item.id}
                  onClick={() => {
                    if (
                      selectedAttribute?.name === "Overall" &&
                      item?.name === "Overall"
                    )
                      return;

                    setSelectedElement(item);
                    setSelectedAttributeElement(
                      dropdownOptions.find(
                        (item) => item.key === "visualElements"
                      )
                    );
                  }}
                  className={classNames(
                    "self-center px-5 py-[10px] border rounded-full flex-none text-sm",
                    selectedAttribute?.name === "Overall" &&
                      item?.name === "Overall"
                      ? "cursor-not-allowed"
                      : "cursor-pointer text-[#536787] border-[#D2D9E5] hover:border-[#7A3DAA] hover:text-black hover:font-semibold",
                    {
                      "border-[#7A3DAA] text-[#7A3DAA] font-semibold bg-[#F7F1FC]":
                        selectedElement?.id === item.id,
                    }
                  )}
                >
                  {item.name}
                </span>
              ))}
            </div>
          </div>

          <div className="flex gap-x-7 border-x border-b-4 rounded-b-xl border-[#EDEFF4]">
            <div className="self-center ps-5 py-4 flex-none">
              <span className="text-sm">Text Elements</span>
            </div>

            <div className="pe-5 py-4 flex-auto flex gap-2 overflow-x-auto">
              {deepDiveElements2["textElements"].map((item) => (
                <span
                  key={item.id}
                  onClick={() => {
                    if (
                      selectedAttribute?.name === "Overall" &&
                      item?.name === "Overall"
                    )
                      return;

                    setSelectedElement(item);
                    setSelectedAttributeElement(
                      dropdownOptions.find(
                        (item) => item.key === "textElements"
                      )
                    );
                  }}
                  className={classNames(
                    "self-center px-5 py-[10px] border rounded-full flex-none text-sm",
                    selectedAttribute?.name === "Overall" &&
                      item?.name === "Overall"
                      ? "cursor-not-allowed"
                      : "cursor-pointer text-[#536787] border-[#D2D9E5] hover:border-[#7A3DAA] hover:text-black hover:font-semibold",
                    {
                      "border-[#7A3DAA] text-[#7A3DAA] font-semibold bg-[#F7F1FC]":
                        selectedElement?.id === item.id,
                    }
                  )}
                >
                  {item.name}
                </span>
              ))}
            </div>
          </div>
        </>
      ) : (
        <div className="flex gap-x-7 border-x border-b-4 rounded-b-xl border-[#EDEFF4]">
          <div className="self-center ps-5 py-5 flex-none">
            <span className="text-sm">Attributes</span>
          </div>

          <div className="pe-5 py-4 flex-auto flex gap-2 overflow-x-auto">
            {deepDiveElements2?.attributes?.map?.((item) => (
              <span
                key={item.id}
                onClick={() => {
                  if (
                    selectedElement?.name === "Overall" &&
                    item?.name === "Overall"
                  )
                    return;

                  setSelectedAttribute(item);
                }}
                className={classNames(
                  "self-center px-5 py-[10px] border rounded-full flex-none text-sm",
                  selectedElement?.name === "Overall" &&
                    item?.name === "Overall"
                    ? "cursor-not-allowed"
                    : "cursor-pointer text-[#536787] border-[#D2D9E5] hover:border-[#7A3DAA] hover:text-black hover:font-semibold",
                  {
                    "border-[#7A3DAA] text-[#7A3DAA] font-semibold bg-[#F7F1FC]":
                      selectedAttribute?.id === item.id,
                  }
                )}
              >
                {item.name}
              </span>
            ))}
          </div>
        </div>
      )}

      {selectedElement && selectedAttribute ? (
        <div className="mt-4">
          {selectedDeepDive.key === "attributes" ? (
            <ul className="flex items-center flex-wrap gap-y-4">
              <li className="flex-none flex items-center flex-wrap text-sm text-[#536787]">
                <span className="uppercase">
                  {
                    deepDiveElements2.attributes.find(
                      (item) => item.id === selectedAttribute?.id
                    ).name
                  }
                </span>
                <span className="mx-3">
                  <RightArrowIcon />
                </span>
              </li>
              <li className="flex-none flex items-center flex-wrap text-sm text-[#536787]">
                <span>{selectedAttributeElement.label}</span>
                <span className="mx-3">
                  <RightArrowIcon />
                </span>
              </li>
              <li className="flex-none flex items-center flex-wrap text-sm text-[#536787]">
                <span className="font-semibold text-[#7A3DAA]">
                  {
                    deepDiveElements2[selectedAttributeElement.key].find(
                      (item) => item.id === selectedElement?.id
                    ).name
                  }
                </span>
              </li>
            </ul>
          ) : (
            <ul className="flex items-center flex-wrap gap-y-4">
              <li className="flex-none flex items-center flex-wrap text-sm text-[#536787]">
                <span className="uppercase">
                  {
                    deepDiveElements2[selectedDeepDive.key].find(
                      (item) => item.id === selectedElement?.id
                    )?.name
                  }
                </span>
                <span className="mx-3">
                  <RightArrowIcon />
                </span>
              </li>
              <li className="flex-none flex items-center flex-wrap text-sm text-[#536787]">
                <span className="font-semibold text-[#7A3DAA]">
                  {
                    deepDiveElements2.attributes.find(
                      (item) => item.id === selectedAttribute?.id
                    )?.name
                  }
                </span>
              </li>
            </ul>
          )}
        </div>
      ) : null}
    </div>
  );
}
