import { Navigate, useLocation } from "react-router-dom";
import {
  GoogleLoginButton,
  MicrosoftLoginButton,
} from "react-social-login-buttons";

import { useAuth } from "../contexts/auth.context";

export default function Login() {
  const { state } = useLocation();

  const { googleSignIn, microsoftSignIn, user } = useAuth();

  const handleGoogleSignIn = async () => {
    await googleSignIn();
  };

  const handleMicrosoftSignIn = async () => {
    await microsoftSignIn();
  };

  if (user) {
    return (
      <Navigate
        to={`${state?.redirectedFrom || ""}${state?.search || ""}` || "/"}
        replace={true}
      />
    );
  }

  return (
    <div className="h-screen overflow-auto flex justify-center items-center flex-wrap">
      <div className="p-6 flex flex-col items-center gap-y-5 border rounded-lg">
        <h1 className="text-2xl">Login with your account</h1>
        <GoogleLoginButton onClick={handleGoogleSignIn} />
        <MicrosoftLoginButton onClick={handleMicrosoftSignIn} />
      </div>
    </div>
  );
}
