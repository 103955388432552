import "react-toastify/dist/ReactToastify.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { ToastContainer } from "react-toastify";
import { BrowserRouter } from "react-router-dom";

import "./index.css";
import App from "./App";

import { GlobalContextProvider } from "./contexts/global.context";
import { ErrorContextProvider } from "./contexts/error.context";
import { AuthContextProvider } from "./contexts/auth.context";
import { ProjectContextProvider } from "./contexts/new-project.context";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.Fragment>
    <ToastContainer />
    <BrowserRouter>
      <GlobalContextProvider>
        <AuthContextProvider>
          <ErrorContextProvider>
            <ProjectContextProvider>
              <App />
            </ProjectContextProvider>
          </ErrorContextProvider>
        </AuthContextProvider>
      </GlobalContextProvider>
    </BrowserRouter>
  </React.Fragment>
);
