export function setItemToStore(item, value) {
  localStorage.setItem(item, value);
}

export function itemExistInStore(item) {
  const exist = localStorage.getItem(item);
  return exist ? true : false;
}

export function getItemFromStore(item) {
  return localStorage.getItem(item);
}

export function removeItemFromStore(item) {
  localStorage.removeItem(item);
}

export function setItemToSessionStore(item, value) {
  sessionStorage.setItem(item, value);
}

export function itemExistInSessionStore(item) {
  const exist = sessionStorage.getItem(item);
  return exist ? true : false;
}

export function getItemFromSessionStore(item) {
  return sessionStorage.getItem(item);
}

export function removeItemFromSessionStore(item) {
  sessionStorage.removeItem(item);
}

export function setCookie(name, value) {
  const expiryDate = new Date();
  expiryDate.setDate(expiryDate.getDate() + 1);
  const expires = expiryDate.toUTCString();
  document.cookie = `${name}=${value}; expires=${expires}; path=/`;
}

export function getCookie(name) {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(name + "=")) {
      return cookie.substring(name.length + 1);
    }
  }
  return null;
}
