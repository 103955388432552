import { useEffect, useState } from "react";

import PersonaCard from "../PersonaCard";
import Button from "../NewButton";

import { useProject } from "../../contexts/new-project.context";
import { useError } from "../../contexts/error.context";

import { httpRequest } from "../../utils/http.util";

import { BulbIcon } from "../../assets/icons";
import { useNavigate } from "react-router-dom";

export default function UpdateSelectedPersonas() {
  const navigate = useNavigate();
  const {
    projectId,
    personas,
    selectedPersonas,
    setSelectedPersonas,
    setIsUpdatingPersonas,
  } = useProject();
  const { handleAPIError } = useError();

  const [localSelectedPersonas, setLocalSelectedPersonas] = useState(
    selectedPersonas?.map?.((persona) => persona?.id) || []
  );

  const handlePersonaClick = (personaId) => {
    if (localSelectedPersonas.includes(personaId)) {
      setLocalSelectedPersonas((prev) =>
        prev.filter((persona) => persona !== personaId)
      );
    } else {
      setLocalSelectedPersonas((prev) => [...prev, personaId]);
    }
  };

  const savePersonas = async () => {
    try {
      const personaList = personas.filter((item) =>
        localSelectedPersonas.includes(item.id)
      );
      await savePersonasApi({
        personas: personaList.map((persona) => persona.role),
      });
      setSelectedPersonas(personaList);
      setIsUpdatingPersonas(false);
      navigate(`/project-stimuli/${projectId}/insights`);
    } catch (error) {
      handleAPIError(error);
    }
  };

  async function savePersonasApi(personas) {
    await httpRequest({
      method: "PUT",
      url: `/projects/${projectId}/personas/selected`,
      data: personas,
      isAuthRequired: true,
    });
  }

  useEffect(() => {
    setLocalSelectedPersonas(
      selectedPersonas?.map?.((persona) => persona?.id) || []
    );
  }, [selectedPersonas]);

  return (
    <div className="flex-1 overflow-auto">
      <div className="min-h-full overflow-auto flex justify-center items-center">
        <div className="max-w-[800px] space-y-8">
          <div className="p-3 rounded bg-[#FDFAF2]">
            <p className="flex gap-x-2 items-center font-medium text-sm text-[#D09625]">
              <BulbIcon />
              <span>You can select multiple personas for analysis</span>
            </p>
          </div>

          <div className="grid grid-cols-3 gap-4">
            {personas.map((persona) => (
              <PersonaCard
                key={persona.id}
                persona={persona}
                selectedPersonas={localSelectedPersonas}
                handlePersonaClick={handlePersonaClick}
              />
            ))}
          </div>

          <div className="flex justify-end">
            <Button
              title="Next"
              variant={!localSelectedPersonas.length ? "disabled" : "primary"}
              type="button"
              disabled={!localSelectedPersonas.length}
              onClick={savePersonas}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
