import classNames from "classnames";

export default function Button({
  variant,
  title,
  className,
  style,
  disabled,
  type = "button",
  ...props
}) {
  const baseClasses = "min-w-40 p-3 font-medium text-sm rounded";

  const buttonClasses = classNames(
    baseClasses,
    {
      "text-white bg-[#7A3DAA] hover:bg-[#4E2670]": variant === "primary",
      "text-[#7A3DAA] bg-[#F1EAF6] hover:text-white hover:bg-[#7A3DAA]":
        variant === "secondary",
      "text-[#536787] bg-[#DFE4EC] hover:cursor-not-allowed":
        variant === "disabled",
      "text-sm font-medium text-[#7A3DAA]": variant === "plain",
    },
    className
  );

  return (
    <button
      type={type}
      style={style}
      disabled={disabled}
      className={buttonClasses}
      {...props}
    >
      {title}
    </button>
  );
}
