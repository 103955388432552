import classNames from "classnames";
import { useEffect, useState } from "react";

import Loader from "./Loader";
import HighlightedText from "./HighlightedText";

import { useProject } from "../../contexts/new-project.context";
import { useError } from "../../contexts/error.context";

import { httpRequest } from "../../utils/http.util";

import { CheckIcon, InfoIcon3 } from "../../assets/icons";

import light from "../../assets/png/light.png";
import lightBg from "../../assets/png/light-bg.png";

export default function Summary() {
  const [isLoading, setIsLoading] = useState(true);
  const [summaryData, setSummaryData] = useState(null);

  const [isVariantImageVisible, setIsVariantImageVisible] = useState(false);

  const { projectId, currentPersona, currentStimuli, currentSelectedStimuli } =
    useProject();
  const { handleAPIError } = useError();

  useEffect(() => {
    if (currentPersona && currentStimuli && currentSelectedStimuli) {
      httpRequest({
        url: `/projects/${projectId}/${currentSelectedStimuli}/summary/${currentPersona?.persona_id}/${currentStimuli?.image_hash}/v2`,
        isAuthRequired: true,
      })
        .then((response) => {
          setSummaryData(response.data);
          setIsLoading(false);
        })
        .catch(handleAPIError);
    }
    // eslint-disable-next-line
  }, [currentPersona, currentStimuli, currentSelectedStimuli]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="rounded-xl relative border border-[#F7EAFB] bg-[#F7F2FB]">
            <img
              src={lightBg}
              alt="Bulb icon"
              className="w-full h-full absolute inset-0 z-10 opacity-10"
            />
            <div className="relative inset-0 z-20">
              <div className="inline-flex items-center gap-2 rounded-full mx-10 mt-8 px-3 py-[10px] bg-white">
                <img src={light} alt="light" width={20} />
                <label className="font-medium text-xs text-[#000000] leading-[18px]">
                  Summarised Insights
                </label>
              </div>
              <p className="text-[#060606] font-medium mx-10 leading-[30px] mt-4">
                {summaryData?.key_takeaway}
              </p>
              <div className="grid max-md:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mx-10 my-4 pb-4">
                {/* {new Array(4).fill(1).map((_, index) => (
              <div
                key={index}
                className="bg-[#ffffff] p-4 rounded-lg leading-[20px]"
              >
                <div>
                  <label className="font-semibold text-[12px] text-[#7A3DAA]">
                    Insight {index + 1}
                  </label>
                  <p className="font-semibold text-sm mt-2 text-[#536787]">
                    <span>
                      Kent seen as a considerate and quality brand with smooth
                      tasting product.
                    </span>
                    <span className="font-normal">
                      However, it has an imagery of a brand for older smokers
                    </span>
                  </p>
                </div>
              </div>
            ))} */}
              </div>
            </div>
          </div>

          <div className="flex gap-10 [@media(max-width:1000px)]:flex-wrap">
            <div
              onMouseOver={() => setIsVariantImageVisible(true)}
              onMouseLeave={() => setIsVariantImageVisible(false)}
              className="flex-none w-[400px] h-[400px] border border-[#EDEFF4] rounded-xl [@media(max-width:1000px)]:w-full"
            >
              <img
                src={currentStimuli?.public_url}
                alt={currentStimuli?.name}
                className="w-full h-full object-contain rounded-xl"
              />
            </div>

            <div
              onMouseOver={() => setIsVariantImageVisible(true)}
              onMouseLeave={() => setIsVariantImageVisible(false)}
              className={classNames(
                "w-[550px] h-[550px] fixed z-40 left-5 top-2/4 -translate-y-2/4 rounded-xl bg-white border shadow-2xl",
                isVariantImageVisible ? "block" : "hidden"
              )}
            >
              <img
                src={currentStimuli?.public_url}
                alt={currentStimuli?.name}
                className="w-full h-full object-contain rounded-lg"
              />
            </div>

            <div className="flex-auto h-[400px] grid [@media(max-width:1200px)]:grid-cols-1 md:grid-cols-2 gap-10 overflow-auto">
              {summaryData?.most_consequential_critiques?.map?.(
                (item, index) => (
                  <div key={index}>
                    <p className="font-light text-[40px] text-[#7A3DAA]">
                      {(index + 1).toLocaleString("en-US", {
                        minimumIntegerDigits: 2,
                        useGrouping: false,
                      })}
                    </p>
                    <p className="uppercase font-semibold underline text-[#7A3DAA]">
                      {item.title}
                    </p>
                    <p className="mt-2">{item.critique}</p>
                  </div>
                )
              )}
            </div>
          </div>

          <div className="grid lg:grid-cols-12 gap-3 max-h-[680px] overflow-auto">
            <div className="p-6 col-span-6 border shadow-md rounded-xl border-[#E2F2E3] shadow-[#E2F2E3] bg-[#F4FCF5]">
              <div>
                <p className="mb-3 text-xl font-medium text-[#3F9545]">
                  What's working?
                </p>
                <div className="space-y-3">
                  {summaryData?.positive_analysis?.map?.((text, index) => (
                    <div key={index}>
                      <p className="flex gap-x-2 text-sm text-[#060606]">
                        <span className="flex-none w-4 h-4 rounded-full inline-flex justify-center items-center bg-[#3F9545]">
                          <CheckIcon fill="#ffffff" />
                        </span>
                        <HighlightedText text={text} />
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="p-6 col-span-6  border shadow-md rounded-xl border-[#FAF1DE] shadow-[#FAF1DE] bg-[#FDFAF2]">
              <p className="mb-3 text-xl font-medium text-[#D09625]">
                What's not?
              </p>
              <div className="space-y-3">
                {summaryData?.analysis?.map?.((text, index) => (
                  <div key={index}>
                    <p className="flex gap-x-2 text-sm text-[#060606]">
                      <span className="flex-none w-4 h-4 rounded-full inline-flex justify-center items-center">
                        <InfoIcon3 />
                      </span>
                      <HighlightedText text={text} />
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div>
            <div className="mb-4 flex items-center gap-3 flex-wrap">
              <p className="text-2xl font-semibold text-[#060606]">
                {currentPersona?.persona_id === "ai_expert"
                  ? "Recommendations"
                  : `Recommendations for consumers like ${
                      currentPersona?.persona_name?.split?.(" ")?.[0]
                    }`}
              </p>
            </div>

            <div className="grid max-md:grid-cols-full lg:grid-cols-12 gap-3">
              {summaryData?.recommendations?.map?.((item, index) => (
                <div
                  key={index}
                  className="p-4 col-span-4 rounded bg-[#F7F8FA]"
                >
                  <p className="mb-2 text-xs font-semibold space-x-1 text-[#7A3DAA]">
                    <span>#{index + 1}</span>
                    <span>{item?.title || ""}</span>
                  </p>
                  <p className="text-sm text-[#536787]">
                    <HighlightedText text={item?.description || ""} />
                  </p>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </>
  );
}
