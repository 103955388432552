import { useState } from "react";
import classNames from "classnames";
import { Link, Navigate, useNavigate } from "react-router-dom";

import Button from "../components/NewButton";
import StepperCard from "../components/StepperCard";
import PersonaCard from "../components/PersonaCard";
import StimuliProcessingModal from "../components/project-stimuli/modals/StimuliProcessingModal";
import ConfirmPersonasModal from "../components/modals/Confirm-Personas-Modal";

import { useProject } from "../contexts/new-project.context";
import { useError } from "../contexts/error.context";

import { httpRequest } from "../utils/http.util";

import { PersonSkeletonIcon, PlusIcon } from "../assets/icons";
import PersonaDetailsModal from "../components/modals/Persona-Details-Modal";

export default function SelectPersona() {
  const navigate = useNavigate();
  const {
    projectId,
    personas,
    setSelectedPersonas,
    setIsProcessingModalOpen,
    isProcessingModalOpen,
    selectedPersonas,
  } = useProject();
  const { handleAPIError } = useError();

  const [isConfirmPersonaModalOpen, setIsConfirmPersonaModalOpen] =
    useState(false);
  const [isPersonaModalOpen, setIsPersonaModalOpen] = useState(false);
  const [clickedPersonaId, setClickedPersonaId] = useState(null);

  const handlePersonaClick = (personaId) => {
    if (selectedPersonas.includes(personaId)) {
      setSelectedPersonas(
        selectedPersonas.filter((persona) => persona !== personaId)
      );
    } else {
      setSelectedPersonas((prev) => [...prev, personaId]);
    }
  };

  const savePersonas = async () => {
    try {
      await savePersonasApi({
        personas: selectedPersonas,
      });

      setIsConfirmPersonaModalOpen(false);

      await startProcessing();
      setIsProcessingModalOpen(true);
    } catch (error) {
      handleAPIError(error);
    }
  };

  async function startProcessing() {
    await httpRequest({
      method: "POST",
      url: `/projects/${projectId}/start`,
      isAuthRequired: true,
    });
  }

  async function savePersonasApi(personas) {
    await httpRequest({
      method: "PUT",
      url: `/projects/${projectId}/personas/selected`,
      data: personas,
      isAuthRequired: true,
    });
  }

  if (!projectId) {
    return <Navigate to="/" replace />;
  }

  return (
    <>
      <div className="flex-auto flex flex-col">
        <div className="p-10 space-y-8 flex-auto overflow-auto">
          <div className="space-y-4">
            <div className="text-[32px]">
              <span className="inline-flex items-center gap-x-2 font-semibold text-[#7A3DAA]">
                Select the AI personas
                <img
                  src="/png/Man Technologist.png"
                  alt="Persona icon"
                  className="w-8 h-8"
                />
              </span>

              <br />

              <span className="font-normal text-[#99A8C0]">
                you'd like insights and recommendations for
              </span>
            </div>

            <div className="flex items-center gap-x-1">
              <StepperCard
                step={1}
                isComplete={true}
                label="Project Name"
                setStep={() => {}}
              />
              <div className="w-10 border-[1.5px] border-dashed border-[#E7EBF1]" />
              <StepperCard
                isComplete={true}
                step={2}
                label="Add your designs"
                setStep={() => {}}
              />
              <div className="w-10 border-[1.5px] border-dashed border-[#E7EBF1]" />
              <StepperCard
                isActive={true}
                step={3}
                label="Select AI Personas"
                setStep={() => {}}
              />
            </div>
          </div>

          <div className="space-y-8">
            {/* <div className="p-3 rounded bg-[#FDFAF2]">
              <p className="flex gap-x-2 items-center font-medium text-sm text-[#D09625]">
                <BulbIcon />
                <span>You can select multiple personas for analysis</span>
              </p>
            </div> */}

            <div className="flex flex-wrap gap-4">
              <CreatePersonaCard />

              {personas.map((persona) => (
                <PersonaCard
                  key={persona.persona_id}
                  persona={persona}
                  selectedPersonas={selectedPersonas}
                  handlePersonaClick={handlePersonaClick}
                  setIsPersonaModalOpen={setIsPersonaModalOpen}
                  setClickedPersonaId={setClickedPersonaId}
                />
              ))}
            </div>
          </div>
        </div>

        <div className="px-10 py-4 flex-none space-x-4 text-right">
          <Button
            title="Back"
            variant="secondary"
            type="button"
            onClick={() => navigate(`/${projectId}/upload-stimuli`)}
          />
          <Button
            title="Generate Insights"
            variant={!selectedPersonas.length ? "disabled" : "primary"}
            type="button"
            disabled={!selectedPersonas.length}
            onClick={() => setIsConfirmPersonaModalOpen(true)}
          />
        </div>
      </div>

      {isProcessingModalOpen ? <StimuliProcessingModal /> : null}

      <ConfirmPersonasModal
        isConfirmPersonaModalOpen={isConfirmPersonaModalOpen}
        setIsConfirmPersonaModalOpen={setIsConfirmPersonaModalOpen}
        savePersonas={savePersonas}
      />

      {isPersonaModalOpen ? (
        <PersonaDetailsModal
          clickedPersonaId={clickedPersonaId}
          closeModal={() => {
            setIsPersonaModalOpen(false);
            setClickedPersonaId(null);
          }}
        />
      ) : null}
    </>
  );
}

function CreatePersonaCard() {
  const { projectId } = useProject();

  return (
    <Link
      to={`/${projectId}/create-persona`}
      className={classNames(
        "block flex-none group/persona w-64 border rounded-lg border-[#E7EBF1] hover:border-[1.5px] hover:border-[#7A3DAA]"
      )}
    >
      <div className="m-1 h-[215px] relative flex justify-center items-center rounded-t-lg rounded-b group-hover/persona:bg-[#F1EAF6] bg-[#E7EBF1]">
        <div className="w-20 h-20 flex justify-center items-end overflow-hidden rounded-full bg-white">
          <PersonSkeletonIcon className="group-hover/persona:fill-[#7A3DAA] group-hover/persona:opacity-25" />
        </div>

        <div className="absolute w-8 h-8 -bottom-4 rounded-full flex justify-center items-center bg-white group-hover/persona:bg-[#7A3DAA]">
          <PlusIcon className="stroke-[#7A3DAA] group-hover/persona:stroke-white" />
        </div>
      </div>

      <div className="p-3 text-center">
        <h1 className="font-medium group-hover/persona:font-semibold group-hover/persona:text-[#7A3DAA]">
          Create a New Persona
        </h1>
        <p className="text-sm text-[#536787] group-hover/persona:text-[#000000]">
          Create a profile for an ideal customer in your target market.
        </p>
      </div>
    </Link>
  );
}
