import { useState } from "react";
import classNames from "classnames";
import { Link, Outlet } from "react-router-dom";

import { useProject } from "../contexts/new-project.context";
import { useError } from "../contexts/error.context";

import { httpRequest } from "../utils/http.util";

import MalePersonaImage from "../assets/png/male-persona.png";
import personaSkeletonAvatar from "../assets/png/persona-skeleton-avatar.png";

import {
  DownMenuIcon,
  NewUploadIcon,
  StimuliDetailsDesignElementsIcon,
  StumiliDetailsLogoIcon,
  StumiliDetailsPackagingIcon,
} from "../assets/icons";

export default function PersonaLayout() {
  const {
    personas,
    projectId,
    logoFiles,
    packagingFiles,
    designElementsFiles,
    setLogoFiles,
    setPackagingFiles,
    setDesignElementsFiles,
    selectedPersonas,
  } = useProject();
  const { handleAPIError } = useError();

  const [isLogoMenuOpen, setIsLogoMenuOpen] = useState(false);
  const [isPackagingMenuOpen, setIsPackagingMenuOpen] = useState(false);
  const [isDesignElementsMenuOpen, setIsDesignElementsMenuOpen] =
    useState(false);
  const [isAllExpanded, setIsAllExpanded] = useState(false);

  const [stimuliHover, setStimuliHover] = useState({
    visible: false,
    imageSrc: "",
  });

  const handleStimuliFileSelect = async (e) => {
    const { name, files } = e.target;
    const formData = new FormData();
    let fileCategory = "";

    if (name === "logoFiles") fileCategory = "logo";
    else if (name === "packagingFiles") fileCategory = "packaging_design";
    else if (name === "designElementsFiles") fileCategory = "design_element";

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      formData.append("image_files", file);
    }

    try {
      const fileUploadResponse = await httpRequest({
        method: "POST",
        url: `/projects/${projectId}/${fileCategory}/image_files`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
        isAuthRequired: true,
      });

      if (name === "logoFiles")
        setLogoFiles((prev) => [...prev, ...fileUploadResponse.data]);
      else if (name === "packagingFiles")
        setPackagingFiles((prev) => [...prev, ...fileUploadResponse.data]);
      else if (name === "designElementsFiles")
        setDesignElementsFiles((prev) => [...prev, ...fileUploadResponse.data]);
    } catch (error) {
      handleAPIError(error);
    }
  };

  return (
    <>
      <div className="flex-auto overflow-auto flex">
        <div className="w-72 overflow-auto flex-none flex flex-col border-r border-[#D2D9E5]">
          <div className="flex-auto">
            <div>
              <div className="px-4 h-11 flex justify-between items-center bg-[#F7F8FA]">
                <span className="text-sm font-semibold">PERSONAS</span>
              </div>

              <div className="h-[230px] px-4 py-3 overflow-auto space-y-5">
                <div className="flex gap-3 hover:cursor-pointer">
                  <div
                    className={classNames(
                      "w-[55px] h-[55px] relative rounded-full bg-[#F7F8FA]",
                      {
                        "outline outline-2 outline-offset-2 outline-[#7A3DAA]":
                          selectedPersonas.includes("ai_expert"),
                      }
                    )}
                  >
                    <img
                      src={MalePersonaImage}
                      alt="persona"
                      className="w-full h-full object-contain rounded-full bg-[#F7F8FA]"
                    />

                    <span className="w-3 h-3 absolute bottom-0 right-0 border-2 rounded-full border-white bg-[#33B379]"></span>
                  </div>

                  <span
                    className={classNames(
                      "text-xs self-center",
                      "font-semibold text-[#7A3DAA]"
                    )}
                  >
                    AI Expert
                  </span>
                </div>

                {personas
                  .filter((persona) => persona.persona_id !== "ai_expert")
                  .map((persona) => (
                    <Link
                      key={persona.persona_id}
                      to={`/${projectId}/update-persona/${persona.persona_id}`}
                      className="flex gap-3 hover:cursor-pointer"
                    >
                      <div
                        className={classNames(
                          "w-[55px] h-[55px] relative rounded-full bg-[#F7F8FA]",
                          {
                            "outline outline-2 outline-offset-2 outline-[#7A3DAA]":
                              selectedPersonas.includes(persona.persona_id),
                          }
                        )}
                      >
                        <img
                          src={persona.avatar_url || personaSkeletonAvatar}
                          alt="persona"
                          className="w-full h-full object-contain rounded-full bg-[#F7F8FA]"
                        />

                        <span className="w-3 h-3 absolute bottom-0 right-0 border-2 rounded-full border-white bg-[#33B379]"></span>
                      </div>

                      <div className="self-center">
                        <span
                          className={classNames(
                            "block text-xs",
                            "font-semibold text-[#7A3DAA]"
                          )}
                        >
                          {persona.persona_name}
                        </span>
                        <span
                          className={classNames("block text-xs text-[#536787]")}
                        >
                          ({persona?.consumer_segment_name})
                        </span>
                      </div>
                    </Link>
                  ))}
              </div>
            </div>

            <PersonaDraft />

            <div>
              <div className="px-4 h-11 flex justify-between items-center bg-[#F7F8FA]">
                <span className="text-sm font-semibold">DESIGNS</span>
                <button
                  onClick={() => {
                    if (isAllExpanded) {
                      setIsAllExpanded(false);
                      setIsLogoMenuOpen(false);
                      setIsPackagingMenuOpen(false);
                      setIsDesignElementsMenuOpen(false);
                    } else {
                      setIsAllExpanded(true);
                      setIsLogoMenuOpen(true);
                      setIsPackagingMenuOpen(true);
                      setIsDesignElementsMenuOpen(true);
                    }
                  }}
                  className="text-xs font-medium text-[#7A3DAA]"
                >
                  {isAllExpanded ? "Hide" : "Show all"}
                </button>
              </div>

              <div>
                <div>
                  <button
                    onClick={() => {
                      setIsLogoMenuOpen((prev) => !prev);
                    }}
                    className="px-4 py-4 w-full flex justify-between items-center text-sm font-medium"
                  >
                    <span className="flex items-center gap-x-3">
                      <StumiliDetailsLogoIcon width={12} height={12} />
                      <span
                        className={classNames(
                          "text-sm",
                          isLogoMenuOpen
                            ? "font-semibold text-[#7A3DAA]"
                            : "font-medium"
                        )}
                      >
                        Logo
                      </span>
                    </span>
                    <span
                      className={classNames({ "rotate-180": isLogoMenuOpen })}
                    >
                      <DownMenuIcon
                        fill={isLogoMenuOpen ? "#7A3DAA" : "#536787"}
                      />
                    </span>
                  </button>

                  {isLogoMenuOpen ? (
                    <div>
                      <div className="px-4 mb-2 max-h-72 overflow-auto grid grid-cols-2 gap-2">
                        {logoFiles.map((item) => (
                          <div
                            key={item.image_hash}
                            onMouseOver={() =>
                              setStimuliHover({
                                visible: true,
                                imageSrc: item.public_url,
                              })
                            }
                            onMouseLeave={() =>
                              setStimuliHover({ visible: false, imageSrc: "" })
                            }
                            className="h-32 rounded cursor-pointer bg-[#EDEFF4]"
                          >
                            <img
                              src={item.public_url}
                              alt={item.name}
                              className="w-full h-full object-contain rounded-lg"
                            />
                          </div>
                        ))}
                      </div>

                      <div className="px-4">
                        <label
                          htmlFor="logoFiles"
                          className="p-3 w-full flex items-center justify-center gap-x-2 rounded text-[#AA48CB] bg-[#F1EAF6]"
                        >
                          <NewUploadIcon fill="#AA48CB" />

                          <span>Upload More</span>
                        </label>

                        <input
                          type="file"
                          accept="image/jpg,image/jpeg,image/png"
                          id="logoFiles"
                          name="logoFiles"
                          multiple={true}
                          onChange={handleStimuliFileSelect}
                          className="hidden"
                        />
                      </div>
                    </div>
                  ) : null}
                </div>

                <div>
                  <button
                    onClick={() => {
                      setIsPackagingMenuOpen((prev) => !prev);
                    }}
                    className="p-4 w-full flex justify-between items-center text-sm font-medium"
                  >
                    <span className="flex items-center gap-x-3">
                      <StumiliDetailsPackagingIcon width={12} height={12} />
                      <span
                        className={classNames(
                          "text-sm",
                          isPackagingMenuOpen
                            ? "font-semibold text-[#7A3DAA]"
                            : "font-medium"
                        )}
                      >
                        Packaging
                      </span>
                    </span>
                    <span
                      className={classNames({
                        "rotate-180": isPackagingMenuOpen,
                      })}
                    >
                      <DownMenuIcon
                        fill={isPackagingMenuOpen ? "#7A3DAA" : "#536787"}
                      />
                    </span>
                  </button>

                  {isPackagingMenuOpen ? (
                    <div>
                      <div className="px-4 mb-2 max-h-72 overflow-auto grid grid-cols-2 gap-2">
                        {packagingFiles.map((item) => (
                          <div
                            key={item.image_hash}
                            onMouseOver={() =>
                              setStimuliHover({
                                visible: true,
                                imageSrc: item.public_url,
                              })
                            }
                            onMouseLeave={() =>
                              setStimuliHover({ visible: false, imageSrc: "" })
                            }
                            className="h-32 rounded cursor-pointer bg-[#EDEFF4]"
                          >
                            <img
                              src={item.public_url}
                              alt={item.name}
                              className="w-full h-full object-contain rounded-lg"
                            />
                          </div>
                        ))}
                      </div>

                      <div className="px-4">
                        <label
                          htmlFor="packagingFiles"
                          className="p-3 w-full flex items-center justify-center gap-x-2 rounded text-[#89A318] bg-[#F5F7EC]"
                        >
                          <NewUploadIcon fill="#89A318" />

                          <span>Upload More</span>
                        </label>

                        <input
                          type="file"
                          accept="image/jpg,image/jpeg,image/png"
                          id="packagingFiles"
                          name="packagingFiles"
                          multiple={true}
                          onChange={handleStimuliFileSelect}
                          className="hidden"
                        />
                      </div>
                    </div>
                  ) : null}
                </div>

                <div>
                  <button
                    onClick={() => {
                      setIsDesignElementsMenuOpen((prev) => !prev);
                    }}
                    className="p-4 w-full flex justify-between items-center text-sm font-medium"
                  >
                    <span className="flex items-center gap-x-3">
                      <StimuliDetailsDesignElementsIcon
                        width={12}
                        height={12}
                      />
                      <span
                        className={classNames(
                          "text-sm",
                          isDesignElementsMenuOpen
                            ? "font-semibold text-[#7A3DAA]"
                            : "font-medium"
                        )}
                      >
                        Concept
                      </span>
                    </span>
                    <span
                      className={classNames({
                        "rotate-180": isDesignElementsMenuOpen,
                      })}
                    >
                      <DownMenuIcon
                        fill={isDesignElementsMenuOpen ? "#7A3DAA" : "#536787"}
                      />
                    </span>
                  </button>

                  {isDesignElementsMenuOpen ? (
                    <div>
                      <div className="px-4 mb-2 max-h-72 overflow-auto grid grid-cols-2 gap-2">
                        {designElementsFiles.map((item) => (
                          <div
                            key={item.image_hash}
                            onMouseOver={() =>
                              setStimuliHover({
                                visible: true,
                                imageSrc: item.public_url,
                              })
                            }
                            onMouseLeave={() =>
                              setStimuliHover({ visible: false, imageSrc: "" })
                            }
                            className="h-32 rounded cursor-pointer bg-[#EDEFF4]"
                          >
                            <img
                              src={item.public_url}
                              alt={item.name}
                              className="w-full h-full object-contain rounded-lg"
                            />
                          </div>
                        ))}
                      </div>

                      <div className="px-4">
                        <label
                          htmlFor="designElementsFiles"
                          className="p-3 w-full flex items-center justify-center gap-x-2 rounded text-[#1F70E1] bg-[#EDF4FD]"
                        >
                          <NewUploadIcon fill="#1F70E1" />

                          <span>Upload More</span>
                        </label>

                        <input
                          type="file"
                          accept="image/jpg,image/jpeg,image/png"
                          id="designElementsFiles"
                          name="designElementsFiles"
                          multiple={true}
                          onChange={handleStimuliFileSelect}
                          className="hidden"
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>

        <Outlet />
      </div>

      <div
        onMouseOver={() => setStimuliHover({ ...stimuliHover, visible: true })}
        onMouseLeave={() => setStimuliHover({ visible: false, imageSrc: "" })}
        className={classNames(
          "w-[550px] h-[550px] fixed z-40 left-[300px] top-2/4 -translate-y-2/4 rounded-xl bg-white border shadow-2xl",
          stimuliHover.visible ? "block" : "hidden"
        )}
      >
        <img
          src={stimuliHover.imageSrc}
          alt="Stimuli input preview"
          className="w-full h-full object-contain rounded-lg"
        />
      </div>
    </>
  );
}

function PersonaDraft() {
  const { projectId, draftedPersonas } = useProject();

  const [isOpen, setIsOpen] = useState(true);

  if (!draftedPersonas.length) return null;

  return (
    <div>
      <button
        onClick={() => setIsOpen((prev) => !prev)}
        className="p-4 w-full flex justify-between items-center text-sm font-medium"
      >
        <span className={classNames("text-sm font-medium")}>
          Drafts ({draftedPersonas?.length})
        </span>

        <span>
          <DownMenuIcon fill="#536787" />
        </span>
      </button>

      {isOpen ? (
        <div
          className={classNames("px-4 py-3 grid grid-cols-3 gap-2", {
            hidden: !draftedPersonas.length,
          })}
        >
          {draftedPersonas.map((draftedPersona, index) => (
            <Link
              key={index}
              to={`/${projectId}/draft-persona/${draftedPersona?.persona_id}`}
              className="col-span-1 flex flex-col items-center gap-2 hover:cursor-pointer"
            >
              <div
                className={classNames(
                  "w-[55px] h-[55px] relative rounded-full bg-[#F7F8FA]"
                )}
              >
                <img
                  src={draftedPersona?.avatar_url || MalePersonaImage}
                  alt="persona"
                  className="w-full h-full object-contain rounded-full bg-[#F7F8FA]"
                />
              </div>

              <span
                className={classNames(
                  "text-xs text-[#536787]",
                  "font-semibold text-[#7A3DAA]"
                )}
              >
                {draftedPersona?.persona_name}
              </span>
            </Link>
          ))}
        </div>
      ) : null}
    </div>
  );
}
