import { useEffect, useState } from "react";
import {
  Outlet,
  Route,
  Routes,
  useLocation,
  useParams,
} from "react-router-dom";

import GlobalLayout from "./layouts/global.layout";
import PersonaLayout from "./layouts/persona.layout";

import Login from "./pages/login.page";
import ProjectCreate from "./pages/project-create.page";
import UploadStimuli from "./pages/upload-stimuli.page";
import SelectPersona from "./pages/select-persona.page";
import CreatePersona from "./pages/create-persona.page";
import DraftedPersona from "./pages/drafted-persona.page";
import GeneratedPersona from "./pages/generated-persona.page";
import UpdatePersona from "./pages/update-persona.page";
import ProjectStimuli from "./pages/project-stimuli.page";
import StimuliInsights from "./pages/stimuli-insights.page";
import Chat from "./pages/chat.page";
import CreateChat from "./pages/create-chat.page";
import ChatView from "./pages/chat-view.page";
import NotFound from "./pages/not-found.page";

import Protected from "./components/Protected";
import UpdateSelectedPersonas from "./components/project-stimuli/UpdateSelectedPersonas";

import ChatContextProvider from "./contexts/chat.context";

import { setItemToStore } from "./utils/storage.util";
import { useProject } from "./contexts/new-project.context";
import DataLoader from "./components/Data-Loader";

function Root() {
  const params = useParams();
  const { setProjectId, projectName } = useProject();
  const [loading, setLoading] = useState(true);

  const projectId = params?.projectId;

  useEffect(() => {
    if (projectName) {
      document.title = `${projectName} - Unmistakably Human`;
    } else {
      document.title = `Unmistakably Human`;
    }
  }, [projectName]);

  useEffect(() => {
    setLoading(true);
    if (projectId) {
      setProjectId(projectId);
    }

    setTimeout(() => {
      setLoading(false);
    }, 1500);

    // eslint-disable-next-line
  }, [projectId]);

  if (loading) {
    return (
      <div className="h-screen flex justify-center items-center">
        <DataLoader />
      </div>
    );
  }

  return <Outlet />;
}

export default function App() {
  const location = useLocation();

  useEffect(() => {
    const parseUrlParams = (url) => {
      const params = {};
      const urlSearchParams = new URLSearchParams(url.search);
      for (const [key, value] of urlSearchParams) {
        params[key] = value;
      }
      return params;
    };

    const urlParams = parseUrlParams(location);

    for (const param in urlParams) {
      setItemToStore(param, urlParams[param]);
    }
  }, [location]);

  return (
    <Routes>
      <Route element={<Protected />}>
        <Route element={<GlobalLayout />}>
          <Route index element={<ProjectCreate />} />
          <Route path="/" element={<Root />}>
            <Route path="/:projectId">
              <Route path="upload-stimuli" element={<UploadStimuli />} />
              <Route element={<PersonaLayout />}>
                <Route path="select-persona" element={<SelectPersona />} />
                <Route path="create-persona" element={<CreatePersona />} />
                <Route
                  path="generated-persona/:personaId"
                  element={<GeneratedPersona />}
                />
                <Route
                  path="update-persona/:personaId"
                  element={<UpdatePersona />}
                />
                <Route
                  path="draft-persona/:personaId"
                  element={<DraftedPersona />}
                />
              </Route>
            </Route>
            <Route
              path="project-stimuli/:projectId"
              element={<ProjectStimuli />}
            >
              <Route path="insights" element={<StimuliInsights />} />
              <Route path="personas" element={<UpdateSelectedPersonas />} />
              <Route element={<ChatContextProvider />}>
                <Route path="chat" element={<Chat />}>
                  <Route path="new" element={<CreateChat />} />
                  <Route path=":chatId" element={<ChatView />} />
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>

      <Route path="/login" element={<Login />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
