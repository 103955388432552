import { Navigate, Outlet, useLocation } from "react-router-dom";

import { useAuth } from "../contexts/auth.context";

const Protected = () => {
  const location = useLocation();
  const { pathname, search } = location;

  const { user } = useAuth();

  if (!user) {
    return (
      <Navigate
        to="/login"
        replace={true}
        state={{ redirectedFrom: pathname, search }}
      />
    );
  }

  return <Outlet />;
};

export default Protected;
