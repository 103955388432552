import { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";

import Button from "../components/NewButton";
import DataLoader from "../components/Data-Loader";

import { useProject } from "../contexts/new-project.context";
import { useError } from "../contexts/error.context";

import { httpRequest } from "../utils/http.util";

import PersonaDemographicDetails from "../components/persona/PersonaDemographicDetails";
import PersonaProfileCard from "../components/persona/PersonaProfileCard";
import DeletePersonaModal from "../components/modals/Delete-Persona-Modal";

export default function UpdatePersona() {
  const params = useParams();
  const navigate = useNavigate();

  const { projectId, getDraftedPersonas, getSavedPersonas } = useProject();
  const { handleAPIError } = useError();

  const personaId = params.personaId;

  const [isDeletePersonaModalOpen, setIsDeletePersonaModalOpen] =
    useState(false);
  const [generatedPersonaDetails, setGeneratedPersonaDetails] = useState(null);
  const [isLoadingPersonaDetails, setIsLoadingPersonaDetails] = useState(true);

  const handlePersonaProfileChange = (e, id) => {
    setGeneratedPersonaDetails((prev) => {
      return {
        ...prev,
        persona_info: {
          ...prev.persona_info,
          profile: prev.persona_info.profile.map((item) =>
            item.id === id ? { ...item, content: e.target.value } : item
          ),
        },
      };
    });
  };

  const handlePersonaUpdate = async () => {
    try {
      const payload = {
        save: true,
        // ...generatedPersonaDetails.persona_info,
        persona_name: generatedPersonaDetails.persona_info.persona_name,
        demographics: generatedPersonaDetails.persona_info.demographics.map(
          (item) => ({
            ...item,
            id: undefined,
          })
        ),
        profile: generatedPersonaDetails.persona_info.profile.map((item) => ({
          ...item,
          section: `${item.icon} ${item.section}`,
          icon: undefined,
          id: undefined,
        })),
      };

      await httpRequest({
        method: "PUT",
        url: `/projects/${projectId}/personas/${personaId}`,
        isAuthRequired: true,
        data: payload,
      });

      await getDraftedPersonas();
      await getSavedPersonas(true);

      navigate(`/${projectId}/select-persona`, { replace: true });
    } catch (error) {
      handleAPIError(error);
    }
  };

  useEffect(() => {
    const main = async () => {
      if (projectId && personaId) {
        try {
          setIsLoadingPersonaDetails(true);

          const getPersonaDetailsResponse = await httpRequest({
            url: `/projects/${projectId}/personas/${personaId}`,
            isAuthRequired: true,
          });

          const personaDemographicDetailList =
            getPersonaDetailsResponse.data.demographics.map((item, index) => {
              return { ...item, id: index + 1 };
            });

          const personaProfiles = getPersonaDetailsResponse.data.profile.map(
            (item, index) => {
              const icon = item.section.slice(0, item.section.indexOf(" "));
              const section = item.section
                .substr(item.section.indexOf(" "))
                .trim();

              return { ...item, icon, section, id: index + 1 };
            }
          );

          setGeneratedPersonaDetails({
            persona_info: {
              ...getPersonaDetailsResponse.data,
              demographics: personaDemographicDetailList,
              profile: personaProfiles,
            },
          });

          setIsLoadingPersonaDetails(false);
        } catch (error) {
          handleAPIError(error);
        }
      }
    };

    main();
    // eslint-disable-next-line
  }, [projectId, personaId]);

  if (!projectId && !personaId) {
    return <Navigate to="/" replace />;
  }

  return (
    <>
      <div className="flex-auto overflow-auto flex flex-col">
        {isLoadingPersonaDetails ? (
          <DataLoader />
        ) : (
          <>
            <div className="px-10 pt-10 flex-auto overflow-auto space-y-11">
              <PersonaDemographicDetails
                personaId={personaId}
                generatedPersonaDetails={generatedPersonaDetails}
                setGeneratedPersonaDetails={setGeneratedPersonaDetails}
              />

              <div className="space-y-10">
                <div className="px-3 py-5 border rounded-lg border-[#F7E7FB]">
                  <p className="mb-3 text-xl font-semibold">
                    🚀 A quick summary about your persona
                  </p>
                  <p>{generatedPersonaDetails?.persona_info?.brief_summary}</p>
                </div>

                <div className="grid lg:grid-cols-2 gap-x-10 gap-y-10">
                  {generatedPersonaDetails?.persona_info?.profile?.map?.(
                    (personaProfile, index) => (
                      <PersonaProfileCard
                        key={index}
                        personaProfile={personaProfile}
                        handlePersonaProfileChange={handlePersonaProfileChange}
                        generatedPersonaDetails={generatedPersonaDetails}
                        setGeneratedPersonaDetails={setGeneratedPersonaDetails}
                      />
                    )
                  )}
                </div>
              </div>
            </div>

            <div className="px-10 py-4 flex-none text-right space-x-4">
              <Button
                onClick={() => setIsDeletePersonaModalOpen(true)}
                variant="plain"
                title="Delete Persona"
              />
              <Button
                title="Cancel"
                variant="secondary"
                onClick={() => navigate(-1, { replace: true })}
              />
              <Button
                title="Save Persona"
                variant="primary"
                onClick={handlePersonaUpdate}
              />
            </div>
          </>
        )}
      </div>

      <DeletePersonaModal
        personaId={personaId}
        isDeletePersonaModalOpen={isDeletePersonaModalOpen}
        setIsDeletePersonaModalOpen={setIsDeletePersonaModalOpen}
      />
    </>
  );
}
