import { createContext, useContext, useEffect, useState } from "react";
import { Navigate, Outlet, useParams, useSearchParams } from "react-router-dom";

import { useProject } from "./new-project.context";
import { useError } from "./error.context";

import { httpRequest } from "../utils/http.util";

const ChatContext = createContext(null);

export default function ChatContextProvider() {
  const { chatId: chatIdFromParams } = useParams();
  const [searchParams] = useSearchParams();

  const shouldLoadChat = searchParams.get("shouldLoadChat");

  const {
    projectId,
    personas,
    currentPersona,
    logoFiles,
    packagingFiles,
    designElementsFiles,
    isStimuliProcessed,
    chatStimuliCategory,
    chatStimuliVariants,
  } = useProject();
  const { handleAPIError } = useError();

  const [chatId, setChatId] = useState(chatIdFromParams || null);

  const [selectedStimuli, setSelectedStimuli] = useState(null);
  const [selectedVariants, setSelectedVariants] = useState([]);

  const [loadingChatHistory, setLoadingChatHistory] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);

  const [loadingChatData, setLoadingChatData] = useState(
    chatIdFromParams && shouldLoadChat === "true" ? true : false
  );
  const [chatData, setChatData] = useState(null);

  const [isPersonaModalOpen, setIsPersonaModalOpen] = useState(false);
  const [isChatLoading, setIsChatLoading] = useState(false);

  const getChatHistory = async () => {
    return httpRequest({
      method: "POST",
      url: `/projects/${projectId}/chat/search`,
      domain: "chat",
      data: {
        persona_id: currentPersona.persona_id,
        category: chatStimuliCategory,
        image_hashes: chatStimuliVariants,
      },
      isAuthRequired: true,
    })
      .then((response) => {
        setChatHistory(
          Object.entries(response.data).map(([key, val]) => ({
            chatId: key,
            chatTitle: val,
          }))
        );
      })
      .catch(handleAPIError);
  };

  const getChatData = async () => {
    return httpRequest({
      url: `/projects/${projectId}/chat/${chatIdFromParams}`,
      domain: "chat",
      isAuthRequired: true,
    });
  };

  const handleChatDetails = (data) => {
    const chatDetails = { ...data };

    chatDetails.persona = personas.find(
      (persona) => persona.persona_id === chatDetails.persona_id
    );

    chatDetails.chats = chatDetails.chats.map((chat, index) => ({
      ...chat,
      id: index + 1,
      loading: false,
    }));

    if (chatDetails.category === "logo") {
      chatDetails.stimuliFiles = logoFiles.filter((file) =>
        chatDetails.image_hashes.includes(file.image_hash)
      );
    }
    if (chatDetails.category === "packaging_design") {
      chatDetails.stimuliFiles = packagingFiles.filter((file) =>
        chatDetails.image_hashes.includes(file.image_hash)
      );
    }
    if (chatDetails.category === "design_element") {
      chatDetails.stimuliFiles = designElementsFiles.filter((file) =>
        chatDetails.image_hashes.includes(file.image_hash)
      );
    }

    return chatDetails;
  };

  useEffect(() => {
    const main = async () => {
      if (currentPersona && chatStimuliCategory) {
        try {
          setLoadingChatHistory(true);

          await getChatHistory();

          setLoadingChatHistory(false);
        } catch (error) {
          handleAPIError(error);
        }
      }
    };

    main();
    // eslint-disable-next-line
  }, [
    currentPersona,
    chatStimuliCategory,
    chatStimuliVariants /* , chatIdFromParams */,
  ]);

  useEffect(() => {
    if (projectId && chatIdFromParams) {
      if (shouldLoadChat !== "false") {
        setLoadingChatData(true);

        getChatData()
          .then((response) => {
            const chatDetails = handleChatDetails(response?.data);

            setChatData(chatDetails);
            setLoadingChatData(false);
          })
          .catch(handleAPIError);
      }
    }
    // eslint-disable-next-line
  }, [projectId, chatIdFromParams]);

  const providerValue = {
    chatId,
    setChatId,

    selectedStimuli,
    setSelectedStimuli,
    selectedVariants,
    setSelectedVariants,

    loadingChatHistory,
    setLoadingChatHistory,
    chatHistory,
    setChatHistory,

    loadingChatData,
    setLoadingChatData,
    chatData,
    setChatData,

    isPersonaModalOpen,
    setIsPersonaModalOpen,

    isChatLoading,
    setIsChatLoading,

    getChatHistory,

    handleChatDetails,
  };

  if (!isStimuliProcessed) {
    return <Navigate to={`/project-stimuli/${projectId}/insights`} />;
  }

  return (
    <ChatContext.Provider value={providerValue}>
      <Outlet />
    </ChatContext.Provider>
  );
}

export const useChat = () => useContext(ChatContext);
