import { useEffect, useMemo, useState } from "react";

import Loader from "./Loader";
import DeepDiveInto from "./DeepDiveInto";
import HighlightedText from "./HighlightedText";

import { useProject } from "../../contexts/new-project.context";
import { useError } from "../../contexts/error.context";

import { httpRequest } from "../../utils/http.util";

import { CheckIcon, RightArrowIcon } from "../../assets/icons";

import light from "../../assets/png/light.png";
import lightBg from "../../assets/png/light-bg.png";
import classNames from "classnames";
import DataLoader from "../Data-Loader";

const DeepDive = () => {
  const dropdownOptions = [
    {
      key: "visualElements",
      label: "Visual Elements",
    },
    {
      key: "textElements",
      label: "Text Elements",
    },
    {
      key: "attributes",
      label: "Attributes",
    },
  ];

  const { projectId, currentPersona, currentStimuli, currentSelectedStimuli } =
    useProject();
  const { handleAPIError } = useError();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDeepDiveData, setIsLoadingDeepDiveData] = useState(false);
  const [deepDiveElements2, setDeepDiveElements2] = useState(null);
  const [deepDiveData, setDeepDiveData] = useState(null);
  const [selectedElement, setSelectedElement] = useState(null);
  const [selectedAttribute, setSelectedAttribute] = useState(null);
  const [selectedDeepDive, setSelectedDeepDive] = useState(dropdownOptions[0]);
  const [selectedAttributeElement, setSelectedAttributeElement] = useState(
    dropdownOptions[0]
  );
  const [workingStatus, setWorkingStatus] = useState(0);
  const [isVariantImageVisible, setIsVariantImageVisible] = useState(false);

  const handleExploreClick = () => {
    if (!selectedElement || !selectedAttribute) return;

    if (selectedDeepDive.key === "attributes") {
      setSelectedDeepDive(selectedAttributeElement);
      setSelectedAttributeElement(null);
    } else {
      setSelectedAttributeElement(selectedDeepDive);
      setSelectedDeepDive(dropdownOptions[2]);
    }
  };

  const exploreButtonText = useMemo(() => {
    if (selectedDeepDive.key === "attributes") {
      return selectedElement?.name;
    } else {
      return selectedAttribute?.name;
    }
  }, [selectedDeepDive, selectedElement, selectedAttribute]);

  useEffect(() => {
    if (currentSelectedStimuli) {
      setIsLoading(true);
      httpRequest({
        url: `/global/${currentSelectedStimuli}/elements_and_attributes`,
        isAuthRequired: true,
      })
        .then((response) => {
          const deepDiveComponentsData = {
            visualElements: response.data.elements.visual.map(
              (item, index) => ({
                id: `visual-element-${index}`,
                name: item,
              })
            ),
            textElements: response.data.elements.text.map((item, index) => ({
              id: `text-element-${index}`,
              name: item,
            })),
            attributes: response.data.attributes.map((item, index) => ({
              id: `attribute-${index}`,
              name: item,
            })),
          };

          setSelectedElement(deepDiveComponentsData.visualElements[1]);
          setSelectedAttribute(deepDiveComponentsData.attributes[1]);
          setDeepDiveElements2(deepDiveComponentsData);
          setIsLoading(false);
        })
        .catch(handleAPIError);
    }
    // eslint-disable-next-line
  }, [currentSelectedStimuli]);

  useEffect(() => {
    if (
      currentPersona &&
      currentStimuli &&
      currentSelectedStimuli &&
      selectedElement &&
      selectedAttribute
    ) {
      setIsLoadingDeepDiveData(true);
      httpRequest({
        url: `/projects/${projectId}/${currentSelectedStimuli}/deep_dive/${currentPersona?.persona_id}/${currentStimuli?.image_hash}/${selectedElement?.name}/${selectedAttribute?.name}/v2`,
        isAuthRequired: true,
      })
        .then((response) => {
          setDeepDiveData(response.data);
        })
        .catch((error) => {
          handleAPIError(error);
          setDeepDiveData(null);
        })
        .finally(() => setIsLoadingDeepDiveData(false));
    }
    // eslint-disable-next-line
  }, [
    currentPersona,
    currentStimuli,
    currentSelectedStimuli,
    selectedElement,
    selectedAttribute,
  ]);

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="flex flex-col gap-4">
          <DeepDiveInto
            dropdownOptions={dropdownOptions}
            selectedDeepDive={selectedDeepDive}
            setSelectedDeepDive={setSelectedDeepDive}
            selectedAttributeElement={selectedAttributeElement}
            setSelectedAttributeElement={setSelectedAttributeElement}
            selectedElement={selectedElement}
            setSelectedElement={setSelectedElement}
            selectedAttribute={selectedAttribute}
            setSelectedAttribute={setSelectedAttribute}
            deepDiveElements2={deepDiveElements2}
          />

          {!isLoadingDeepDiveData && deepDiveData ? (
            <>
              <div className="rounded-xl relative border border-[#F7EAFB] bg-[#F7F2FB]">
                <img
                  src={lightBg}
                  alt="Bulb icon"
                  className="w-full h-full absolute inset-0 z-10 opacity-10"
                />
                <div className="relative inset-0 z-20">
                  <div className="inline-flex items-center gap-2 rounded-full mx-5 mt-5 px-3 py-[10px] bg-white">
                    <img src={light} alt="light" width={20} />
                    <label className="font-medium text-sm text-[#000000] leading-[18px]">
                      {selectedAttribute?.name} of the {selectedElement?.name}
                    </label>
                  </div>
                  <p className="text-[#060606] font-medium mx-5 leading-[30px] mt-4">
                    {deepDiveData?.key_takeaway}
                  </p>
                  <button
                    onClick={handleExploreClick}
                    className="my-5 mx-5 px-5 py-[10px] flex items-center gap-5 rounded-lg text-sm text-white font-medium bg-[#7A3DAA] hover:bg-[#4E266F]"
                  >
                    <span>
                      Explore{" "}
                      <span className="uppercase">{exploreButtonText}</span>
                    </span>
                    <RightArrowIcon stroke="#ffffff" />
                  </button>
                </div>
              </div>

              <div className="my-5 flex gap-5 [@media(max-width:1000px)]:flex-wrap">
                <div
                  onMouseOver={() => setIsVariantImageVisible(true)}
                  onMouseLeave={() => setIsVariantImageVisible(false)}
                  className="flex-none w-[335px] h-[384px] border border-[#EDEFF4] rounded-xl [@media(max-width:1000px)]:w-full"
                >
                  <img
                    src={currentStimuli?.public_url}
                    alt={currentStimuli?.name}
                    className="w-full h-full object-contain rounded"
                  />
                </div>

                <div
                  onMouseOver={() => setIsVariantImageVisible(true)}
                  onMouseLeave={() => setIsVariantImageVisible(false)}
                  className={classNames(
                    "w-[550px] h-[550px] fixed z-40 left-5 top-2/4 -translate-y-2/4 rounded-xl bg-white border shadow-2xl",
                    isVariantImageVisible ? "block" : "hidden"
                  )}
                >
                  <img
                    src={currentStimuli?.public_url}
                    alt={currentStimuli?.name}
                    className="w-full h-full object-contain rounded-lg"
                  />
                </div>

                <div className="flex-auto overflow-hidden rounded-b-xl">
                  <div className="flex">
                    <button
                      onClick={() => setWorkingStatus(0)}
                      className={classNames(
                        "relative flex-1 px-6 py-3 rounded-tl-xl bg-[#F4FCF5] text-[#3F9545]",
                        {
                          'border-t border-l border-t-[#3F9545] border-l-[#3F9545] before:content-[""] before:absolute before:w-[25px] before:h-2/3 before:-top-px before:-right-px before:z-10 before:bg-[#FDFAF2] after:content-[""] after:absolute after:w-[25px] after:h-2/3 after:-top-px after:-right-px after:z-20 after:border-t after:border-r after:rounded-tr-xl after:border-[#3F9545] after:bg-[#F4FCF5]':
                            workingStatus === 0,
                          'border-b border-b-[#DEA028] before:content-[""] before:absolute before:w-[25px] before:h-2/3 before:-bottom-px before:-right-px before:z-10 before:bg-[#FDFAF2] after:content-[""] after:absolute after:w-[25px] after:h-2/3 after:-bottom-px after:-right-px after:z-20 after:border-b after:border-r after:rounded-br-xl after:border-[#DEA028] after:bg-[#F4FCF5]':
                            workingStatus === 1,
                        }
                      )}
                    >
                      What's working?
                    </button>
                    <div className="w-px border-none" />
                    <button
                      onClick={() => setWorkingStatus(1)}
                      className={classNames(
                        "relative flex-1 px-6 py-3 border border-transparent rounded-tr-xl bg-[#FDFAF2] text-[#DEA028]",
                        {
                          'border-b border-b-[#3F9545] before:content-[""] before:absolute before:w-[25px] before:h-2/3 before:-bottom-px before:-left-0.5 before:z-10 before:bg-[#F4FCF5] after:content-[""] after:absolute after:w-[25px] after:h-2/3 after:-bottom-px after:-left-0.5 after:z-20 after:border-b after:border-l after:rounded-bl-xl after:border-[#3F9545] after:bg-[#FDFAF2]':
                            workingStatus === 0,
                          'border-t border-r border-t-[#DEA028] border-r-[#DEA028] before:absolute before:w-[25px] before:h-2/3 before:-top-px before:-left-0.5 before:z-10 before:bg-[#F4FCF5] after:content-[""] after:absolute after:w-[25px] after:h-2/3 after:-top-px after:-left-0.5 after:z-20 after:border-t after:border-l after:rounded-tl-xl after:border-[#DEA028] after:bg-[#FDFAF2]':
                            workingStatus === 1,
                        }
                      )}
                    >
                      What's not?
                    </button>
                  </div>

                  <div
                    className={classNames(
                      "h-[336px] p-6 border border-transparent rounded-b-xl space-y-3 overflow-auto",
                      {
                        "bg-[#F4FCF5] border-l-[#3F9545] border-b-[#3F9545] border-r-[#3F9545]":
                          workingStatus === 0,
                        "bg-[#FDFAF2] border-l-[#DEA028] border-b-[#DEA028] border-r-[#DEA028]":
                          workingStatus === 1,
                      }
                    )}
                  >
                    {workingStatus === 0 ? (
                      <>
                        {deepDiveData?.positive_analysis.map((text, index) => (
                          <p key={index} className="flex gap-x-2 text-sm">
                            <span className="flex-none w-4 h-4 rounded-full inline-flex justify-center items-center bg-[#3F9545]">
                              <CheckIcon fill="#ffffff" />
                            </span>
                            <HighlightedText text={text} />
                          </p>
                        ))}
                      </>
                    ) : (
                      <>
                        {deepDiveData?.analysis?.map?.((text, index) => (
                          <p key={index} className="flex gap-x-2 text-sm">
                            <span className="flex-none w-4 h-4 rounded-full inline-flex justify-center items-center bg-[#DEA028]">
                              <CheckIcon fill="#ffffff" />
                            </span>
                            <HighlightedText text={text} />
                          </p>
                        ))}
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div>
                <div className="mb-5 flex items-center gap-3 flex-wrap">
                  <p className="text-2xl font-semibold text-[#060606]">
                    {currentPersona?.persona_id === "ai_expert"
                      ? "Recommendations"
                      : `Recommendations for consumers like ${
                          currentPersona?.persona_name?.split?.(" ")?.[0]
                        }`}
                  </p>
                </div>

                <div className="grid max-md:grid-cols-full lg:grid-cols-12 gap-3">
                  {deepDiveData?.recommendations.map((item, index) => (
                    <div
                      key={index}
                      className="p-4 col-span-4 rounded bg-[#F7F8FA]"
                    >
                      <p className="mb-2 text-xs font-semibold space-x-1 text-[#7A3DAA]">
                        <span>#{index + 1}</span>
                        <span>{item?.title || ""}</span>
                      </p>
                      <p className="text-sm text-[#536787]">
                        <HighlightedText text={item?.description || ""} />
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </>
          ) : (
            <DataLoader />
          )}
        </div>
      )}
    </div>
  );
};

export default DeepDive;
