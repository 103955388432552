import classNames from "classnames";

import Button from "../NewButton";

import { CLoseIcon, CheckIcon } from "../../assets/icons";

export default function ConfirmPersonasModal({
  isConfirmPersonaModalOpen,
  setIsConfirmPersonaModalOpen,
  savePersonas,
}) {
  return (
    <div
      className={classNames("absolute inset-0 z-50 bg-black bg-opacity-75", {
        hidden: !isConfirmPersonaModalOpen,
      })}
    >
      <div className="w-full h-full flex justify-center items-center">
        <div className="w-[520px] min-h-16 relative rounded-xl bg-white">
          <button
            onClick={() => setIsConfirmPersonaModalOpen(false)}
            className="w-8 h-8 absolute top-4 right-4 inline-flex justify-center items-center rounded-full border z-30 border-[#E7EBF1] bg-white"
          >
            <CLoseIcon stroke="#536787" />
          </button>

          <div className="p-10 flex justify-center items-center flex-col flex-wrap">
            <span className="w-10 h-10 flex justify-center items-center rounded-full bg-[#F1EAF6]">
              <span className="w-5 h-5 flex justify-center items-center rounded-full bg-[#7A3DAA]">
                <CheckIcon fill="white" />
              </span>
            </span>

            <p className="mt-5 text-2xl font-semibold text-[#7A3DAA]">
              Confirm your setup
            </p>

            <p className="mt-2 text-center font-medium text-[#99A8C0]">
              The designs and personas cannot be updated hereafter
            </p>

            <div className="mt-5 space-x-2">
              <Button
                title="Cancel"
                variant="secondary"
                onClick={() => setIsConfirmPersonaModalOpen(false)}
              />
              <Button
                title="Confirm"
                variant="primary"
                onClick={savePersonas}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
