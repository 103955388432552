import classNames from "classnames";
import { useNavigate } from "react-router-dom";

import Button from "../../components/NewButton";

import { useProject } from "../../contexts/new-project.context";
import { useError } from "../../contexts/error.context";

import { httpRequest } from "../../utils/http.util";

import { CLoseIcon, UpdatePersonaDeleteIcon } from "../../assets/icons";

export default function DeletePersonaModal({
  personaId,
  isDeletePersonaModalOpen,
  setIsDeletePersonaModalOpen,
}) {
  const navigate = useNavigate();
  const { projectId, getDraftedPersonas, getSavedPersonas } = useProject();
  const { handleAPIError } = useError();

  const handleDeletePersona = async () => {
    try {
      await httpRequest({
        method: "DELETE",
        url: `/projects/${projectId}/personas/${personaId}`,
        isAuthRequired: true,
      });
      await getDraftedPersonas();
      await getSavedPersonas();
      navigate(-1, { replace: true });
    } catch (error) {
      handleAPIError(error);
    }
  };

  return (
    <div
      className={classNames("absolute inset-0 z-50 bg-black bg-opacity-75", {
        hidden: !isDeletePersonaModalOpen,
      })}
    >
      <div className="w-full h-full flex justify-center items-center">
        <div className="w-[520px] min-h-16 relative rounded-xl bg-white">
          <button
            onClick={() => setIsDeletePersonaModalOpen(false)}
            className="w-8 h-8 absolute top-4 right-4 inline-flex justify-center items-center rounded-full border z-30 border-[#E7EBF1] bg-white"
          >
            <CLoseIcon stroke="#536787" />
          </button>

          <div className="p-10 flex justify-center items-center flex-col flex-wrap">
            <span className="w-10 h-10 flex justify-center items-center rounded-full bg-[#F1EAF6]">
              <UpdatePersonaDeleteIcon />
            </span>

            <p className="mt-5 text-2xl font-semibold text-[#7A3DAA]">
              Delete persona
            </p>

            <p className="mt-2 font-medium text-[#99A8C0]">
              Are you sure you want to delete this persona? You will permanently
              lose the information about this persona.{" "}
            </p>

            <div className="mt-5 space-x-2">
              <Button
                title="No, keep persona"
                variant="secondary"
                onClick={() => setIsDeletePersonaModalOpen(false)}
              />
              <Button
                title="Yes, delete persona"
                variant="primary"
                onClick={handleDeletePersona}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
