import classNames from "classnames";

import MalePersonaImage from "../assets/png/male-persona.png";
import personaSkeletonAvatar from "../assets/png/persona-skeleton-avatar.png";

import { CheckIcon } from "../assets/icons";

export default function PersonaCard({
  persona,
  selectedPersonas,
  handlePersonaClick,
  setClickedPersonaId,
  setIsPersonaModalOpen,
}) {
  const handleMountOver = (e) => {
    const checkbox = e.currentTarget.querySelector(".checkbox");
    checkbox.style.borderColor = "#7A3DAA";
    checkbox.style.outline = "4px solid #F1EAF6";
  };

  const handleMountLeave = (e) => {
    const checkbox = e.currentTarget.querySelector(".checkbox");
    checkbox.style.borderColor = "#899BB6";
    checkbox.style.outline = "none";
  };

  const onClick = (e, persona) => {
    if (e.target?.classList?.contains?.("persona_name")) return;
    handlePersonaClick(persona.persona_id);
  };

  const personaGender = persona?.demographics?.find?.(
    (per) => per?.name === "Gender"
  )?.description;

  const personaAge = persona?.demographics?.find?.(
    (per) => per?.name === "Age"
  )?.description;

  const personaOccupation = persona?.demographics?.find?.(
    (per) => per?.name === "Occupation"
  )?.description;

  let profileImageUrl = "";

  if (persona?.avatar_url) {
    profileImageUrl = persona.avatar_url;
  } else if (persona.persona_id === "ai_expert") {
    profileImageUrl = MalePersonaImage;
  } else {
    profileImageUrl = personaSkeletonAvatar;
  }

  return (
    <div
      onMouseOver={handleMountOver}
      onMouseLeave={handleMountLeave}
      onClick={(e) => onClick(e, persona)}
      className={classNames(
        "flex-none group/persona w-64 flex flex-col relative border rounded-lg cursor-pointer select-none hover:border-[#7A3DAA] hover:outline hover:outline-4 hover:outline-[#F1EAF6]",
        {
          "border-[#E7EBF1]": !selectedPersonas.includes(persona.persona_id),
          "border-[#7A3DAA]": selectedPersonas.includes(persona.persona_id),
        }
      )}
    >
      <div className="h-[215px] p-5 space-y-3">
        <div className="w-20 h-20 flex justify-center items-center rounded-full overflow-hidden bg-[#F7F8FA]">
          <img
            src={profileImageUrl}
            alt={`${persona.consumer_segment_name} persona`}
            className="w-full h-full rounded-full object-contain"
          />
        </div>

        <div>
          <p
            onClick={() => {
              setClickedPersonaId(persona.persona_id);
              setIsPersonaModalOpen(true);
            }}
            className={classNames(
              "persona_name text-xl font-semibold text-[#060606] group-hover/persona:text-[#7A3DAA] truncate hover:underline hover:underline-offset-1",
              {
                "text-[#7A3DAA]": selectedPersonas.includes(persona.persona_id),
              }
            )}
          >
            {persona?.persona_name}
          </p>

          {persona?.consumer_segment_name ? (
            <p className="text-sm text-[#536787]">
              ({persona?.consumer_segment_name})
            </p>
          ) : null}

          <p className="text-sm text-[#536787]">
            {personaGender && personaAge
              ? `${personaGender || "Male"}, ${personaAge}`
              : null}
          </p>

          <p className="text-sm truncate text-[#536787]">{personaOccupation}</p>
        </div>
      </div>

      <div className="min-h-[124px] p-5 flex-1 rounded-b-lg bg-[#F1EAF6]">
        <p className="text-sm text-[#536787]">
          {persona.one_line_desc ||
            "An advanced AI with specialised knowledge about packaging design"}
        </p>
      </div>

      <div className="absolute top-5 right-5">
        <span
          className={classNames(
            "checkbox w-[18px] h-[18px] inline-flex justify-center items-center rounded-sm border-[1.5px] border-[#899BB6]",
            { "bg-[#7A3DAA]": selectedPersonas.includes(persona.persona_id) }
          )}
        >
          <CheckIcon fill="#ffffff" />
        </span>
      </div>
    </div>
  );
}
