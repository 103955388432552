import { useState } from "react";

import Button from "../../NewButton";
import ValidateErrorInput from "../ValidationErrorInput";

import { useProject } from "../../../contexts/new-project.context";

import { CompletedIcon } from "../../../assets/icons";

export default function ValidationModal({ submitStimuliDetails }) {
  const {
    stimuliValidationErrors,
    setStimuliValidationErrors,
    setIsShowValidationInput,
  } = useProject();
  const [isFormTouched, setIsFormTouched] = useState(false);

  const handlePackErrorDescription = (id, value) => {
    setStimuliValidationErrors(
      stimuliValidationErrors.map((val) => {
        if (val.id === id) return { ...val, desc: value };
        else return val;
      })
    );
  };

  const formDisabled = stimuliValidationErrors.some((val) => !val.desc);

  return (
    <div className="absolute inset-0 z-50 bg-black bg-opacity-75">
      <div className="w-full h-full flex justify-center items-center">
        <div className="rounded-xl bg-white">
          <div className="m-2 p-3 flex items-center gap-x-2 rounded-xl bg-[#E9F8EA]">
            <CompletedIcon />
            <p className="text-sm font-medium text-[#326736]">
              Validation complete! Your design images have been checked and
              cleared for takeoff!
            </p>
          </div>

          <div className="px-4 py-4 bg-white">
            <p className="text-2xl font-medium text-[#060606]">
              Unidentified Keywords • {stimuliValidationErrors.length}
            </p>
            <p className="text-sm text-[#536787]">
              We need your help to get this right. Tell us more about these
              keywords on the images that you've uploaded.
            </p>
          </div>

          <div className="max-h-60 overflow-y-auto">
            <div className="p-4 flex flex-col gap-4">
              {stimuliValidationErrors.map((packError) => (
                <ValidateErrorInput
                  key={packError.id}
                  packError={packError}
                  isFormTouched={isFormTouched}
                  setIsFormTouched={setIsFormTouched}
                  handleChange={handlePackErrorDescription}
                />
              ))}
            </div>
          </div>

          <div className="ps-4 py-4 pe-8 text-right space-x-2">
            <Button
              title="Back"
              variant="secondary"
              onClick={() => setIsShowValidationInput(false)}
            />
            <Button
              title="Save & Continue"
              variant={formDisabled ? "disabled" : "primary"}
              disabled={formDisabled}
              onClick={submitStimuliDetails}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
