import { getAuth } from "firebase/auth";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyAAkXRY0r-hiteyFUhlxS6li5aIlRMnSHY",
  authDomain: "kntr-413112.firebaseapp.com",
  projectId: "kntr-413112",
  storageBucket: "kntr-413112.appspot.com",
  messagingSenderId: "673130516998",
  appId: "1:673130516998:web:7a19b253ec00230398a987",
  measurementId: "G-6YDKWSG0BG",
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

export default app;
