import classNames from "classnames";
import { useEffect, useState } from "react";

import { useProject } from "../../contexts/new-project.context";

import {
  CheckIcon,
  DownMenuIcon,
  StimuliDetailsDesignElementsIcon,
  StumiliDetailsLogoIcon,
  StumiliDetailsPackagingIcon,
} from "../../assets/icons";

export default function ChatStimuliSelect({ setStimuliHover }) {
  const {
    logoFiles,
    packagingFiles,
    designElementsFiles,
    setChatStimuliCategory,
  } = useProject();

  const [stimulis, setStimulis] = useState([
    {
      field: "logo",
      label: "Logo",
      logo: <StumiliDetailsLogoIcon width={13} h={13} />,
      variants: logoFiles,
    },
    {
      field: "packaging_design",
      label: "Packaging",
      logo: <StumiliDetailsPackagingIcon width={13} h={13} />,
      variants: packagingFiles,
    },
    {
      field: "design_element",
      label: "Concept",
      logo: <StimuliDetailsDesignElementsIcon width={13} h={13} />,
      variants: designElementsFiles,
    },
  ]);
  const [isVariantsDropdownOpen, setIsVariantsDropdownOpen] = useState(false);
  const [selectedStimuli, setSelectedStimuli] = useState(stimulis[1]);

  const handleStimuliSelect = (selectedStimuli) => {
    setChatStimuliCategory(selectedStimuli?.field);
    setSelectedStimuli(selectedStimuli);
    setIsVariantsDropdownOpen(false);
  };

  useEffect(() => {
    const stimuliList = [
      {
        field: "logo",
        label: "Logo",
        logo: <StumiliDetailsLogoIcon width={13} h={13} />,
        variants: logoFiles,
      },
      {
        field: "packaging_design",
        label: "Packaging",
        logo: <StumiliDetailsPackagingIcon width={13} h={13} />,
        variants: packagingFiles,
      },
      {
        field: "design_element",
        label: "Concept",
        logo: <StimuliDetailsDesignElementsIcon width={13} h={13} />,
        variants: designElementsFiles,
      },
    ];

    setStimulis(stimuliList);

    setSelectedStimuli((prev) => {
      if (prev) {
        if (prev.field === "logo") return { ...prev, variants: logoFiles };
        else if (prev.field === "packaging_design")
          return { ...prev, variants: packagingFiles };
        else if (prev.field === "design_element")
          return { ...prev, variants: designElementsFiles };
      } else {
        return stimuliList[0];
      }
    });
  }, [logoFiles, packagingFiles, designElementsFiles]);

  return (
    <div className="p-4">
      <div>
        <p className="text-sm font-semibold uppercase">DESIGNS</p>
      </div>

      <div className="relative">
        <div
          onClick={() => setIsVariantsDropdownOpen((prev) => !prev)}
          className={classNames(
            "p-2 mt-1 border flex items-center justify-between cursor-pointer select-none bg-[#F7F8FA]",
            isVariantsDropdownOpen
              ? "border-x-[#7A3DAA] border-t-[#7A3DAA] rounded-tl rounded-tr"
              : "border-[#D2D9E5] rounded hover:border-[#7A3DAA]"
          )}
        >
          <div className="flex items-center gap-1">
            <div className="w-6 h-6 bg-white rounded-full flex justify-center items-center">
              {selectedStimuli?.logo}
            </div>
            <div className="text-sm font-semibold">
              {selectedStimuli?.label}
            </div>
          </div>
          <div className={classNames({ "rotate-180": isVariantsDropdownOpen })}>
            <DownMenuIcon />
          </div>
        </div>

        {isVariantsDropdownOpen && (
          <div className="absolute left-0 right-0 border rounded-b border-x-[#7A3DAA] border-b-[#7A3DAA] bg-white">
            {stimulis.map((item, index) => (
              <div
                key={index}
                onClick={() => handleStimuliSelect(item)}
                className="p-2 flex items-center justify-between cursor-pointer select-none hover:bg-[#F7F8FA]"
              >
                <div className="flex items-center gap-1">
                  <div className="w-6 h-6 bg-white rounded-full flex justify-center items-center">
                    {item.logo}
                  </div>
                  <div
                    className={classNames("text-sm", {
                      "text-[#7A3DAA] font-semibold":
                        selectedStimuli?.field === item.field,
                    })}
                  >
                    {item.label}
                  </div>
                </div>
                {selectedStimuli?.field === item.field && (
                  <div className="w-4 h-4 rounded-full flex justify-center items-center bg-[#7A3DAA]">
                    <CheckIcon fill="#ffffff" />
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>

      {/* <div className="w-px h-72 ms-6 me-3 border border-[#D2D9E5]" /> */}
      <div className="ps-14">
        <p className="mt-5 mb-3 text-sm text-[#536787]">
          Filter chats by variants:
        </p>

        <ChatStimuliItem
          selectedStimuli={selectedStimuli}
          setStimuliHover={setStimuliHover}
        />
      </div>
    </div>
  );
}

function ChatStimuliItem({ selectedStimuli, setStimuliHover }) {
  const {
    chatStimuliCategory,
    setChatStimuliCategory,
    chatStimuliVariants,
    setChatStimuliVariants,
  } = useProject();

  const handleVariantClick = (clickedVariant, variantOf) => {
    if (chatStimuliCategory === variantOf) {
      const alreadySelectedVariant = chatStimuliVariants.includes(
        clickedVariant.image_hash
      );
      let newVariants = [];
      if (alreadySelectedVariant) {
        newVariants = chatStimuliVariants.filter(
          (item) => item !== clickedVariant.image_hash
        );
      } else {
        newVariants = [...chatStimuliVariants, clickedVariant.image_hash];
      }

      setChatStimuliVariants(newVariants);
    } else {
      setChatStimuliCategory(variantOf);
      setChatStimuliVariants([clickedVariant.image_hash]);
    }
  };

  return (
    <div className="space-y-4">
      {selectedStimuli?.variants?.map?.((item, index) => {
        return (
          <StimuliListItem
            key={index}
            item={item}
            variantOf={selectedStimuli?.field}
            handleVariantClick={handleVariantClick}
            setStimuliHover={setStimuliHover}
          />
        );
      })}
    </div>
  );
}

const StimuliListItem = ({
  item,
  variantOf,
  handleVariantClick,
  setStimuliHover,
}) => {
  const { chatStimuliVariants } = useProject();

  const [isHovering, setIsHovering] = useState(false);

  return (
    <div
      onMouseOver={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      onClick={() => handleVariantClick(item, variantOf)}
      className="flex items-center justify-between cursor-pointer"
    >
      <div className="flex items-center gap-3">
        <div className="w-[52px] h-[52px] rounded bg-[#EDEFF4]">
          <img
            src={item.public_url}
            alt={item.name}
            onMouseOver={() =>
              setStimuliHover({ imageSrc: item.public_url, visible: true })
            }
            onMouseLeave={() =>
              setStimuliHover({ visible: false, imageSrc: "" })
            }
            className="w-full h-full object-contain"
          />
        </div>
        <span
          className={classNames(
            "text-sm",
            chatStimuliVariants.includes(item.image_hash)
              ? "text-[#7A3DAA] font-semibold"
              : "font-medium text-[#536787]",
            {
              "text-[#7A3DAA] font-semibold":
                isHovering && !chatStimuliVariants.includes(item.image_hash),
            }
          )}
        >
          {item.name}
        </span>
      </div>
      <div>
        <span
          className={classNames(
            "checkbox w-[18px] h-[18px] border-[1.5px] inline-flex justify-center items-center rounded-sm",
            isHovering
              ? "border-[#7A3DAA] outline outline-4 outline-[#F1EAF6]"
              : "border-[#899BB6]",
            {
              "bg-[#7A3DAA]": chatStimuliVariants.includes(item.image_hash),
            }
          )}
        >
          <CheckIcon fill="#ffffff" />
        </span>
      </div>
    </div>
  );
};
