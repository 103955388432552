import { useState } from "react";
import classNames from "classnames";
import { NavLink, Navigate, Outlet } from "react-router-dom";

import StimuliSidebar from "../components/project-stimuli/StimuliSidebar";

import { useProject } from "../contexts/new-project.context";

import {
  AiPersonaInterviewsIcon,
  CompareIcon,
  InsightsStarsIcon,
} from "../assets/icons";

export default function ProjectStimuli() {
  const { projectId, isStimuliProcessing, isStimulisUploaded } = useProject();

  const [currentTab, setCurrentTab] = useState(1);

  if (!projectId) {
    return <Navigate to="/" replace />;
  }

  return (
    <div className="flex-auto flex overflow-auto">
      <StimuliSidebar />

      <div className="flex-auto overflow-auto flex flex-col">
        <div className="flex-none h-11 flex justify-center items-center bg-[#F7F8FA]">
          <div className="flex items-center flex-wrap">
            <NavLink
              to="insights"
              onClick={() => {
                setCurrentTab(1);
              }}
              className={({ isActive }) =>
                classNames(
                  "pb-2 inline-flex items-center gap-x-2 text-sm border-b-2",
                  isActive
                    ? "font-semibold border-[#7A3DAA] text-[#7A3DAA]"
                    : "font-medium border-transparent text-[#A8B6CA]"
                )
              }
            >
              <InsightsStarsIcon
                fill={currentTab === 1 ? "#7A3DAA" : "#A8B6CA"}
              />
              <span>Insights</span>
              {isStimuliProcessing || !isStimulisUploaded ? (
                <span className="px-1 rounded-sm text-[10px] text-white bg-[#DEA028]">
                  Pending
                </span>
              ) : null}
            </NavLink>

            <span className="mx-8 h-full border border-[#D2D9E5]" />

            <NavLink
              to="chat"
              onClick={() => {
                setCurrentTab(2);
              }}
              className={({ isActive }) =>
                classNames(
                  "pb-2 inline-flex items-center gap-x-2 text-sm border-b-2",
                  isActive
                    ? "font-semibold border-[#7A3DAA] text-[#7A3DAA]"
                    : "font-medium border-transparent text-[#A8B6CA]"
                )
              }
            >
              <AiPersonaInterviewsIcon
                fill={currentTab === 2 ? "#7A3DAA" : "#A8B6CA"}
              />
              <span>AI Persona Interviews</span>
            </NavLink>

            <span className="mx-8 h-full border border-[#D2D9E5]" />

            <span
              to="compare"
              /* onClick={() => setCurrentTab(3)} */
              className={
                /* ({ isActive }) => */
                classNames(
                  "cursor-not-allowed pb-2 inline-flex items-center gap-x-2 text-sm border-b-2",
                  /* isActive
                    ? "font-semibold border-[#7A3DAA] text-[#7A3DAA]"
                    : */ "font-medium border-transparent text-[#A8B6CA]"
                )
              }
            >
              <CompareIcon fill={currentTab === 3 ? "#7A3DAA" : "#A8B6CA"} />
              <span>Compare</span>
            </span>
          </div>
        </div>

        <Outlet />
      </div>
    </div>
  );
}
